import React, { useRef } from "react";
import useOutsideClick from "../../../hooks/useOutsideClick";
import ReceiptItemDropdown from "./ReceiptItemDropdown";
import useDate from "../../../hooks/useDate";
import { Button } from "../../ui/button";
import { SvgEllipses, SvgZoeeIcon } from "../../icons";
import { Badge } from "../../ui/badge";

interface Props {
  receipt: any;
}

const ReceiptItem: React.FC<Props> = ({ receipt }) => {
  const ref = useRef(null);
  const [showDropdown, setShowDropdown] = React.useState<boolean>(false);
  const { mdDate } = useDate();
  const status = receipt.payment_status;

  const getFormattedAmount = () => {
    const amount = receipt.stripe_amount / 100;
    return amount.toLocaleString("en-US", {
      minimumFractionDigits: 2,
    });
  };

  useOutsideClick(ref, () => {
    setShowDropdown(false);
  });

  return (
    <div className="w-full bg-white rounded-lg main-shadow relative p-6 grid grid-cols-[auto_1fr_auto] items-center gap-6">
      <SvgZoeeIcon className="h-8 w-8" />

      <div className="grid md:grid-cols-3 gap-3 items-center">
        <div className="flex flex-col">
          <h4 className="font-bold">
            Zoee Subscription
          </h4>
          <p className="text-graySlate">Business</p>
        </div>

        <p className="text-sm text-graySlate md:text-center">
          {mdDate(receipt.period_start)}
        </p>

        <div className="flex flex-row items-end gap-3 items-center justify-between">
          <Badge
            className="capitalize"
            variant={status === "paid"
              ? "success"
              : status === "pending"
              ? "secondary"
              : status === "failed"
              ? "destructive"
              : "default"}
          >
            {status}
          </Badge>

          <p className="font-bold">
            ${getFormattedAmount()}
          </p>
        </div>
      </div>

      <div ref={ref} className="flex items-center">
        <Button
          variant="ghost"
          size="icon"
          onClick={() => setShowDropdown(() => !showDropdown)}
        >
          <SvgEllipses />
        </Button>
        {showDropdown && (
          <div className="absolute z-50 right-0 top-[68px] md:top-[32px]">
            <ReceiptItemDropdown receipt={receipt} />
          </div>
        )}
      </div>
    </div>
  );
};

export default ReceiptItem;
