import React from "react";
import { Navigate, Outlet, useLocation, useSearchParams } from "react-router-dom";
import { UserCtx } from "../../context/userContext";
import useDate from "../../hooks/useDate";

const PrivateRouteCoach = () => {
    const { user } = React.useContext(UserCtx);
    const { iso, now } = useDate()
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const keys = Array.from(searchParams.keys());
    const params: any = {};
    keys.forEach((key) => {
        params[key] = searchParams.get(key);
    });
    const trialActive = iso(user.trialExpiration) > now;
    let route = <Outlet />;
    if (user) {
        if (user?.isCoach) {
            if (user?.isSubscribed || trialActive) {
                route = <Outlet />;
            } else {
                route = (
                    <Navigate
                        to="/coach/subscription?subscribed=false"
                    />
                ) 
            }
        } else {
            route = (
                <Navigate
                    to="/member"
                />
            )
        }
    } else {
        route = (
            <Navigate
                to="/direct-routes"
                state={{
                    storageAvailable: true,
                    pathname: location.pathname,
                    searchParams: params,
                }}
            />
        );
    }
    return route;
};

export default PrivateRouteCoach;
