import React from "react";
import { UserCtx } from "../../context/userContext";
import useDate from "../../hooks/useDate";

interface Props {
    contactStatus: string;
    origin: string;
    contactDate: any;
}

const ContactStatusTagMobile: React.FC<Props> = ({ contactStatus, origin, contactDate }) => {
    const { user } = React.useContext(UserCtx);
    const { mdDate } = useDate()
    
    const getStatusInfo = () => {
      const userType = user.activeProfile
      switch (userType) {
        case "coach":
          switch (contactStatus) {
            case "active":
              return { color: "bg-grassGreen", text: "Active Member" }
            case "prospect":
              return { color: "bg-fallOrange", text: "Prospect" }
            case "lead":
              return { color: "bg-blurple", text: "Zoee Lead" }
            case "zoee lead":
                return { color: "bg-oceanBlue", text: "Zoee Lead" }
            case "inactive":
              return { color: "bg-gray", text: "Inactive Member" }
            case "invited":
              return { color: "bg-luminousAmber", text: "Invited" }
            case "requested":
              return { color: "bg-vividOrange", text: getRequestedTag() }
            default:
              return { color: "bg-green", text: "" }
          }
        case "member":
          return { color: "bg-green", text: "Active Coach" }
        default:
          return { color: "bg-green", text: "" }
      }
    }

    const getRequestedTag = () => {
        if (origin === "full") {
            return `Requested on ${mdDate(contactDate)}`;
        } else {
            return "Requested";
        }
    };

    const { color, text } = getStatusInfo()

    return (
        <div
            className="
            rounded-[8px] flex items-center gap-[6px] text-base font-bold"
        >
            <div className={`w-[8px] h-[8px] rounded-full ${color}`}></div>
            {text}
        </div>
    );
};

export default ContactStatusTagMobile;
