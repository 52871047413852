import React from 'react';
import { ProfileRequests } from '../../../api/app.service';
import { CommonFunctionCtx } from '../../../context/commonFunctionContext';
import { CoachPrivateProfileContext } from '../../../pages/manage-business/profile/context/coachPrivateProfileContext';
import CloseModal from '../../CloseModal';
import { Button } from '../../ui/button';
import Loader from '../../ui/loader';
import { SvgCheckbox, SvgCheckboxOff, SvgPlus, SvgVideoOutline } from '../../icons';
import { SvgArrowLeftRight } from '../../icons/arrows/arrow-left-right';

interface Props {
  handleClick: any;
  updateBento: (bentoSpot: number, selection: string, contentId?: number, content?: string, url?: string) => void;
  bentoSelection: number,
}

export default function BentoVideoFlow({ handleClick, updateBento, bentoSelection }: Props) {
  const [videos, setVideos] = React.useState<any[]>([])
  const { coachPublicProfileData, setEditPortraitVideo, editPortraitVideo } = React.useContext(CoachPrivateProfileContext)
  const { renderError } = React.useContext(CommonFunctionCtx)
  const [selectedVideo, setSelectedVideo] = React.useState<any>(null)
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const videoRef = React.useRef<HTMLVideoElement>(null);

  const getVideos = () => {
    setIsLoading(true)
    ProfileRequests.getCoachProfileVideos({
      coach_public_profile_id: coachPublicProfileData?.coach_public_profile_id,
    })
      .then((data) => {
        setVideos(data.videos)
        setIsLoading(false)
      })
      .catch((ex) => {
        console.log(ex)
        renderError(ex.response.data.message)
        setIsLoading(false)
      })
  }
  const getVideoType = (extension: any) => {
    if (extension === "mov") {
      return "mp4";
    } else {
      return extension;
    }
  }


  React.useEffect(() => {
    getVideos()
  }, [coachPublicProfileData])


  return (
    <div className={`fixed top-0 left-0 w-screen h-screen flex items-center justify-center zoee-backdrop-filter z-[1009] ${editPortraitVideo ? "hidden" : ""}`}>
      <div className="w-[775px] max-w-[90%] main-shadow rounded-[16px] pt-[76px] pb-[40px] mt-[90px] px-[16px] md:px-[55px] md:min-h-[70vh] max-h-[85vh] bg-white fixed flex flex-col items-center overflow-y-auto"
      >
        <CloseModal
          callback={() => {
            handleClick("cancel")
          }}
          styling="absolute top-[24px] right-[32px] flex items-center justify-center w-[32px] h-[32px] rounded-full bg-grayFlash"
        />
        <h2 className="text-center top-[10px] left-[10px] sm:left-[30px] sm:top-[30px] md:left-[50px] text-[16px] md:text-[28px] lg:text-[16px] sm:mt-0">
          Featured Content
        </h2>
        <div className="flex gap-x-2 justify-center items-center">
          <SvgVideoOutline />
          <p className="font-bold text-[20px] sm:mt-4 md:-translate-y-2">
            Video
          </p>
        </div>
        <Button
          variant={"link"}
          onClick={() => {
            handleClick("switch content")
          }}
        >
          <SvgArrowLeftRight />
          Switch content type
        </Button>
        <div className="flex flex-col gap-2 justify-center items-center mt-4 w-full gap-y-4 h-full overflow-auto">
          {videos.length > 0 ? (
            <>
              {videos.map((video: any, index: number) => {
                return (
                  <div key={`key${index}`} className='self-start hover:cursor-pointer'
                    onClick={() => {
                      setSelectedVideo(video)
                    }}
                  >
                    <div className='flex gap-x-6' key={index}>
                      {selectedVideo === video ? (
                        <>
                          <SvgCheckbox className='self-center' />
                        </>
                      ) : (
                        <>
                          <SvgCheckboxOff className='self-center' />
                        </>
                      )}
                      {/* TODO: Find a better way to extract thumbnail without using video tag */}
                      <video
                        ref={videoRef}
                        className="w-[53px] h-[85px] object-cover object-center rounded-md"
                        preload="metadata"
                      >
                        <source
                          src={
                            `https://public-profile-videos-${process.env.REACT_APP_USER_ENVIRONMENT}.s3.amazonaws.com/${coachPublicProfileData?.coach_public_profile_id}_about_${video.id}.${video.video_extension}#t=0.1`}
                          type={`video/${getVideoType(video.video_extension)}`}
                        />
                      </video>
                      <p className='text-md truncate font-bold md:max-w-[500px] max-w-[250px] self-center'>

                        {video.title}
                      </p>
                    </div>
                  </div>
                )
              })}
            </>
          ) : (
            <>
              <div className={`h-full flex flex-col items-center justify-center gap-y-8`}>
                {isLoading ? (
                  <Loader />
                ) : (
                  <p className="text-graySlate text-md text-center">
                    No videos available.
                    <br />
                    Add your first video to feature in this section
                  </p>
                )}

              </div>
            </>
          )}
        </div>
        <Button
          variant={"utility"}
          className={`bg-white hover:bg-grayFlash drop-shadow-lg mt-auto`}
          onClick={() => {
            setEditPortraitVideo(true)
          }}
        >
          <SvgPlus />
          Add a Video
        </Button>
        <div className="flex gap-x-2 mt-auto">
          <Button className="mt-8" size="lg"
            variant={"ghost"}
            onClick={() => {
              handleClick("cancel")

            }}
          >
            Cancel
          </Button>

          <Button className="mt-8" size="lg"
            variant={`${selectedVideo ? "default" : "secondary"}`}
            onClick={() => {
              updateBento(bentoSelection, "video", selectedVideo?.id);
              handleClick("cancel")
            }}
            disabled={!selectedVideo}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  )
}
