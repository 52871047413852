import React from "react";
import { CoachPublicProfileContext } from "../../pages/manage-business/profile/context/coachPublicProfileContext";

const PublicProfileBannerImage: React.FC = () => {
  const { coachPublicProfileData } = React.useContext(
    CoachPublicProfileContext
  );

  if (coachPublicProfileData?.has_banner_image) {
    return (
      <div className="bg-grayMist relative aspect-[3.5/1] w-full rounded-xl">
        <img
          src={`${
            coachPublicProfileData?.banner_image
          }?timestamp=${new Date().getTime()}`}
          alt="banner"
          className="h-full w-full rounded-xl object-cover"
        />
      </div>
    );
  }

  return (
    <>
      <div className="relative w-full md:h-[100px] md:rounded-xl" />
    </>
  );
};

export default PublicProfileBannerImage;
