import * as React from "react"
import Avatar from "../../../assets/images/PricingPageAvatar.png"
import FAQ from "../../../components/marketing/pricing/FAQ"
import MarketingPlans from "../../../components/subscription/MarketingPlans"
import OrganizationPlan from "../../../components/marketing/pricing/OrganizationPlan"
import ZoeeChatBox from "../../../assets/images/ZoeeChatBox.png"
import { useLocation } from "react-router-dom"

const PricingPage: React.FC = () => {
  const location = useLocation()

  React.useEffect(() => {
    if (location.hash === "#faq") {
      window.scrollTo({
        top: 650,
        left: 0,
      })
    } else {
      window.scrollTo({
        top: 0,
        left: 0,
      })
    }
  }, [location.hash])

  return (
    <div className="relative">
      <div className="-mt-40 bg-grayMist w-full h-[635px] sm:h-[400px] absolute top-0 left-0 z-0"></div>
      {/* <div className="md:hidden -mt-40 bg-grayMist w-full xsm:h-[475px] md:h-[400px] absolute top-0 left-0 z-0 mb-[72px]"></div> */}
      <div className="h-[100%] relative z-10 mx-auto sm:mt-[88px] max-w-screen-2xl overflow-hidden flex-col flex justify-center ">
        <section className="h-[214px] py-5 sm:flex justify-between md:items-center px-8 md:px-48 mb-[235px] sm:mb-0">
          <div className="text-black md:text-4xl text-3xl font-['Beausite Classic'] ">
            <p className="mb-1 font-bold">The All-in-One Solution</p>
            <p className="text-blurple mb-2 font-bold">
              Less Admin, More Coaching
            </p>
            <p className="text-lg mb-1">
              Limited Time Discount! Sign up now to lock in our special pricing
              forever.
            </p>
          </div>
          <div className="flex items-start gap-2">
            {[Avatar, ZoeeChatBox].map((img, idx) => (
              <div
                key={idx}
                className={`h-[145px] ${
                  img === ZoeeChatBox ? "w-[155px]" : "w-[125px]"
                } bg-no-repeat bg-cover rounded-xl`}
                style={{
                  backgroundImage: `url(${img})`,
                  backgroundPosition: "top",
                  backgroundSize: "cover",
                }}
              ></div>
            ))}
          </div>
        </section>

        <section className="mb-4 ">
          <div className="px-4">
            <MarketingPlans />
          </div>
        </section>

        <section className="h-full flex justify-center items-center">
          <div className="w-full max-w-[1060px] mx-auto flex justify-center items-center p-4 ">
            <OrganizationPlan />
          </div>
        </section>

        <section className="text-center sm:p-4">
          <h3 className="text-black text-[32px] font-bold font-['Beausite Classic']">
            Zoee Coach FAQ: Your Burning Questions, Answered!
          </h3>
          <p className="text-slate-900 text-lg font-['Beausite Classic'] leading-7">
            If you’re already a paying customer or interested in partnering with
            us and need assistance, email us at{" "}
            <a href="mailto:hello@zoee.com" className="underline">
              hello@zoee.com
            </a>
            .
          </p>
          <FAQ />
          <p className="mt-6">
            Not seeing your question here?{" "}
            <span className="underline leading-7">Go to our help center</span>
          </p>
        </section>
      </div>
    </div>
  )
}

export default PricingPage
