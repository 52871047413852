import React, { useContext, useState } from "react";
import {
  ChannelPreviewUIComponentProps,
  useChatContext,
} from "stream-chat-react";
import { ShowChatCtx } from "../../context/showChatContext";
import { UserCtx } from "../../context/userContext";
import { Button } from "../ui/button";
import { SvgTrash } from "../icons";

const ChannelItem = (props: ChannelPreviewUIComponentProps) => {
  const { user } = useContext(UserCtx);
  const { channel, setActiveChannel } = props;
  const { channel: activeChannel, client } = useChatContext();
  const selected = channel?.id === activeChannel?.id;
  const showChatContext = useContext(ShowChatCtx);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);

  const membersLength = Object.keys(channel?.state?.members).length;

  const openDeleteDialog = (e: React.MouseEvent<HTMLButtonElement>) => {

    e.stopPropagation();
    setIsConfirmDialogOpen(true);
  };

  const closeDeleteDialog = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setIsConfirmDialogOpen(false);
  };

  const deletChannel = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    await activeChannel?.delete();
    setIsConfirmDialogOpen(false);
  };

  const renderChannelTitle = () => {
    const names = [];
    const members = Object.keys(channel?.state?.members) || [];
    for (let i = 0; i < members.length; i++) {
      const userId = Object.values(channel?.state?.members)[i]?.user?.id;
      if (userId !== client?.user?.id) {
        names.push(
          Object.values(channel?.state?.members)[i]?.user?.name?.split(" ")[0]
        );
      }
    }
    const joinedNames =
      names.join(", ").length < 18
        ? names.join(", ")
        : `${names.join(", ").slice(0, 18)}...`;
    const messageTitle = channel?.data?.name;
    return messageTitle || joinedNames;
  };

  const renderMessageText = () => {
    const lastMessageText =
      channel.state.messages[channel.state.messages.length - 1]?.text ||
      "No messages";
    return lastMessageText.length > 29
      ? `${lastMessageText.slice(0, 30)}...`
      : lastMessageText;
  };

  const renderMessageImage = () => {
    let channelImage;
    if (membersLength === 2) {
      const notCurrentUser = Object.values(channel?.state?.members).filter(
        (member: any) => {
          const id = user.activeProfileId
          return parseInt(member.user.id) !== id
        }
      );
      channelImage = notCurrentUser[0]?.user?.image
        ? notCurrentUser[0]?.user?.image
        : undefined;
    } else {
      channelImage = channel?.data?.image;
    }
    return channelImage;
  };

  const renderNonImageAvatar = () => {
    const notCurrentUser = Object.values(channel?.state?.members).filter(
      (member: any) => {
        const id = user.activeProfileId
        return parseInt(member.user.id) !== id
      }
    );
    const otherMembersCount = membersLength - 1;
    return (
      <div className="relative">
        <div>
          {notCurrentUser[0]?.user?.image ? (
            <img
              src={notCurrentUser[0].user.image}
              alt="User"
              className="w-12 h-12 rounded-full object-cover"
            />
          ) : (
            <span
              className={`font-bold text-xl w-12 h-12 justify-center
                            rounded-full flex items-center text-white ${
                              notCurrentUser[0]?.user?.user_color
                                ? notCurrentUser[0].user.user_color
                                : "bg-primaryBlue"
                            }`}
            >
              {notCurrentUser[0]?.user?.name?.charAt(0)}
            </span>
          )}
        </div>
        {membersLength > 2 && (
          <div className="absolute top-0 -right-1.5 flex items-center">
            <div className="w-5 h-5 bg-black text-white flex items-center justify-center rounded-full text-xs font-semibold">
              {otherMembersCount}
            </div>
          </div>
        )}
      </div>
    );
  };

  const assignActiveChannel = () => {
    if (channel) {
      showChatContext?.setShowChat(true);
      setActiveChannel && setActiveChannel(channel);
    }
  };

  const getTimeStamp = (channel: any) => {
    let lastHours = channel.state.last_message_at?.getHours();
    let lastMinutes = channel.state.last_message_at?.getMinutes();
    let half = "AM";
    if (lastHours === undefined || lastMinutes === undefined) {
      return "";
    }
    if (lastHours > 12) {
      lastHours = lastHours - 12;
      half = "PM";
    }
    if (lastHours === 0) lastHours = 12;
    if (lastHours === 12) half = "PM";
    if (lastMinutes.toString().length === 1) {
      lastMinutes = `0${lastMinutes}`;
    }
    return `${lastHours}:${lastMinutes} ${half}`;
  };

  const unreadMessages = () => {
    if (channel) {
      if (channel.state.unreadCount > 0) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  };

  const profileId = user.activeProfileId
  const isAbleToModify =
    activeChannel?.state.members[profileId]?.role === "owner" &&
    channel.id === activeChannel?.id;

  return (
    <div
      className={`w-full flex items-center relative h-20 p-4 cursor-pointer
      ${selected && "md:bg-white md:main-shadow md:rounded-[14px]"}`}
      onClick={() => assignActiveChannel()}
    >
      {renderMessageImage() ? (
        <img
          src={`${renderMessageImage()}?{new Date().getTime()`}
          alt="avatar"
          className="mr-5 w-12 h-12 rounded-full object-cover"
        />
      ) : (
        <div
          className="mr-5 w-12 h-12 rounded-full
            bg-white flex items-center "
        >
          {renderNonImageAvatar()}
        </div>
      )}

      <div className="flex flex-col gap-1">
        <h4 className="font-bold text-[16px] w-auto">{renderChannelTitle()}</h4>
        <p className="text-grayCharcoal text-base">{renderMessageText()}</p>
      </div>
      <p className="absolute top-5 right-4 text-graySlate text-base">
        {getTimeStamp(channel)}
      </p>
      {isAbleToModify && (
        <Button
          className="w-[20px] h-[20px] absolute right-[15px] bottom-[16px] z-[55]"
          variant="ghost"
          size="icon"
          onClick={(e) => openDeleteDialog(e)}
        >
          <SvgTrash />
        </Button>
      )}
      <div
        onMouseLeave={() => setIsConfirmDialogOpen(false)}
        className={`flex items-center absolute
            border border-[#dc2626] rounded-[16px] 
            h-[80px] bg-lightGrey p-4 z-50 gap-2
            ${isConfirmDialogOpen ? "right-[0]" : "hidden"}
            z-[60]
        `}
      >
        <Button
          variant="destructive"
          size="sm"
          onClick={(e) => deletChannel(e)}
        >
          Delete chat?
        </Button>
        <Button
          variant="secondary"
          size="sm"
          onClick={(e) => closeDeleteDialog(e)}
        >
          Cancel
        </Button>
      </div>

      <div
        className={`absolute top-[50%] -translate-y-[50%] right-0 h-12 w-[3px] bg-blurple 
        ${selected ? "hidden md:block" : "hidden"}`}
      ></div>
      <div
        className={`absolute bottom-[21px] right-4 w-2 h-2 bg-primaryBlue
        rounded-full ${unreadMessages() ? "block" : "hidden"}`}
      ></div>
    </div>
  );
};

export default ChannelItem;
