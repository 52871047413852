import * as React from "react"
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom"
import { Affiliate } from "./affiliate"
import { ThriveTogether } from "../../_components/thrive-together"
import { ShowcaseVideo } from "../../_components/showcase-video"
import { Features } from "../../_components/features/features"
import { CeoQuote } from "../../_components/ceo-quote"
import { Testimonials } from "../../_components/testimonials/testimonials"
import { SubscribeToNewsletterForm } from "../../_components/subscribe-to-newsletter-form"
import { FeaturedArticles } from "../../_components/articles/featured-articles"
import { AffiliateLandingCTA } from "./affiliate-landing-CTA"
import { useAuthModal } from "../../../../components/auth/use-auth-modal"
import { isLocalStorageAvailable } from "../../../../utils/isLocalStorageAvailable"
import { CommonFunctionCtx } from "../../../../context/commonFunctionContext"
import useScrollToTop from "../../../../hooks/useScrollToTop"

export default function AffiliateHomePage() {
  const location = useLocation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { promocode } = useParams()

  const { showRegister } = useAuthModal()
  const { setPopupNotification, setShowAuthModal } =
    React.useContext(CommonFunctionCtx)

  const checkUrlParams = () => {
    const message = location?.state?.message

    const reset = searchParams.get("reset")
    const emailParams = searchParams.get("email")
    const phoneParams = searchParams.get("phone")
    const firstNameParams = searchParams.get("firstname")
    const lastNameParams = searchParams.get("lastname")
    const signupType = searchParams.get("signuptype")
    const login = searchParams.get("login")

    if (message === "no-code" || message === "wrong-code") {
      setPopupNotification({
        show: true,
        title: "Invalid Email Verification!",
        message:
          "Oops! Looks like your email verification link didn't work properly. Try sending a new verification link by clicking the 'Verify Email' button on this page.",
        callback: null,
      })
      window.history.replaceState(null, "", window.location.pathname)
    }
    if (login) {
      setShowAuthModal(true)
    }
    if (message === "success") {
      setPopupNotification({
        show: true,
        title: "Email Verified!",
        message:
          "Your email address is now verified with Zoee. You can change your primary email address whenever you'd like, but will need to verify it after each change.",
        callback: null,
      })
      window.history.replaceState(null, "", window.location.pathname)
    }

    if (reset === "true") {
      setPopupNotification({
        show: true,
        title: "Reset Password Link Sent!",
        message:
          "An email has been sent to the email address associated with your account. Please check your email for a link to reset your password. 🎉",
        callback: null,
      })
      window.history.replaceState(null, "", window.location.pathname)
    } else if (reset === "complete") {
      setPopupNotification({
        show: true,
        title: "Reset Password Complete!",
        message: "You can now log in with your email and your new password. 🎉",
        callback: null,
      })
      window.history.replaceState(null, "", window.location.pathname)
    }

    if (emailParams && phoneParams && firstNameParams && lastNameParams) {
      showRegister({
        accountType: "coach",
        email: emailParams,
        phone: phoneParams,
        firstName: firstNameParams,
        lastName: lastNameParams,
      })
      if (signupType) {
        localStorage.setItem("signupType", signupType)
      }
    }

    if (signupType === "member" && emailParams) {
      showRegister({ accountType: "member", email: emailParams })
    }
  }

  const setLocalStorageReferrerPromoCode = () => {
    if (promocode) {
      if (isLocalStorageAvailable()) {
        localStorage.setItem("referrerPromoCode", promocode)
      }
    }
  }

  React.useEffect(() => {
    checkUrlParams()

    setLocalStorageReferrerPromoCode()

    const coachRegisterParam = searchParams.get("coachRegister")
    if (coachRegisterParam === "true") {
      // showRegister({ accountType: "coach" });
    }

    if (location?.state?.storageAvailable === false) {
      setPopupNotification({
        show: true,
        title: "Oh no! Browser Storage is Disabled",
        message:
          "You'll need to enable cookies in your browser to login or sign up for the Zoee application.",
      })
      navigate(location.pathname, { replace: true })
    }
  }, [])

  React.useEffect(() => {
    if (promocode) {
      // showRegister({ accountType: "coach" });
    }
  }, [promocode])

  useScrollToTop()

  return (
    <>
      <Affiliate />
      <ThriveTogether />
      <ShowcaseVideo />
      <Features />
      <CeoQuote />
      <Testimonials />
      <FeaturedArticles />
      <SubscribeToNewsletterForm />
      <AffiliateLandingCTA />
    </>
  )
}
