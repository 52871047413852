import React from "react";
import { Link } from "react-router-dom";
import { UserCtx } from "../../../context/userContext";
import { CoachPrivateProfileContext } from "../../../pages/manage-business/profile/context/coachPrivateProfileContext";
import CloseModal from "../../CloseModal";
import { SvgSyncNeeded } from "../../icons";

interface Props {}

const ProfileSyncPrompt: React.FC<Props> = (props:any) => {
    const { nextStepsData, setSyncPrompt, connectAccountData } = React.useContext(CoachPrivateProfileContext);
    const { user } = React.useContext(UserCtx);
    const closeAndReset = () => {
        setSyncPrompt(false);
    };

    return (
        <div className="fixed top-0 left-0 w-screen h-screen pb-[100px] md:pb-0 zoee-backdrop-filter z-[801] flex items-center justify-center">
            <div
                className="mx-[20px] md:w-[600px] w-full py-[48px] px-[20px] md:px-[72px] bg-white 
                main-shadow rounded-[16px] flex flex-col items-center relative gap-[20px]"
            >
                <CloseModal callback={closeAndReset} styling="absolute top-[24px] right-[24px] cursor-pointer" />
                <SvgSyncNeeded />
                <h3 className="font-bold md:font-semibold text-[18px] md:text-[28px] mx-auto text-center">
                    To publish your coach profile, you'll need to complete the following steps:
                </h3>
                <div className="flex flex-col items-start w-full gap-[4px]">
                    <div className="flex items-center gap-[8px]">
                        <span className="font-bold text-grayCharcoal">1.</span>
                        <Link
                            to="/account-settings/calendars"
                            className="flex items-center gap-[4px]"
                        >
                            <span>Connect your calendar</span>
                            {nextStepsData?.is_calendar_connect ? <span>✅</span> : <span>❌</span>}
                        </Link>
                    </div>
                    <div className="flex items-center gap-[8px]">
                        <span className="font-bold text-grayCharcoal">2.</span>
                        <Link
                            to="/account-settings/calendars"
                            className="flex items-center gap-[4px]"
                        >
                            <span>Please verify your identity with Stripe</span>
                            {connectAccountData?.identity_status === 'verified' ? <span>✅</span> : <span>❌</span>}
                        </Link>
                    </div>
                </div>
                <p className="text-graySlate text-[16px] text-center mx-auto">
                    Zoee is committed to the safety of our coaches and clients and requires coaches to verify their identities with Stripe.
                </p>
                <div className="flex items-center mx-auto gap-[8px] flex-col sm:flex-row">
                    {!nextStepsData.is_calendar_connect && (
                        <Link
                            to={`/account-settings/calendars`}
                            className="btn-primary btn-blue"
                        >
                            Connect Calendar
                        </Link>
                    )}
                    {connectAccountData?.identity_status !== 'verified' && (
                        <Link
                            to={`/${user.activeProfile}/client-payments`}
                            className="btn-primary btn-blue"
                        >
                            Verify Your Identity
                        </Link>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ProfileSyncPrompt;
