import React from "react"
import { SvgCheckCircle } from "../../components/icons"
import TrialSubscriptionCard from "../../components/subscription/TrialSubscriptionCard"

type Props = {
  isNewPlan: boolean
  subscription: any
}

const ICFSubscriptionCheckoutCard: React.FC<Props> = ({
  isNewPlan,
  subscription,
}) => {
  const [paymentPeriod, setPaymentPeriod] = React.useState<string>("")
  const [subscriptionCardTitle, setSubscriptionCardTitle] =
    React.useState<string>("")
  const [twoYearSubscription, setTwoYearSubscription] =
    React.useState<boolean>(false)
  const [price, setPrice] = React.useState<string>("")
  const [annualPeriod, setAnnualPeriod] = React.useState<string>("")
  const [marketPrice, setMarketPrice] = React.useState<string>("")

  const setSubscriptionCardData = () => {
    if (
      subscription?.recurring_interval === "annual" &&
      !subscription?.is_promotional
    ) {
      setPrice("27.50")
      setMarketPrice("$55/mo")
      setPaymentPeriod("/month")
      setAnnualPeriod("Billed annually")
      setSubscriptionCardTitle("Annual Pro")
    }
    if (
      subscription?.recurring_interval === "month" &&
      !subscription?.is_promotional
    ) {
      setPrice("34.50")
      setMarketPrice("$69/mo")
      setPaymentPeriod("/month")
      setSubscriptionCardTitle("Monthly Pro")
    }
  }

  React.useEffect(() => {
    setSubscriptionCardData()
  }, [subscription])

  return (
    <div
      className={`min-w-[300px] h-full flex flex-col main-shadow rounded-[32px] ${
        subscription?.recurring_interval === "annual" || twoYearSubscription
          ? "subscription-cta-gradient-border"
          : "border-4 border-black"
      }`}
    >
      <div
        className={`w-full h-[35px] rounded-t-[28px] flex items-center justify-center ${
          subscription?.recurring_interval === "annual" || twoYearSubscription
            ? "subscription-cta-gradient"
            : "bg-black"
        }`}
      >
        <p className="text-center text-white">50% off for ICF members</p>
      </div>

      <div className="bg-white rounded-b-[32px] p-10">
        <h2 className="md:mb-[8px] mb-[4px] font-semibold text-[28px]">
          {subscriptionCardTitle}
        </h2>
        <p className="md:mb-[40px] mb-[20px] text-graySlate -mt-2">
          {subscription?.stripe_product_description}
        </p>
        <div className="md:mb-[10px] mb-[16px] flex font-bold items-start gap-[4px] leading-none">
          <h4 className="text-[36px] text-grayCharcoal">$</h4>
          <p className="md:text-[80px] text-[64px] -translate-y-[10px]">
            {price}
          </p>
          <h4 className="md:text-[18px] text-base text-graySlate">
            {paymentPeriod}
          </h4>
        </div>
        <div className="mb-5">
          <p className="text-[18px] text-graySlate line-through">
            {marketPrice}
          </p>
          <p className="text-[18px] text-graySlate">{annualPeriod}</p>
        </div>
        <div className="flex flex-col gap-[12px]">
          {Array.from({ length: 10 }, (_, index) => (
            <div className="flex items-center gap-[16px]" key={index}>
              <SvgCheckCircle stroke="#5738d2" />
              <p className="text-large">
                {subscription?.stripe_product_metadata[`feature${index + 1}`]}
              </p>
            </div>
          ))}
        </div>
        <p className="text-[14px] mt-[15px] mr-[1px] mb-2">
          *Valid for one whole year{" "}
        </p>
      </div>
    </div>
  )
}

export default ICFSubscriptionCheckoutCard
