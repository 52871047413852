import * as React from "react"
import { Link } from "react-router-dom"
import { BlogPost } from "../../models/blog.interface"
import { Button } from "../ui/button"
import { CoachPrivateProfileContext } from "../../pages/manage-business/profile/context/coachPrivateProfileContext"
import { CoachPublicProfileContext } from "../../pages/manage-business/profile/context/coachPublicProfileContext"
import { SvgPlus } from "../icons/"

interface Props {
  post: BlogPost | null
  endpointSlug?: string
  blogTag?: boolean
  isEditable?: boolean
  coachColor?: any
  selectedBlogPost?: BlogPost | undefined
}

const BlogCard: React.FC<Props> = ({
  post,
  blogTag = false,
  endpointSlug,
  isEditable = true,
  coachColor,
}) => {
  const [showEditButtonHover, setShowEditButtonHover] = React.useState(false)
  const { setCreateBlogPost } = React.useContext(CoachPrivateProfileContext)

  const renderEditButton = () => {
    if (isEditable && showEditButtonHover) {
      return (
        <Button
          onClick={() => {
            setCreateBlogPost(true)
          }}
          variant={"utility"}
          size={"lg"}
          className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 py-[4px] px-[20px] bg-white border z-3 border-[1px] shadow-md opacity-100"
        >
          <SvgPlus />
          Add
        </Button>
      )
    }
    return null
  }

  const readTime = (post: BlogPost) => {
    const fullString = post.content
      .flatMap(({ type, children }) => {
        if (type !== "bulleted-list" && type !== "numbered-list") {
          return children.flatMap((item) => item.text)
        }

        return children.flatMap((listItem) =>
          listItem.children.map((thing) => thing.text)
        )
      })
      .join(" ")

    const wpm = 225
    const words = fullString?.trim().split(/\s+/).length
    const time = Math.ceil(words / wpm)
    return time
  }

  return (
    <>
      {post ? (
        <Link
          to={`/find-a-coach/${endpointSlug}/blog/${post.id}`}
          className={`aspect-square w-full h-full rounded-[20px] p-2.5 bg-cover bg-center flex flex-col justify-between hover:scale-102 transition-transform shadow-lg`}
          style={{
            backgroundImage: `url(https://blog-images-${process.env.REACT_APP_USER_ENVIRONMENT}.s3.amazonaws.com/${post.id})`,
            backgroundColor: `${coachColor}`,
          }}
        >
          {blogTag && (
            <div className="font-bold bg-white/80 rounded-[15px] py-1 px-3 w-fit">
              Blog
            </div>
          )}
          <div className="flex flex-col gap-2 bg-white/80 rounded-[15px] p-3 mt-auto">
            <h3 className="font-bold text-lg text-black line-clamp-2 text-balance">
              {post.title}
            </h3>
            <span className="text-black text-base">
              {readTime(post)} min read
            </span>
          </div>
        </Link>
      ) : (
        <>
          <div
            className={`h-[100%] rounded-[20px] flex flex-col p-2 relative ${post ? "" : ""
              }`}
            onMouseEnter={() => setShowEditButtonHover(true)}
            onMouseLeave={() => setShowEditButtonHover(false)}
          >
            <div className="flex justify-center items-center h-full w-full text-center">
              <p className="text-graySlate text-md font-bold max-w-[65%]">
                Add a blog to get started
              </p>
            </div>
            {renderEditButton()}
          </div>
        </>
      )}
    </>
  )
}

export default BlogCard
