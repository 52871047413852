import React from 'react';
import { PaymentRequests } from "../../../api/app.service";
import { UserCtx } from "../../../context/userContext";
import { CommonFunctionCtx } from "../../../context/commonFunctionContext";
import useLoading from '../../../hooks/useLoading';
import { Button } from '../../ui/button';
import { SvgAmericanExpress, SvgAsterisk, SvgDinersClub, SvgDiscover, SvgJcb, SvgMastercard, SvgTrash, SvgVisa } from '../../icons/';
import { Badge } from '../../ui/badge';

interface Props {
    card: any;
    setShowDeleteCard: any;
    getPaymentMethods: any;
}

export default function SavedCard({ card, setShowDeleteCard, getPaymentMethods }: Props) {
    const { user } = React.useContext(UserCtx);
    const {
        renderError, renderSuccess 
    } = React.useContext(CommonFunctionCtx);
    const { stopLoading, startLoading } = useLoading();

    const getCardImage = () => {
      switch (card?.card_brand) {
        case "visa":
          return SvgVisa
        case "mastercard":
          return SvgMastercard
        case "discover":
          return SvgDiscover
        case "amex":
          return SvgAmericanExpress
        case "jcb":
          return SvgJcb
        case "diners_club":
          return SvgDinersClub
        default:
          return SvgAsterisk
      }
    }

    const CardImage = getCardImage()

    const setDefaultCard = () => {
        if(user){
            startLoading();
            PaymentRequests.setDefaultPaymentMethod({
                user_id: parseInt(user.user_id),
                stripe_payment_method_id: card.stripe_payment_method_id
            }).then((data) => {
                getPaymentMethods();
                renderSuccess(data.message);
            }).catch((ex) => {
                console.log(ex);
                renderError(ex.response.data.message);
                stopLoading();
            });
        }
    }

    return(
        <div 
            className={`w-full sm:h-[80px] flex flex-col gap-[20px] sm:gap-0 sm:flex-row 
            justify-between items-center main-shadow rounded-[16px] p-[24px] sm:px-[24px] sm:py-0
            ${card.last_charge_failed ? "border border-1 border-vividRed" : ""}`}
        >
            <div className="flex items-center w-full jusfify-between">
                <div className='flex items-center min-w-fit'>
                    <CardImage className='mr-4' />
                    <div className="flex items-center gap-1 mr-4">
                        <SvgAsterisk className='w-2 h-2' />
                        <SvgAsterisk className='w-2 h-2' />
                        <SvgAsterisk className='w-2 h-2' />
                        <SvgAsterisk className='w-2 h-2' />
                    </div>
                    <span className='font-bold text-base'>{card?.card_last4}</span>
                </div>
                <div className="min-w-fit ml-auto sm:ml-[12px] text-muted-foreground text-base">
                    {`Exp ${card?.card_expiration}`}
                </div>
                {!!card.last_charge_failed && (
                    <p className='md:hidden lg:block ml-[30px] text-base text-vividRed font-bold'>Last charge failed</p>
                )}
            </div>
            <div 
                className={`flex items-center w-full justify-end gap-8`}
            >
                {card?.is_default ? (
                    <Badge variant="success">Default</Badge>
                ) : (
                    <Button onClick={setDefaultCard} variant="secondary">Make Default</Button>
                )}
                <Button variant="destructive" size="icon" onClick={() => setShowDeleteCard({ show: true, card })}><SvgTrash /></Button>
            </div>
        </div>
    )
}
