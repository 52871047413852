import React from 'react';
import Peer from './Peer';
import {
    useHMSStore,
    selectPeers
  } from "@100mslive/react-sdk";
import { SessionContext } from '../../pages/sessions/context/sessionContext';
import { SvgChevronRight } from '../icons';

interface Props {}

const GalleryView:React.FC<Props> = () => {
    const { mobileView, fullScreen } = React.useContext(SessionContext);
    const peers = useHMSStore(selectPeers);
    const [videoRange, setVideoRange] = React.useState<any>({start: 0, end: 6});
    const [mobileVideoRange, setMobileVideoRange] = React.useState<any>({start: 0, end: 4});
    const [currentPage, setCurrentPage] = React.useState<number>(1);
    const [currentMobilePage, setCurrentMobilePage] = React.useState<number>(1);
    const totalPages = Math.ceil(peers.length / 6);
    const totalMobilePages = Math.ceil(peers.length / 4);

    const handlePageLeft = () => {
        const newStart = videoRange.start - 6;
        const newEnd = videoRange.end - 6;
        setVideoRange({start: newStart, end: newEnd});
        setCurrentPage(currentPage - 1);
    }
    
    const handlePageRight = () => {
        const newStart = videoRange.start + 6;
        const newEnd = videoRange.end + 6;
        setVideoRange({start: newStart, end: newEnd})
        setCurrentPage(currentPage + 1);
    }

    const handleMobilePageLeft = () => {
        const newStart = mobileVideoRange.start - 4;
        const newEnd = mobileVideoRange.end - 4;
        setMobileVideoRange({start: newStart, end: newEnd});
        setCurrentMobilePage(currentMobilePage - 1);
    }
    
    const handleMobilePageRight = () => {
        const newStart = mobileVideoRange.start + 4;
        const newEnd = mobileVideoRange.end + 4;
        setMobileVideoRange({start: newStart, end: newEnd})
        setCurrentMobilePage(currentMobilePage + 1);
    }
    
    return (
        <>
            {mobileView ? (
                <>
                    {peers.length >= 5 && (
                        <div className="fixed right-[24px] top-[42px] flex items-center gap-[16px] z-[20]">
                            <button
                                className='mr-[16px] w-[40px] h-[40px] rounded-full
                                bg-white flex items-center justify-center disabled:bg-gray'
                                disabled={mobileVideoRange.start === 0}
                                onClick={handleMobilePageLeft}
                            >
                                <SvgChevronRight stroke="#3395FF" className='rotate-[180deg]' />
                            </button>
                            <p className="text-white font-bold text-base">{`${currentMobilePage}/${totalMobilePages}`}</p>
                            <button
                                className='ml-[16px] w-[40px] h-[40px] rounded-full
                                bg-white flex items-center justify-center disabled:bg-gray'
                                disabled={mobileVideoRange.end >= peers.length}
                                onClick={handleMobilePageRight}
                            >
                                <SvgChevronRight stroke="#3395FF" className='' />
                            </button>
                        </div>
                    )}
                </>
            ) : (
                <>
                    {peers.length >= 7 && (
                        <div className="absolute left-[50%] -translate-x-[50%] top-[28px] flex items-center gap-[16px]">
                            <button
                                className='mr-[16px] w-[40px] h-[40px] rounded-full
                                bg-white flex items-center justify-center disabled:bg-gray'
                                disabled={videoRange.start === 0}
                                onClick={handlePageLeft}
                            >
                                <SvgChevronRight stroke="#3395FF" className='rotate-[180deg]' />
                            </button>
                            <p className="text-white font-bold text-base">{`${currentPage}/${totalPages}`}</p>
                            <button
                                className='ml-[16px] w-[40px] h-[40px] rounded-full
                                bg-white flex items-center justify-center disabled:bg-gray'
                                disabled={videoRange.end >= peers.length}
                                onClick={handlePageRight}
                            >
                                <SvgChevronRight stroke="#3395FF" className='' />
                            </button>
                        </div>
                    )}
                </>
            )}
            {peers.length === 1 && (
                <Peer
                    peer={peers[0]}
                    peerType="full-screen"
                    peerCount={peers.length}
                    currentPage={currentPage}
                />
            )}
            {peers.length > 1 && (
                <div 
                    className={`absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%]
                    w-full flex flex-wrap items-center justify-center gap-[12px]
                    ${fullScreen ? "max-w-[95%]" : ""}`}
                >
                    {mobileView ? (
                        <>
                            {peers.slice(mobileVideoRange.start, mobileVideoRange.end).map((peer) => (
                                <Peer
                                    peer={peer}
                                    peerType="gallery"
                                    peerCount={peers.length}
                                    currentPage={currentMobilePage}
                                />
                            ))}
                        </>
                    ) : (
                        <>
                            {peers.slice(videoRange.start, videoRange.end).map((peer) => (
                                <Peer
                                    peer={peer}
                                    peerType="gallery"
                                    peerCount={peers.length}
                                    currentPage={currentPage}
                                />
                            ))}
                        </>
                    )}
                    
                </div>
            )}
            
        </>
  );
}

export default GalleryView;
