import useName from "../../hooks/useName";
import { getUserColor } from "../../utils/getUserColor";

interface Props {
  contact: any;
  width: number;
  height: number;
  border?: string;
  fontSize?: string;
  bold: boolean;
}

export default function ContactAvatar({ contact, width, height, border, bold, fontSize }: Props) {
  const { has_avatar, user_color } = contact;
  const { initial } = useName(contact);

  return (
    <div
      className={`rounded-full overflow-hidden flex items-center justify-center ${
        getUserColor(user_color)
      }`}
      style={{
        width: `${width}px`,
        height: `${height}px`,
        border: border,
      }}
    >
      {has_avatar ? (
        <img
          src={`https://profile-avatars-${process.env.REACT_APP_USER_ENVIRONMENT}.s3.amazonaws.com/${contact?.user_id}`}
          alt="avatar"
          className="w-full h-full object-cover"
        />
      ) : (
        <span
          className={`${bold ? "font-bold" : ""} ${
            fontSize ? fontSize : "text-[22px]"
          }`}
        >
          {initial}
        </span>
      )}
    </div>
  );
};
