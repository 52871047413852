import PrivateProfileBlog from "./PrivateProfileBlog";
import PortraitVideoDeck from "./PortraitVideoDeck";

export default function PrivateProfileFeatureTab() {
  return (
    <>
      <h1 className="text-xl font-bold">Video</h1>
      <PortraitVideoDeck />
      <h1 className="text-xl font-bold">Blog</h1>
      <PrivateProfileBlog />
    </>
  );
};
