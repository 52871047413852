import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import OverviewVideo from "./OverviewVideo";
import PublicProfileBlogPost from "../../coach-public-profile/PublicProfileBlogPost";
import PrivateBentoBlogCard from "./PrivateBentoBlogCard";
import PublicOverviewTestimonials from "./PublicOverviewTestimonials";
import PrivateOverviewTestimonials from "./PrivateOverviewTestimonials";
import { getLightTextColor } from "../../../utils/getLightTextColor";
import { PublicProfileRequests } from "../../../api/public.service";
import { CommonFunctionCtx } from "../../../context/commonFunctionContext";

type BoxType = "quote" | "video" | "blog" | "testimonials" | "image";
interface Props {
  coachData: any;
  endpointSlug: any;
  coachColor: any;
  bentoBoxNumber: string;
  setBoxType: Dispatch<SetStateAction<BoxType | undefined>>
  isPublicProfileView: boolean;
  boxType: BoxType | undefined;
  setActiveTab?: any;
  editBento?: boolean;
  isEditable?: any;
}

export default function BentoSelectedContent({ coachData, setActiveTab, coachColor, boxType, isPublicProfileView, bentoBoxNumber, setBoxType }: Props) {
  const [selectedTestimonialId, setSelectedTestimonialId] = useState<
    number | undefined
  >();
  const [quote, setQuote] = useState<string>("");
  const [selectedVideoId, setSelectedVideoId] = useState<number | null>(null);
  const [imgUrl, setImgUrl] = useState<string>("");
  const [selectedBlogPostId, setSelectedBlogPostId] = useState<
    number | undefined
  >();
  const { renderError } = useContext(CommonFunctionCtx);
  const userColor = getLightTextColor(coachColor);
  const boxProperty = `bento_box_${bentoBoxNumber}`;

  const contentToDisplay = () => {
    switch (boxType) {
      case "video":
        return (
          <OverviewVideo
            coachData={coachData}
            videoIdToDisplay={selectedVideoId}
          />
        );
      case "blog":
        return (
          <>
            {isPublicProfileView ? (
              <PublicProfileBlogPost
                selectedBlogPostId={selectedBlogPostId}
                blogTag
              />
            ) : (
              <PrivateBentoBlogCard selectedBlogPostId={selectedBlogPostId} />
            )}
          </>
        );
      case "testimonials":
        return (
          <>
            {isPublicProfileView ? (
              <PublicOverviewTestimonials
                selectedTestimonialId={selectedTestimonialId}
                setActiveTab={setActiveTab}
              />
            ) : (
              <PrivateOverviewTestimonials
                selectedTestimonialId={selectedTestimonialId}
              />
            )}
          </>
        );
      case "quote":
        return (
          <>
            <div
              className="flex h-full w-full items-center justify-center rounded-[20px] bg-cover bg-center bg-no-repeat p-4"
              style={{ backgroundColor: userColor }}
            ></div>
            <p className="font-md absolute left-1/2 top-1/2 w-full -translate-x-1/2 -translate-y-1/2 transform p-4 text-center font-bold">
              "{quote}"
            </p>
          </>
        );

      case "image":
        return (
          <div
            className="h-full w-full rounded-[20px]"
            style={{ backgroundColor: userColor }}
          />
        );

      case undefined && !isPublicProfileView:
        return (
          <div className="flex h-full w-full items-center justify-center text-center">
            <p className="text-graySlate text-md max-w-[65%] font-bold">
              Add content
            </p>
          </div>
        );

      default:
        return null;
    }
  };

  useEffect(() => {
    const getData = () =>
      PublicProfileRequests.getCoachPublicProfile({
        endpoint_slug: coachData?.endpoint_slug,
      })
        .then((data) => {
          if (data.coach_public_profile) {
            setBoxType(data?.coach_public_profile?.[boxProperty]?.type);
            setSelectedVideoId(
              data?.coach_public_profile?.[boxProperty]?.data?.type_id
            );
            setQuote(data?.coach_public_profile?.[boxProperty]?.data?.quote);
            setImgUrl(data.coach_public_profile?.[boxProperty]?.data?.img_url);
            setSelectedTestimonialId(
              data?.coach_public_profile?.[boxProperty]?.data?.type_id
            );
            setSelectedBlogPostId(
              data?.coach_public_profile?.[boxProperty]?.data?.type_id
            );
          }
        })
        .catch((ex) => {
          renderError("Error requesting coach data");
          console.error(ex);
        });

    if (coachData?.endpoint_slug) {
      getData();
    }
  }, [
    coachData,
    selectedBlogPostId,
    selectedVideoId,
    imgUrl,
    selectedTestimonialId,
  ]);

  return contentToDisplay();
};
