import * as React from "react";
import { Button } from "../../ui/button";
import { SwapAuthAction } from "../swap-auth-action";
import { AccountType, RegisterFormContext } from "./context";

export const AccountTypeForm: React.FC = () => {
  const { setAccountType, setView } = React.useContext(
    RegisterFormContext,
  );

  const handleAccountSelection = (accountType: AccountType) => {
    setAccountType(accountType);

    setView("email");
  };

  return (
    <div className="flex flex-col gap-4">
      <p className="text-muted-foreground text-sm text-center">
        Choose the account type that best describes you
      </p>
      <div className="grid sm:grid-cols-2 gap-2">
        <Button
          className="text-md h-auto py-6"
          variant="outline"
          onClick={() => handleAccountSelection("member")}
        >
          Find a Coach
        </Button>
        <Button
          className="text-md h-auto py-6"
          onClick={() => handleAccountSelection("coach")}
        >
          I'm a Coach
        </Button>
      </div>

      <SwapAuthAction />
    </div>
  );
};
