import React from "react";
import { getTextColor } from "../../utils/getTextColor";

interface Props {
    coachPublicProfileData: any;
    initialSize?: string;
}

const PublicAvatarImage: React.FC<Props> = ({coachPublicProfileData, initialSize}) => {
    return (
        <div className="min-w-full h-full rounded-[20px] group relative shadow-md">
            {coachPublicProfileData?.has_avatar ? (
                <img
                    src={`https://profile-avatars-${process.env.REACT_APP_USER_ENVIRONMENT}.s3.amazonaws.com/${coachPublicProfileData?.user_id}?t=${new Date().getTime()}`}
                     alt="avatar"
                    className="w-full h-full rounded-[20px] object-cover"
                />
            ) : (
                <div 
                    className={`flex items-center justify-center rounded-[20px]
                    w-full h-full font-bold ${initialSize ? initialSize : "text-[32px] md:text-[40px]"}`}
                    style={{
                        backgroundColor: `${coachPublicProfileData?.profile_information?.profileColor}`,
                        color: `${getTextColor(coachPublicProfileData?.profile_information?.profileColor)}`
                    }}
                >
                    {coachPublicProfileData?.first_name?.charAt(0)}
                </div>
            )}
        </div>
    )
}

export default PublicAvatarImage;
