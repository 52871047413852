import React from 'react';
import { PaymentRequests } from "../../../api/app.service";
import { UserCtx } from "../../../context/userContext";
import { CommonFunctionCtx } from "../../../context/commonFunctionContext";
import CloseModal from '../../CloseModal';
import useLoading from '../../../hooks/useLoading';
import { Button } from '../../ui/button';

interface Props {
  setShowDeleteCard: any;
  showDeleteCard: any;
  getPaymentMethods: any;
}

export default function DeleteCardModal({ setShowDeleteCard, showDeleteCard, getPaymentMethods }: Props) {
  const { user } = React.useContext(UserCtx);
  const { renderError, renderSuccess } = React.useContext(CommonFunctionCtx);
  const { startLoading, stopLoading } = useLoading();
  const { card } = showDeleteCard;

  const deleteCard = () => {
    startLoading();
    PaymentRequests.deletePaymentMethod({
      user_id: parseInt(user.user_id),
      stripe_payment_method_id: card.stripe_payment_method_id
    }).then((data) => {
      stopLoading();
      getPaymentMethods();
      renderSuccess(data.message);
      setShowDeleteCard({show: false, card: {}})
    }).catch((err) => {
      console.error(err);
      renderError(err.response.data.message);
      stopLoading();
    });
  }

  return(
    <div className='fixed top-0 left-0 w-screen h-screen zoee-backdrop-filter z-[5001] flex items-center justify-center pb-[160px] md:pb-0'>
      <div className="pb-[24px] px-[24px] sm:px-[48px] h-auto w-[335px] sm:w-[600px] bg-white main-shadow rounded-[16px] flex flex-col items-center relative">
        <CloseModal callback={() => setShowDeleteCard({ show: false, card: {}})} styling="absolute top-6 right-6" />
        <h3 className="mt-[48px] md:mt-[72px] sm:mt-[40px] font-bold text-[22px]">Delete Payment Method</h3>
        <p className="mt-[16px] text-graySlate text-base text-center w-[236px]">
          Are you sure you want to delete this payment method &nbsp;
          <span className='font-bold text-black'>{`**** ${card?.card_last4}?`}</span>
        </p>
        <div className="flex items-center gap-4 justify-center mt-[32px]">
          <Button variant="ghost" onClick={() => setShowDeleteCard({show: false, card: {}})}>Cancel</Button>
          <Button onClick={deleteCard} variant="destructive">Delete Card</Button>
        </div>
      </div>
    </div>
  )
}
