import React, { FC, useContext, useEffect, useState } from "react"
import { Link, Outlet, useLocation } from "react-router-dom"
import { CoachPublicProfileContext } from "../manage-business/profile/context/coachPublicProfileContext"
import { CommonFunctionCtx } from "../../context/commonFunctionContext"
import { PublicProfileRequests } from "../../api/public.service"
import { ServiceRequests } from "../../api/app.service"
import PublicProfileBannerImage from "../../components/coach-public-profile/PublicProfileBannerImage"
import PublicProfileInformation from "../../components/coach-public-profile/PublicProfileInformation"
import PublicTilesCarousel from "../../components/coach-public-profile/PublicTilesCarousel"
import { UserCtx } from "../../context/userContext"
import { useAuthenticatedUser } from "../../hooks/useAuthenticatedUser"
import moment from "moment"
import { Button } from "../../components/ui/button"
import useLoading from "../../hooks/useLoading"
import ProfileSchedulerPopup from "../../components/coach-public-profile/ProfileSchedulerPopup"
import { getLightTextColor } from "../../utils/getLightTextColor"

const CoachPublicProfile: FC = () => {
  const { user } = useContext(UserCtx)
  const authenticatedUser = useAuthenticatedUser(user)
  const location = useLocation()
  const { renderError } = useContext(CommonFunctionCtx)
  const { startLoading, stopLoading } = useLoading()
  const [coachPublicProfileData, setCoachPublicProfileData] = useState<any>({})
  const [selectedTile, setSelectedTile] = useState<string>("")
  const [hasProfileVideo, setHasProfileVideo] = useState<boolean>(false)
  const [loadingServices, setLoadingServices] = useState<boolean>(false)
  const [largeVideo, setLargeVideo] = useState<any>({ show: false, video: {} })
  const [services, setServices] = useState<any[]>([])
  const [consultationModalOpen, setConsultationModalOpen] =
    React.useState<boolean>(false)
  const [displayBlogPost, setDisplayBlogPost] = useState<any>({
    show: false,
    post: {},
  })
  const textColor = getLightTextColor(
    coachPublicProfileData?.profile_information?.profileColor
  )
  const [showServiceDetails, setShowServiceDetails] = useState<any>({
    show: false,
    service: {},
  })
  const [isOwner, setIsOwner] = useState<boolean>(
    authenticatedUser &&
      parseInt(user?.user_id) === coachPublicProfileData?.user_id
  )

  const endpointSlug = location.pathname.split("/")[2]

  const getCoachPublicProfile = async () => {
    startLoading()
    await PublicProfileRequests.getCoachPublicProfile({
      endpoint_slug: endpointSlug,
    })
      .then((data) => {
        if (data.coach_public_profile) {
          setCoachPublicProfileData(data.coach_public_profile)
        } else {
          setCoachPublicProfileData(null)
        }
      })
      .catch((ex) => {
        renderError(ex.response.data.message)
      })
      .finally(() => {
        stopLoading()
      })
  }

  const getServices = async () => {
    setLoadingServices(true)
    await ServiceRequests.getClientServiceList({
      profile_id: parseInt(coachPublicProfileData?.coach_profile_id),
      profile_type: "coach",
    })
      .then((data: any) => {
        const visibleServices = data?.service_list?.filter((service: any) => {
          const visible = service?.service_details?.visibility?.profile?.service
          const enabled = service?.service_details?.status === "active"
          const enrollmentOpen =
            moment(service?.enroll_by_date) > moment() ||
            service?.enroll_by_date === null
          return visible && enabled && enrollmentOpen
        })
        setServices(visibleServices)
        setLoadingServices(false)
      })
      .catch((ex) => {
        console.log(ex)
        renderError(ex.response.data.message)
        setLoadingServices(false)
      })
  }

  const CoachPrivateProfileContextValues = {
    coachPublicProfileData,
    setCoachPublicProfileData,
    selectedTile,
    setSelectedTile,
    hasProfileVideo,
    setHasProfileVideo,
    getCoachPublicProfile,
    services,
    getServices,
    setServices,
    largeVideo,
    setLargeVideo,
    loadingServices,
    setLoadingServices,
    displayBlogPost,
    setDisplayBlogPost,
    showServiceDetails,
    setShowServiceDetails,
    isOwner,
  }

  useEffect(() => {
    if (coachPublicProfileData?.coach_profile_id) {
      getServices()
    }
  }, [coachPublicProfileData])

  useEffect(() => {
    setIsOwner(
      authenticatedUser &&
        parseInt(user?.user_id) === coachPublicProfileData?.user_id
    )
  }, [user, coachPublicProfileData])

  useEffect(() => {
    if (endpointSlug) {
      getCoachPublicProfile()
    }
  }, [endpointSlug])

  if (!coachPublicProfileData) {
    return (
      <div className="fixed inset-0 flex min-h-screen flex-col items-center justify-center gap-4">
        <h1 className="text-xl font-bold">This profile does not exist</h1>
        <Button size="lg" asChild>
          <Link to="/">Back to Home</Link>
        </Button>
      </div>
    )
  }

  if (!coachPublicProfileData?.published && !isOwner) {
    return (
      <div className="fixed inset-0 flex min-h-screen flex-col items-center justify-center gap-4">
        <h1 className="text-xl font-bold">This profile is not public</h1>
        <Button size="lg" asChild>
          <Link to="/">Back to Home</Link>
        </Button>
      </div>
    )
  }

  return (
    <>
      <CoachPublicProfileContext.Provider
        value={CoachPrivateProfileContextValues}
      >
        <div
          className={`-mt-[65px] w-full lg:flex lg:flex-col lg:items-center ${
            isOwner ? "pt-[44px]" : "pt-[44px] md:pt-[44px]"
          }`}
        >
          <div
            className={`xl:hidden ${
              consultationModalOpen ? "left-[0px]" : "-left-[300px]"
            } hover:shadow-custom box-shadow-1 fixed top-[65px] z-[1001] flex min-h-[601px] max-w-[300px] cursor-pointer items-center justify-center rounded-xl shadow-sm transition-all duration-500 ease-in-out`}
            style={{ backgroundColor: textColor }}
          >
            <ProfileSchedulerPopup
              consultation={services.find(
                (service: any) =>
                  service.service_details.type === "consultation"
              )}
            />
            <button
              onClick={() => setConsultationModalOpen(!consultationModalOpen)}
              className={`shadow-outline } fixed top-[520px] z-[1000] -mr-[348px] flex h-[50px] w-[155px] -rotate-90 transform cursor-pointer items-center justify-center rounded-bl-lg rounded-br-lg
          xl:hidden`}
              style={{ backgroundColor: textColor }}
            >
              {consultationModalOpen ? (
                <h2 className="text-sm font-bold">
                  <span>Close</span>
                </h2>
              ) : (
                <h2 className="text-sm font-bold">
                  <span>Book</span>
                  <span className="ml-2">a</span>
                  <span className="ml-2">Consult</span>
                </h2>
              )}
            </button>
          </div>
          {isOwner ? (
            <div className="flex w-full items-center justify-center">
              <div className="z-10 -mb-16 mt-24 flex w-[90%] max-w-[1250px] items-center justify-start gap-4 font-bold md:min-w-[800px] lg:w-[80%]">
                <span>You're viewing a preview</span>
                <Link to="/coach/profile">
                  <Button variant="utility">Back to edit</Button>
                </Link>
              </div>
            </div>
          ) : (
            <></>
          )}
          <div className="container flex flex-col gap-10 px-4">
            {/* DESKTOP HEADER */}
            <div className="mt-[90px] block">
              <PublicProfileBannerImage />
            </div>
            <div className="z-10">
              <PublicProfileInformation />
            </div>
            <PublicTilesCarousel services={services} />
          </div>

          <div
            className={`mx-auto mt-[20px] md:mt-[40px] ${
              selectedTile ? "min-h-[300px]" : ""
            } max-w-[90%]`}
          >
            <Outlet />
          </div>
        </div>
      </CoachPublicProfileContext.Provider>
    </>
  )
}

export default CoachPublicProfile
