import React from "react";

import {
  SvgAsterisk,
  SvgCheckbox,
  SvgAmericanExpress,
  SvgDinersClub,
  SvgDiscover,
  SvgJcb,
  SvgMastercard,
  SvgVisa,
  SvgCheckboxOff,
} from "../../icons/";
import { Badge } from "../../ui/badge";

interface Props {
  card: any;
  setSelectedPaymentMethod: any;
  selectedPaymentMethod: any;
  setListExpanded: any;
}

const CardListItem: React.FC<Props> = ({
  card,
  setSelectedPaymentMethod,
  selectedPaymentMethod,
  setListExpanded,
}) => {
  const handleCardClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setSelectedPaymentMethod(card);
    setListExpanded(false);
  };

  console.log(selectedPaymentMethod);

  const getCardLogo = () => {
    switch (selectedPaymentMethod?.card_brand) {
      case "visa":
        return <SvgVisa className="mr-[8px]" />;
      case "mastercard":
        return <SvgMastercard className="mr-[8px]" />;
      case "discover":
        return <SvgDiscover className="mr-[8px]" />;
      case "amex":
        return <SvgAmericanExpress className="mr-[8px]" />;
      case "jcb":
        return <SvgJcb className="mr-[8px]" />;
      case "diners_club":
        return <SvgDinersClub className="mr-[8px]" />;
      default:
        break;
    }
  };
  return (
    <div
      className="flex w-full items-center justify-between"
      onClick={handleCardClick}
    >
      <div className="flex w-[250px] items-center justify-between">
        <div className="flex items-center">
          {getCardLogo()}
          <div className="mr-[16px] flex items-center gap-[4px] xl:gap-[8px]">
            <SvgAsterisk className="h-3 w-3" />
            <SvgAsterisk className="h-3 w-3" />
            <SvgAsterisk className="h-3 w-3" />
            <SvgAsterisk className="h-3 w-3" />
          </div>
          <span className="text-base font-bold">{card?.card_last4}</span>
        </div>
      </div>
      {Boolean(card?.is_default) && (
        <Badge variant="success" className="mr-2 hidden xl:flex">
          Default
        </Badge>
      )}
      <button
        className={`border-1 border-grayCloud flex h-[24px] min-w-[24px]
                items-center justify-center rounded-[8px] border bg-transparent
                ${
                  card?.stripe_payment_method_id ===
                  selectedPaymentMethod?.stripe_payment_method_id
                    ? "bg-cyanBlue"
                    : ""
                }`}
      >
        {card?.stripe_payment_method_id ===
        selectedPaymentMethod?.stripe_payment_method_id ? (
          <SvgCheckbox />
        ) : (
          <SvgCheckboxOff />
        )}
      </button>
    </div>
  );
};

export default CardListItem;
