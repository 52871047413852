import * as React from "react";
import { CoachPublicProfileContext } from "../../pages/manage-business/profile/context/coachPublicProfileContext";
import { CoachPrivateProfileContext } from "../../pages/manage-business/profile/context/coachPrivateProfileContext";
import CustomDayPicker from "../date-time/CustomDayPicker";
import moment from "moment";
import { add } from "date-fns";
import { CalendarRequests } from "../../api/app.service";
import { AvailabilityRulesData } from "../../types/availabilityTypes";
import { CommonFunctionCtx } from "../../context/commonFunctionContext";
import { GetAvailability } from "../../models/app.interface";
import TimeOptions from "../scheduling/new-meeting/step-views/TimeOptions";
import { UserCtx } from "../../context/userContext";
import { useNavigate } from "react-router-dom";
import { ContactRequests } from "../../api/app.service";
import UserEmailCheckModal from "./UserEmailCheckModal";
import { useAuthenticatedUser } from "../../hooks/useAuthenticatedUser";
import Loader from "../ui/loader";
import useLoading from "../../hooks/useLoading";
import { getLightTextColor } from "../../utils/getLightTextColor";
import { isCoachInCommissionTracking } from "../../utils/commissionTracking";
import { isLocalStorageAvailable } from "../../utils/isLocalStorageAvailable";

interface Props {
  consultation?: any;
  disabled?: boolean;
}

const PrivateProfileScheduler: React.FC<Props> = ({
  consultation,
  disabled = false,
}) => {
  const { user, setUser } = React.useContext(UserCtx);
  const navigate = useNavigate();
  const [userEmailCheck, setUserEmailCheck] = React.useState<boolean>(false);
  // const [schedulerExtended, setSchedulerExtended] =
  //   React.useState<boolean>(false);
  const [activeDate, setActiveDate] = React.useState<Date>(new Date());
  const { isOwner } = React.useContext(CoachPublicProfileContext);
  const { coachPublicProfileData } = React.useContext(
    CoachPrivateProfileContext,
  );
  const [availablePeriods, setAvailablePeriods] = React.useState<Array<any>>(
    [],
  );
  const [userAvailabilities, setUserAvailabilities] = React.useState<
    Array<AvailabilityRulesData>
  >([]);
  const [loadingAvailablePeriods, setLoadingAvailablePeriods] =
    React.useState<boolean>(false);
  const { renderError } =
    React.useContext(CommonFunctionCtx);
  const { startLoading, stopLoading } = useLoading();
  const [startTime, setStartTime] = React.useState<Date | null>(null);
  const [endTime, setEndTime] = React.useState<Date | null>(null);
  // const { getTextColor } = useGetTextContrast()
  const authenticatedUser = useAuthenticatedUser(user);
  const textColor = getLightTextColor(
    coachPublicProfileData?.profile_information?.profileColor,
  );

  const dayColor = coachPublicProfileData?.profile_information?.profileColor;

  const durationDefault = 60;
  const duration = consultation?.service_details?.session?.duration
    ? parseInt(consultation?.service_details?.session?.duration) / 60
    : 60;

  // const getConsultationAvailabilityId = () => {
  //   let activeAvailabilityIdArr: Array<string> = []
  //   const activeConsultationAvailability = userAvailabilities.find(
  //     (availability) => {
  //       return (
  //         availability.id ===
  //         consultation?.service_details?.availability_rule_id
  //       )
  //     }
  //   )

  //   if (activeConsultationAvailability?.id) {
  //     activeAvailabilityIdArr.push(activeConsultationAvailability.id.toString())
  //   }

  //   return activeAvailabilityIdArr
  // }

  // const getAvailability = () => {
  //   setLoadingAvailablePeriods(true)
  //   const profileIds = [coachPublicProfileData?.coach_profile_id]
  //   // if activeDate is today, make sure that the time sent to GetAvailability is not before now, otherwise the request will fail
  //   let now = new Date()
  //   let start_date: any = activeDate
  //   if (activeDate < now) {
  //     start_date = moment.utc(now).toISOString()
  //   } else {
  //     start_date = moment.utc(activeDate).toISOString()
  //   }

  //   const m = moment.tz(
  //     activeDate,
  //     Intl.DateTimeFormat().resolvedOptions().timeZone
  //   )
  //   const end = m.clone().endOf("day").utc().toISOString()

  // const getAvailabilityRequest: GetAvailability = {
  //   profile_ids: profileIds,
  //   creator_profile_id: parseInt(coachPublicProfileData?.coach_profile_id),
  //   availability_rule_ids: getConsultationAvailabilityId(),
  //   meeting_duration: duration || durationDefault,
  //   start_date: start_date,
  //   end_date: end,
  //   coach_user_id: parseInt(coachPublicProfileData?.user_id),
  //   show_all_times: true,
  // }

  //   CalendarRequests.getAvailability(getAvailabilityRequest)
  //     .then((data) => {
  //       setAvailablePeriods(data.available_periods)
  //       setLoadingAvailablePeriods(false)
  //     })
  //     .catch((ex) => {
  //       console.log(ex)
  //       renderError(ex.response.data.message)
  //       setLoadingAvailablePeriods(false)
  //     })
  // }

  // const getAvailabilityRules = async () => {
  //   console.log("coach stuff" ,coachPublicProfileData?.user_id)
  //   await CalendarRequests.getAvailabilityRules({
  //     user_id: parseInt(coachPublicProfileData?.user_id),
  //   })
  //     .then((data) => {
  //       setUserAvailabilities(data.availability_rules)
  //     })
  //     .catch((ex) => {
  //       console.log(ex)
  //       renderError(ex.response.data.message)
  //     })
  // }

  const handleClientSwitch = async () => {
    startLoading();
    // const activeProfile: "coach" | "member" | null = "member";
    // const activeProfileId = user.memberProfile.profile_id;
    const userObject = {
      ...user,
    //   activeProfile: activeProfile,
    //   activeProfileId: activeProfileId,
    };
    if (isLocalStorageAvailable()) {
      localStorage.setItem("user", JSON.stringify(userObject));
    }
    setUser(userObject);
  };

  const connectAndRedirect = () => {
    ContactRequests.createContactConnection({
      sender_profile_id: coachPublicProfileData?.coach_profile_id,
      receiver_profile_id: user.activeProfileId,
      user_environment: process.env.REACT_APP_USER_ENVIRONMENT || "",
      status: isCoachInCommissionTracking(coachPublicProfileData?.endpoint_slug)
        ? "zoee lead"
        : "connected",
    })
      .then(() => {
        navigate(
          `/member/contacts/${coachPublicProfileData?.coach_profile_id}/services/${consultation?.service_id}`,
          { state: {} },
        );
      })
      .catch((ex) => {
        console.error(ex);
        renderError(ex.response.data.message);
        stopLoading();
      });
  };

  const goToEnroll = (actionType: string, emailObject: any) => {
    const forceMemberLogin = actionType === "login";
    const forceMemberSignup = actionType === "signup";
    localStorage.setItem(
      "meetingToSchedule",
      JSON.stringify({ startTime: startTime, endTime: endTime }),
    );
    if (isOwner) {
      return renderError(
        "Can't take action on profile page when logged into this account",
      );
    }
    if (authenticatedUser) {
      handleClientSwitch().then(() => {
        connectAndRedirect();
      });
    } else {
      navigate(`/direct-routes`, {
        state: {
          storageAvailable: true,
          pathname: `/member/contacts/${coachPublicProfileData?.coach_profile_id}/services/${consultation?.service_id}`,
          searchParams: {},
          actions: {
            createConnection: {
              coachProfileId: coachPublicProfileData?.coach_profile_id,
            },
            scheduleMeeting: {
              startTime: startTime,
              endTime: endTime,
            },
            forceMemberSignup: forceMemberSignup,
            forceMemberLogin: forceMemberLogin,
            emailObject: emailObject,
            memberShortForm: true,
            coachName: `${coachPublicProfileData?.first_name} ${coachPublicProfileData?.last_name}`,
          },
        },
      });
    }
  };

  const checkEmailOrRedirect = () => {
    if (authenticatedUser) {
      goToEnroll("", {});
    } else {
      setUserEmailCheck(true);
    }
  };

  React.useEffect(() => {
    if (startTime) {
      const end = add(startTime, {
        minutes: duration || durationDefault,
      });
      setEndTime(end);
    }
  }, [startTime]);

  // React.useEffect(() => {
  //   if (userAvailabilities) {
  //     getAvailability()
  //   }
  // }, [activeDate, userAvailabilities, duration])

  // React.useEffect(() => {
  //   getAvailabilityRules()
  // }, [])

  // React.useEffect(() => {
  //   console.log(startTime, endTime)
  // }, [startTime, endTime])

  return (
    <>
      {userEmailCheck && (
        <UserEmailCheckModal
          setUserEmailCheck={setUserEmailCheck}
          goToEnroll={goToEnroll}
        />
      )}
      <div
        className={`main flex h-full max-h-[601px] min-w-[299px] flex-col rounded-[20px] bg-white shadow md:max-w-[300px]
                    ${disabled ? "pointer-events-none opacity-25" : ""}`}
      >
        <div
          className="flex h-[20px] items-center justify-center rounded-t-[16px] px-[24px]
                    text-[16px] font-bold"
          style={{
            backgroundColor: `${coachPublicProfileData?.profile_information?.profileColor}`,
            color: textColor,
          }}
        ></div>
        <div className={`px-[24px] py-[16px] sm:py-[5px]`}>
          <h1 className="-mb-[5px] pt-[7px] text-[16px] font-bold">
          Schedule a Consultation
          </h1>
          <h1
            style={{
              color: `${coachPublicProfileData?.profile_information?.profileColor}`,
            }}
            className="mb-2 text-[22px] font-bold"
          >
            {consultation?.service_details?.title}
          </h1>
          <div className="mb-[12px] flex w-full items-center justify-between text-[16px] font-bold">
            <CustomDayPicker
              setActiveDate={setActiveDate}
              disablePastDates={true}
              dayColor={dayColor}
            />
          </div>

          {/* TIME */}
          {/* <h4 className="text-base text-graySlate mb-[8px]">Time</h4> */}
          {/* <div className="w-full flex flex-col gap-[8px] overflow-y-auto">
            {loadingAvailablePeriods ? (
              <div className="text-center pt-[24px] px-[8px] font-bold">
                <Loader />
                <h3 className="font-bold text-[16px] text-center">
                  Loading available meeting times.
                </h3>
              </div>
            ) : (
              <>
                {availablePeriods?.length > 0 ? (
                  <>
                    {availablePeriods?.map((availablePeriod, idx) => {
                      return (
                        <Fragment key={idx}>
                          <TimeOptions
                            key={idx}
                            availabilityStart={moment(
                              availablePeriod.start
                            ).toDate()}
                            availabilityEnd={moment(
                              availablePeriod.end
                            ).toDate()}
                            duration={duration || durationDefault}
                            setStartTime={setStartTime}
                            startTime={startTime}
                          />
                          <div className="last:hidden bg-hover text-graySlate flex items-center justify-center p-[12px] rounded-[10px] text-base font-bold">
                            Unavailable
                          </div>
                        </Fragment>
                      )
                    })}
                  </>
                ) : (
                  <div className="text-center text-sm pt-[5px] px-[4px] font-bold mb-2">
                    No available meeting times for you and your meeting
                    participant(s) on this day. Please select another day.
                  </div>
                )}
              </>
            )}
          </div> */}
        </div>
      </div>
    </>
  );
};

export default PrivateProfileScheduler;
