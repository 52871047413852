import React, { FC } from "react"
import { CommonFunctionCtx } from "../../context/commonFunctionContext"
import { SubscriptionContext } from "../../pages/subscription/context/subscriptionContext"
import useScrollToTop from "../../hooks/useScrollToTop"
import BackToButton from "../BackToButton"
import Loader from "../ui/loader"
import SubscriptionPlanCard from "./SubscriptionPlanCard"
import { SvgChatEllipses } from "../icons/"
import { useIntercom } from "react-use-intercom"
import TrialSubscriptionCard from "./TrialSubscriptionCard"

type Props = {}

const SubscriptionPlans: FC<Props> = () => {
  const { show } = useIntercom()
  const { setPopupNotification } = React.useContext(CommonFunctionCtx)
  const {
    setHideSecondaryNav,
    plans,
    setSelectedSubscription,
    showSubscriptionComponents,
    setShowSubscriptionCheckout,
    setShowSubscription,
  } = React.useContext(SubscriptionContext)

  const [selectedPlan, setSelectedPlan] = React.useState<string>("annual")

  React.useEffect(() => {
    setHideSecondaryNav(true)
  }, [])

  const handleChoosePlanClick = (chosenSubscription: any): void => {
    setSelectedSubscription(chosenSubscription)
    showSubscriptionComponents(setShowSubscriptionCheckout)
  }

  const goBack = () => {
    setHideSecondaryNav(false)
    showSubscriptionComponents(setShowSubscription)
  }

  useScrollToTop()

  return (
    <div className="-mt-0 md:-mt-[43px]">
      <BackToButton
        onClick={goBack}
        current="Plans & Pricing"
        prev="My Subscription"
      />
      <div className="flex flex-col items-center">
        <div className="w-full flex flex-col items-right">
          <h1 className="font-bold flex text-[22px] md:text-[36px] text-center mt-[40px] mb-[8px]">
            Plans &amp; Pricing
          </h1>
          <div className="mb-[24px] flex items-center gap-[8px] max-w-[302px] md:max-w-none">
            <p className="text-graySlate text-center ">
              * Payment and processing fees are not included.
              <span
                className="font-bold text-primaryBlue md:hidden cursor-pointer"
                onClick={() =>
                  setPopupNotification({
                    show: true,
                    title: "Coming Soon",
                    message:
                      "This feature will be available on a future version of Zoee!",
                  })
                }
              >
                {" "}
                Learn More
              </span>
            </p>
            <button
              className="font-bold text-blurple md:block hidden"
              onClick={() =>
                setPopupNotification({
                  show: true,
                  title: "Coming Soon",
                  message:
                    "This feature will be available on a future version of Zoee!",
                })
              }
            >
              Learn more
            </button>
          </div>
        </div>
        <div className="relative flex justify-center items-center w-full max-w-xs rounded-[20px] border-2 border-black h-[50px] p-1 mb-[24px]">
          <button
            onClick={() => setSelectedPlan("monthly")}
            className={`w-[40%] h-full rounded-[18px] ${
              selectedPlan === "monthly"
                ? "bg-[black] text-[white]"
                : "bg-white text-blue-500"
            }`}
          >
            Monthly
          </button>
          <button
            onClick={() => setSelectedPlan("annual")}
            className={`w-[60%] h-full rounded-[18px] ${
              selectedPlan === "annual"
                ? "bg-[black] text-[white]"
                : "bg-white text-blue-500"
            }`}
          >
            Annual (Save 16%)
          </button>
        </div>
        {plans ? (
          <>
            <div className="w-full flex flex-col md:flex-row items-center md:justify-center gap-[32px] mx-auto mb-[64px] h-[700px]">
              <TrialSubscriptionCard />
              {selectedPlan === "monthly" && (
                <SubscriptionPlanCard
                  plan={plans?.monthly}
                  handleChoosePlanClick={handleChoosePlanClick}
                  title={`Monthly Plan`}
                />
              )}
              {selectedPlan === "annual" && (
                <SubscriptionPlanCard
                  plan={plans?.annual}
                  handleChoosePlanClick={handleChoosePlanClick}
                  title={`Annual Plan`}
                />
              )}
            </div>
            <div className="text-[16px] flex items-center gap-[8px] mb-[24px] md:mb-0">
              <SvgChatEllipses />
              <p>Need help deciding?</p>
              <button
                onClick={() => {
                  show()
                }}
                className="text-blurple font-bold"
              >
                Chat with our support
              </button>
            </div>
          </>
        ) : (
          <Loader />
        )}
      </div>
    </div>
  )
}

export default SubscriptionPlans
