import { capitalize } from "../../../utils/capitalize";
import { SvgCreditCard, SvgEducation } from "../../icons/";

interface Props {
  credentials: any;
  educationList: any;
}

export default function ProfileCredentials({
  credentials,
  educationList,
}: Props) {
  return (
    <div className="flex items-start justify-between mb-16">
      <div
        className={`flex flex-col items-start gap-[40px] ${credentials === null ? "hidden" : ""}`}
      >
        <h1 className="font-bold text-xl">
          {`
            ${credentials?.length > 0 ? "Credentials" : ""}
            ${credentials?.length > 0 && educationList.length > 0 ? " and" : ""}
            ${educationList?.length > 0 ? " Education" : ""}
          `}
        </h1>
        {/* CREDENTIALS SECTION */}
        <div
          className={`flex flex-col items-start ${credentials?.length > 0 ? "" : "hidden"} `}
        >
          <h5 className="font-bold flex items-center gap-[8px] mb-[12px]">
            <SvgCreditCard />
            Credentials
          </h5>
          {/* LOOP OVER CREDENTIALS ARRAY FROM CREDENTIALS JSON */}
          {credentials?.length > 0 ? (
            <>
              <div className="flex flex-col gap-[12px]">
                {credentials?.map((credential: any, index: number) => (
                  <div
                    key={index}
                    className="flex flex-col items-start"
                  >
                    {/* NAME */}
                    <div className="font-bold text-[16px]">
                      {credential?.name}
                    </div>
                    {/* ISSUING ORGANIZATION */}
                    <div className="font-semibold text-[16px] text-graySlate">
                      {credential?.issuingOrganization}
                    </div>
                    <div className="flex flex-col gap-[4px] text-[16px] text-graySlate">
                      {/* ISSUE DATE */}
                      {credential?.issueMonth && credential?.issueYear && (
                        <>
                          <span>
                            Issued {capitalize(credential?.issueMonth)}
                            {credential?.issueYear}
                          </span>
                          {/* EXPIRATION DATE */}
                          {credential?.expireMonth &&
                            credential?.expireYear && (
                              <span>
                                Expires
                                {capitalize(credential?.expireMonth)}
                                {credential?.expireYear}
                              </span>
                            )}
                        </>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </>
          ) : (
            <div className="text-graySlate">No credentials listed</div>
          )}
        </div>

        {/* EDUCATION SECTION */}
        <div
          className={`flex flex-col items-start
          ${educationList?.length > 0 ? "" : "hidden"}
          `}
        >
          <h5 className="font-bold flex items-center gap-[8px] mb-[12px]">
            <SvgEducation className="w-[20px" />
            Education
          </h5>
          {/* LOOP OVER EDUCATION ARRAY FROM CREDENTIALS JSON */}
          {educationList?.length > 0 ? (
            <>
              <div className="flex flex-col gap-[12px]">
                {educationList?.map((education: any, index: number) => (
                  <div
                    key={index}
                    className="flex flex-col items-start"
                  >
                    {/* SCHOOL */}
                    <div className="font-bold text-[16px] text-grayCharcoal">
                      {education?.school}
                    </div>
                    {/* DEGREE / FIELD OF STUDY */}
                    <div className="font-semibold text-[16px] text-graySlate">
                      {education?.degree} - {education?.fieldOfStudy}
                    </div>
                    <div className="flex items-center gap-[4px] text-[16px] text-graySlate">
                      {/* YEAR STARTED */}
                      {education?.startYear && (
                        <>
                          <span>{education.startYear}</span>
                          <span>-</span>
                          {/* YEAR FINISHED */}
                          {education?.endYear && (
                            <span>{education.endYear}</span>
                          )}
                        </>
                      )}
                    </div>
                    {/* Activities and societies */}
                    {education?.activitiesAndSocieties && (
                      <div className="font-semibold text-[16px] text-graySlate">
                        Activities and societies:
                        {education?.activitiesAndSocieties}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </>
          ) : (
            <div className="text-graySlate">No education listed</div>
          )}
        </div>
      </div>
    </div>
  );
};
