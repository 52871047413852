import React from "react"
import moment from "moment"
import { UserCtx } from "../../context/userContext"

interface Props {
  contactStatus: string
  origin: string
  contactDate: any
}

const ContactStatusTag: React.FC<Props> = ({
  contactStatus,
  origin,
  contactDate,
}) => {
  const { user } = React.useContext(UserCtx)

  const getRequestedTag = () => {
    const date = moment(contactDate).format("ll")
    if (origin === "full") {
      return `Requested on ${date}`
    } else {
      return "Requested"
    }
  }

  const getStatusInfo = () => {
    const userType = user.activeProfile
    switch (userType) {
      case "coach":
        switch (contactStatus) {
          case "active":
            return { color: "bg-grassGreen", text: "Active Member" }
          case "prospect":
            return { color: "bg-fallOrange", text: "Prospect" }
          case "lead":
            return { color: "bg-blurple", text: "Zoee Lead" }
          case "zoee lead":
              return { color: "bg-oceanBlue", text: "Zoee Lead" }
          case "inactive":
            return { color: "bg-gray", text: "Inactive Member" }
          case "invited":
            return { color: "bg-luminousAmber", text: "Invited" }
          case "requested":
            return { color: "bg-vividOrange", text: getRequestedTag() }
          default:
            return { color: "bg-green", text: "" }
        }
      case "member":
        return { color: "bg-green", text: "Active Coach" }
      default:
        return { color: "bg-green", text: "" }
    }
  }

  const { color, text } = getStatusInfo()

  return (
    <div
      className="absolute top-[8px] left-[8px] px-[8px] py-[6px] bg-white
            rounded-[8px] flex items-center gap-[6px] text-base font-bold"
    >
      <div className={`w-[8px] h-[8px] rounded-full ${color}`}></div>
      {text}
    </div>
  )
}

export default ContactStatusTag
