import React from "react";
import { UserCtx } from "../../../context/userContext";
import moment from "moment";
import { SessionContext } from "../../../pages/sessions/context/sessionContext";
import { getUserColor } from "../../../utils/getUserColor";
import useName from "../../../hooks/useName";
import { Button } from "../../ui/button";
import { Badge } from "../../ui/badge";
import { SvgVideoSolid } from "../../icons";

interface Props {
    session: any;
    setDetailedPastSession: any;
}

const PastSessionPreview: React.FC<Props> = ({ session, setDetailedPastSession }) => {
    const { user } = React.useContext(UserCtx);
    const { initial } = useName();
    const { mobileView } = React.useContext(SessionContext);
    const {
        participant_list,
        meeting_name,
        session_end_time,
        session_start_time,
        service_usage_id,
    } = session;

    const getParticipantColor = (participant: any) => {
        let color: string;
        if (participant?.user_color) {
            color = participant.user_color;
        } else {
            color = "orange";
        }
        return `bg-${color}`;
    };

    const getSessionDuration = () => {
        var end = moment(session_end_time);
        var start = moment(session_start_time);
        return end.diff(start, "minutes");
    };

    const getTimezoneAdjustedTime = (time: any) => {
        return moment.utc(time).tz(user.timezone.value).format("h:mm a");
    };

    const getTimezoneAdjustedDateTime = (time: any) => {
        return moment.utc(time).tz(user.timezone.value).format("MMM DD, YYYY h:mm a");
    };

    return (
        <div
            className="w-[358px] mx-auto md:mx-0 md:w-full py-[24px] 
            flex flex-col border-grayMist"
        >
            <div className="flex w-full items-center justify-between">
                <div className="flex items-center gap-[16px]">
                    <div className={`w-[48px] relative h-[48px] flex flex-col justify-center`}>
                        {participant_list.length <= 1 ? (
                            <>
                                {user?.hasAvatar ? (
                                    <img
                                        src={`https://profile-avatars-${process.env.REACT_APP_USER_ENVIRONMENT}.s3.amazonaws.com/${user?.user_id}`}
                                        className="w-full h-full object-cover rounded-full flex items-center justify-center"
                                        alt="avatar"
                                    />
                                ) : (
                                    <div
                                        className={`flex items-center justify-center w-full
                                    h-full font-normal text-[18px] rounded-full cursor-pointer ${getUserColor(user.userColor)}`}
                                    >
                                        <span>{initial}</span>
                                    </div>
                                )}
                                <div
                                    className="absolute right-[-2px] bottom-[-2px] w-[24px] flex items-center
                                    justify-center h-[24px] border-[2px] border-white rounded-full bg-paleCyanBlue"
                                >
                                    <SvgVideoSolid />
                                </div>
                            </>
                        ) : (
                            <div className="flex flex-wrap gap-0">
                                {participant_list
                                    .slice(0, 3)
                                    .map((participant: any, idx: number) => (
                                        <div className="w-[24px] h-[24px]" key={idx}>
                                            {participant.has_avatar ? (
                                                <img
                                                    src={`https://profile-avatars-${process.env.REACT_APP_USER_ENVIRONMENT}.s3.amazonaws.com/${participant?.user_id}`}
                                                    alt="avatar"
                                                    className="w-full h-full object-cover rounded-full"
                                                />
                                            ) : (
                                                <div
                                                    className={`w-full h-full rounded-full justify-center text-base
                                                items-center flex font-bold ${getParticipantColor(
                                                    participant
                                                )}`}
                                                >
                                                    {initial}
                                                </div>
                                            )}
                                        </div>
                                    ))}
                            </div>
                        )}
                        {participant_list.length > 3 && (
                            <div
                                className="absolute bottom-0 right-[-2px] w-[28px] h-[24px] bg-luminousAmber
                                text-base rounded-[100px] border-white border-[2px] flex items-center justify-center"
                            >
                                {participant_list.length}
                            </div>
                        )}
                    </div>
                    <div className="flex flex-col gap-[4px] items-start">
                        <div className="flex items-center gap-[8px]">
                            {participant_list.length === 2 ? (
                                <h5 className="font-bold text-base">
                                    {participant_list[1].first_name}
                                </h5>
                            ) : (
                                <h5 className="font-bold text-base">{meeting_name}</h5>
                            )}
                        </div>
                        {!mobileView ? (
                            <h5 className="text-base">
                                <span className="font-bold">{meeting_name}</span>&nbsp; ended at
                                &nbsp;
                                <span className="font-bold">
                                    {getTimezoneAdjustedTime(session_end_time)}
                                </span>
                                &nbsp; - lasted &nbsp;
                                <span className="font-bold">{`${getSessionDuration()} ${
                                    getSessionDuration() === 1 ? "min" : "mins"
                                }`}</span>
                            </h5>
                        ) : (
                            <div className="flex items-center gap-[8px]">
                                <span className="text-graySlate text-base">
                                    {getTimezoneAdjustedDateTime(session_start_time)}
                                </span>
                            </div>
                        )}
                        {!!service_usage_id && (
                            <Badge variant="alert">
                                Service Attached
                            </Badge>
                        )}
                    </div>
                </div>
                <Button
                    className="h-[32px]"
                    onClick={() => setDetailedPastSession({ show: true, session: session })}
                >
                    {!mobileView ? "Session Details" : "Details"}
                </Button>
            </div>
            {!mobileView && (
                <div className="flex items-center gap-[8px] ml-[64px] mt-[8px]">
                    <span className="text-graySlate text-base">
                        {getTimezoneAdjustedDateTime(session_start_time)}
                    </span>
                </div>
            )}
        </div>
    );
};

export default PastSessionPreview;
