import React from "react"
import EndOfLifeCoaching from "../../../assets/images/EndOfLifeCoaching.png"
import ICFLogo from "../../../assets/images/ICF.png"
import RadiantImage from "../../../assets/images/Radiant.png"
import { Button } from "../../ui/button"
import { useIntercom } from "react-use-intercom"

const OrganizationPlan: React.FC = () => {
  const { show } = useIntercom()

  return (
    <div className="flex justify-center w-[340px] sm:w-[850px] sm:h-full">
      <div
        className="relative flex flex-col sm:flex-row items-start gap-10 bg-white rounded-[32px] shadow-lg w-full max-w-[1060px]"
        style={{
          boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.1)",
          transform: "translateY(-5px)",
        }}
      >
        <div className="absolute top-0 left-0 w-full h-5 bg-[#4750f5] rounded-tl-xl rounded-tr-xl"></div>

        <div className="p-8 flex flex-col sm:w-2/3 text-left sm:mt-4">
          <h2 className="text-black text-2xl font-semibold font-['Beausite Classic']">
            Organization Pro
          </h2>
          <p className="text-[#7d878f] text-lg font-normal font-['Beausite Classic'] sm:mb-4">
            For schools and organizations seeking custom solutions and
            personalized support
          </p>
          <div className="sm:flex items-center gap-4 mt-2">
            <span className="font-bold">Trusted by partners from:</span>
            <span className="flex items-center gap-2 h-[24px]">
              <img src={RadiantImage} alt="Radiant" className="h-full" />
              <img src={ICFLogo} alt="ICF" className="h-full" />
              <img
                src={EndOfLifeCoaching}
                alt="End of Life Coaching"
                className="h-full rounded-md"
              />
            </span>
          </div>
        </div>
        <div className="p-8 flex flex-1 justify-end items-start -mt-20 sm:mt-4">
          <Button
            onClick={show}
            className="text-white text-[13.12px] font-bold font-['Beausite Classic'] h-12 mr-6 w-[255px] sm:w-full"
          >
            <p className="text-[17px]">Talk to Sales</p>
          </Button>
        </div>
      </div>
    </div>
  )
}

export default OrganizationPlan
