import React from "react";
import ContactAvatar from "../cards/ContactAvatar";
import ContactStatusTagMobile from "./ContactStatusTagMobile";
import { UserCtx } from "../../context/userContext";
import { useNavigate } from "react-router-dom";
import { Button } from "../ui/button";
import { SvgChevronRight, SvgUserPlus } from "../icons";

type Props = {
    contact: any;
    setContactRequestFull: any;
    type: string;
    setShowInviteModal: any;
    showInviteButton: boolean;
};

const ContactMobileListItem: React.FC<Props> = ({ contact, setContactRequestFull, type, setShowInviteModal, showInviteButton }) => {
    const { contact_type, contact_name, contact_date, status } = contact;
    const { user } = React.useContext(UserCtx);
    const navigate = useNavigate();

    const handleSelectContact = () => {
        navigate(`/${user.activeProfile}/contacts/${contact.profile_id}`);
    };

    const showFullRequest = () => {
        setContactRequestFull({ show: true, contact: contact });
    };

    const handleInvitedContactClick = () => {
        setContactRequestFull({ show: true, contact: contact, variant: "invited" });
    };


    const getStatus = () => {
        let profileStatus = "Unknown"
        if(contact_type === "prospect"){
          return "prospect"
        }
        if (status === "connected") {
          profileStatus = "active"
        } else if (status === "lead") {
          profileStatus = "lead"
        } else if (status === "zoee lead") {
          profileStatus = "zoee lead"
        }else if (status === "prospect") {
          profileStatus = "prospect"
        } else if (contact_type === "invited") {
          profileStatus = "invited"
        } else if (contact_type === "requested") {
          profileStatus = "requested"
        }
        return profileStatus
      }

    const handleListItemClick = () => {
        if (contact_type === "connected") {
            handleSelectContact();
        } else if (contact_type === "requested") {
            showFullRequest();
        } else if (contact_type === "invited") {
            handleInvitedContactClick();
        }
    };

    return (
        <div className={type === 'invited' ? 'flex flex-col items-center' : ''}>
              {(showInviteButton && type === "invited") && (
                <Button
                  className="gap-[10px]"
                  onClick={() => setShowInviteModal(true)}
                >
                    New Contact
                  <SvgUserPlus />
                </Button>
            )}
        <div
            onClick={handleListItemClick}
            className="flex items-center justify-between py-[8px] cursor-pointer"
        >
            <div className="flex items-center">
                <ContactAvatar
                    contact={contact}
                    width={48}
                    height={48}
                    bold={false}
                    fontSize={"text-[18px]"}
                />
                <h3 className="font-bold ml-[8px] mr-[2px]">{contact?.contact_name?.length > 20
                        ? `${contact.contact_name.slice(0, 20)}…`
                        : contact.contact_name}</h3>
            </div>
            <div className="flex items-center min-w-fit">
                <ContactStatusTagMobile
                    contactStatus={getStatus()}
                    origin=""
                    contactDate={contact_date}
                />
                <SvgChevronRight fill="black" className="ml-[10px]" />
            </div>
        </div>
      </div>
    );
};

export default ContactMobileListItem;
