import React from "react";
import { CoachPrivateProfileContext } from "../../../../pages/manage-business/profile/context/coachPrivateProfileContext";
import DragDropArea from "../../../DragDropArea";
import { ProfileRequests } from "../../../../api/app.service";
import { CommonFunctionCtx } from "../../../../context/commonFunctionContext";
import BlogEditor from "./blog/BlogEditor";
import DialogueModal from "../../../DialogueModal";
import Loader from "../../../ui/loader";
import { Switch } from "../../../ui/switch";
import { Label } from "../../../ui/label";
import { Button } from "../../../ui/button";
import { SvgClose, SvgWarning } from "../../../icons/";

export default function EditBlogPost() {
  const { renderError, setPopupNotification, renderSuccess } =
    React.useContext(CommonFunctionCtx);
  const {
    editBlogPost,
    setEditBlogPost,
    getCoachPrivateProfile,
  } = React.useContext(CoachPrivateProfileContext);
  const [blogPostTitle, setBlogPostTitle] = React.useState<string>("");
  const [blogContent, setBlogContent] = React.useState<any>(null);
  const [blogImageFile, setBlogImageFile] = React.useState<any>(null);
  const [status, setStatus] = React.useState<string>("pending");
  const [blogImageProperties, setBlogImageProperties] = React.useState<any>({
    base64: "",
    type: "",
  });
  const [previewImgUrl, setPreviewImgUrl] = React.useState<string>("");
  const [formErrors, setFormErrors] = React.useState<any>({});
  const [isSubmit, setIsSubmit] = React.useState<boolean>(false);
  const [confirmDeleteBlog, setConfirmDeleteBlog] =
    React.useState<boolean>(false);
  const [published, setPublished] = React.useState<boolean>(
    editBlogPost?.post?.published
  );
  const [editingBlogPost, setEditingBlogPost] = React.useState<boolean>(false);

  // const { getTextColor } = useGetTextContrast();

  const validateForm = () => {
    const errors: any = {};
    !blogPostTitle && (errors.blogPostTitle = "Title is required");
    !blogContent[0]?.children[0]?.text &&
      (errors.blogContent = "Content is required");
    return errors;
  };

  const confirmDeletePost = (e: any) => {
    e.stopPropagation();
    setConfirmDeleteBlog(true);
  };

  const handleSubmit = (e: React.FormEvent<EventTarget>) => {
    e.preventDefault();
    setFormErrors(validateForm());
    setIsSubmit(true);
  };

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBlogPostTitle(e.target.value);
  };

  const saveBlogPost = () => {
    setEditingBlogPost(true);
    ProfileRequests.editBlogPost({
      blog_post_id: editBlogPost?.post?.id,
      title: blogPostTitle,
      content: blogContent,
      base_64_string: blogImageProperties?.base64,
      file_extension: blogImageProperties?.type,
      published: published,
    })
      .then(() => {
        getCoachPrivateProfile();
        setEditBlogPost(false);
        renderSuccess("The blog post was updated.");
      })
      .catch((ex) => {
        console.log(ex);
        renderError(ex.response.data.message);
        setEditingBlogPost(false);
      });
  };

  const deleteBlogPost = () => {
    setEditingBlogPost(true);
    ProfileRequests.deleteBlogPost({
      blog_post_id: editBlogPost?.post?.id,
    })
      .then(() => {
        getCoachPrivateProfile();
        setEditBlogPost(false);
        renderSuccess("Blog post deleted");
      })
      .catch((ex) => {
        console.log(ex);
        renderError(ex.response.data.message);
        setEditingBlogPost(false);
      });
  };

  const validateFile = (imageFile: File) => {
    if (
      (imageFile.type === "image/png" ||
        imageFile.type === "image/jpg" ||
        imageFile.type === "image/jpeg") &&
      imageFile.size <= 5000000 &&
      imageFile.size > 0
    ) {
      return true;
    } else {
      return false;
    }
  };

  const getBlogImageProperties = () => {
    if (blogImageFile) {
      if (validateFile(blogImageFile)) {
        const type = blogImageFile.type.split("/")[1];
        const reader = new FileReader();
        reader.readAsDataURL(blogImageFile);
        reader.onload = function () {
          const string = (reader.result as string).split("\n").map((data) => {
            return data.split(",");
          });
          const base64 = string[0][1];
          setBlogImageProperties({ base64: base64, type: type });
        };
        reader.onerror = function (error) {
          console.log("Error: ", error);
        };
      } else {
        setPopupNotification({
          show: true,
          title: "Bummer! Your file is too big.",
          message:
            "Give it another try with a file under 5 megabytes, and in one of the following formats: png, jpg, or jpeg.",
          callback: null,
        });
        setBlogImageFile(undefined);
        setBlogImageProperties({});
      }
    }
  };
  React.useEffect(() => {
    getBlogImageProperties();
    if (blogImageFile) {
      setPreviewImgUrl(URL.createObjectURL(blogImageFile));
    }
  }, [blogImageFile]);

  React.useEffect(() => {
    if (isSubmit && Object.keys(formErrors).length === 0) {
      if (
        (editBlogPost?.post?.title !== blogPostTitle ||
          editBlogPost?.post?.content !== blogContent) &&
        status !== "pending"
      ) {
        setIsSubmit(false);
      } else {
        saveBlogPost();
      }
    } else {
      setIsSubmit(false);
    }
  }, [formErrors]);

  React.useEffect(() => {
    setBlogPostTitle(editBlogPost?.post?.title);
    setBlogContent(editBlogPost?.post?.content);
    setStatus(editBlogPost?.post?.status);
  }, []);

  const buttonsSection = () => {
    return (
      <>
        <Button
          variant="ghost"
          onClick={() => setEditBlogPost(false)}
        >
          Cancel
        </Button>
        {status === "approved" && (
          <div className="flex gap-2 items-center">
            <Label htmlFor="blog-status">Draft</Label>
            <Switch
              onCheckedChange={() => setPublished(!published)}
              checked={published}
              id="blog-status"
            />
            <Label htmlFor="blog-status">Published</Label>
          </div>
        )}

        <Button
          type="button"
          variant="destructive"
          onClick={confirmDeletePost}
        >
          Delete Post
        </Button>
        <Button
          type="submit"
          onClick={saveBlogPost}
        >
          Update Post
        </Button>
      </>
    );
  };

  return (
    <>
      {confirmDeleteBlog && (
        <DialogueModal
          setShowDialogueModal={setConfirmDeleteBlog}
          header={`If you delete this post, it cannot be restored.`}
          helpText={`Are you sure you want to permanently delete your blog post?`}
          confirmCallbackFn={deleteBlogPost}
          confirmButtonText="Delete Post"
          isWarningVariant={true}
        />
      )}
      <div
        className="w-full h-screen py-[80px] bg-white fixed left-0 top-0 px-[16px] md:px-[100px] flex flex-col overflow-y-auto z-[2001]"
      >
        {/* CLOSE BUTTON */}
        <button
          className="absolute top-[24px] right-[32px] flex items-center justify-center w-[32px] h-[32px] rounded-full bg-grayFlash"
          onClick={() => setEditBlogPost({ show: false, post: {} })}
        >
          <SvgClose />
        </button>
        <form
          onSubmit={handleSubmit}
          className="flex w-full flex-col"
        >
          <h2 className="font-bold text-[24px] mb-[32px]">Edit Blog Post</h2>
          <div className="flex w-full justify-between flex-col md:flex-row pb-[20px] md:pb-0 h-full md:h-[500px]">
            {/* LEFT COLUMN */}
            <div className="flex flex-col gap-8 w-full md:w-[48%] h-full mb-[20px] md:mb-0">
              {/* Title */}
              <div className="flex flex-col gap-[8px]">
                <h5 className="mb-[8px] font-bold text-[16px]">Blog title</h5>
                <div
                  className={`default-input w-full ${
                    formErrors?.blogPostTitle && "input-error"
                  }`}
                >
                  <input
                    type="text"
                    placeholder="Blog Post Title"
                    className="w-full"
                    onChange={handleTitleChange}
                    name="title"
                    value={blogPostTitle}
                  />
                  <div className="overlay">Blog Post Title</div>
                </div>
                {formErrors?.blogPostTitle && (
                  <div className="field-error">
                    <SvgWarning />
                    {formErrors.blogPostTitle}
                  </div>
                )}
              </div>
              <div className="flex flex-col gap-[8px]">
                <h5 className="font-bold text-[16px]">Blog image</h5>
                <div className="flex flex-row">
                  <DragDropArea
                    styling={`w-full rounded-[16px] flex-row h-[100px]`}
                    mediaFile={blogImageFile}
                    setMediaFile={setBlogImageFile}
                    mediaType="image"
                  />
                </div>
              </div>
              <div className="flex justify-center items-center">
                <div className="w-[200px] rounded-[16px] h-[200px] bg-secondary">
                  {previewImgUrl ? (
                    <img
                      src={previewImgUrl}
                      alt="preview"
                      className="w-full h-full rounded-[16px] object-cover"
                    />
                  ) : (
                    <>
                      {!!editBlogPost?.post?.has_image && (
                        <img
                          src={`https://blog-images-${
                            process.env.REACT_APP_USER_ENVIRONMENT
                          }.s3.amazonaws.com/${
                            editBlogPost?.post?.id
                          }?${Date.now()}`}
                          alt="preview"
                          className="w-full h-full rounded-[16px] object-cover"
                        />
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>

            {/* RIGHT COLUMN */}
            <div className="w-full md:w-[48%] h-[500px] md:h-full -mt-16 md:-mt-0">
              <div className={`w-full h-full flex flex-col gap-[8px]`}>
                <h5 className="font-bold text-[16px] mt-[70px] sm:mt-0">
                  Blog content
                </h5>
                {blogContent && (
                  <BlogEditor
                    blogContent={blogContent}
                    setBlogContent={setBlogContent}
                    customStyles={`${
                      formErrors?.blogContent
                        ? "border border-[1px] border-vividRed"
                        : ""
                    }`}
                  />
                )}
                {formErrors?.blogContent && (
                  <div className="field-error">
                    <SvgWarning />
                    {formErrors.blogContent}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="flex flex-wrap items-center justify-center gap-4 ml-auto mt-[32px]">
            {editingBlogPost ? <Loader /> : buttonsSection()}
          </div>
        </form>
      </div>
    </>
  );
};
