import * as React from "react";
import { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import useEmblaCarousel from "embla-carousel-react";
import { PublicProfileRequests } from "../../api/public.service";
import { Button } from "../../components/ui/button";
import { CoachCard, CoachTagWithImage } from "../../models/public.interface";
import heroBg from "../../assets/images/marketplace/splash-background.jpeg";
import Footer from "../../components/marketing/footer/Footer";
import MarketplaceProfileCard from "../../components/cards/MarketplaceProfileCard";
import useScrollToTop from "../../hooks/useScrollToTop";
import { SearchMarketplace } from "../../components/navigation/SearchMarketplace";
import { SvgChevronLeft, SvgChevronRight } from "../../components/icons/";

export function FindACoachPage() {
  useScrollToTop()
  // State
  const [tags, setTags] = useState<CoachTagWithImage[]>([]);
  const [coaches, setCoaches] = useState<CoachCard[]>([]);
  const [hasNextPage, setHasNextPage] = useState(false);

  // Hooks
  const [emblaRef, emblaApi] = useEmblaCarousel({
    align: "start",
    slidesToScroll: "auto",
    loop: true,
  });
  const [searchParams, setSearchParams] = useSearchParams();

  // Functions
  const scrollPrev = React.useCallback(() => {
    console.log(emblaApi);
    if (emblaApi) emblaApi.scrollPrev();
  }, [emblaApi]);

  const scrollNext = React.useCallback(() => {
    console.log(emblaApi);
    if (emblaApi) emblaApi.scrollNext();
  }, [emblaApi]);

  async function getCoachTags() {
    try {
      let tags = await PublicProfileRequests.getCoachTags();
      tags = tags.filter((tag) => tag.coach_count > 0);

      const tagsWithImagePromises = tags.map(async (tag) => {
        const image = await import(
          `../../assets/images/marketplace/categories/${tag.id}.jpg`
        );
        return {
          ...tag,
          image: image.default,
        };
      });

      const tagsWithImage = await Promise.all(tagsWithImagePromises);

      setTags(tagsWithImage);
    } catch (error) {
      console.error(error);
    }
  }

  async function getCoaches() {
    try {
      const coaches = await PublicProfileRequests.getCoaches(
        searchParams.get("q") || "",
        searchParams.getAll("tags").map((tag) => parseInt(tag)),
        parseInt(searchParams.get("page") || "1"),
      );

      setCoaches(coaches);

      const nextPageCoaches = await PublicProfileRequests.getCoaches(
        searchParams.get("q") || "",
        searchParams.getAll("tags").map((tag) => parseInt(tag)),
        parseInt(searchParams.get("page") || "1") + 1,
      );

      if (nextPageCoaches.length) {
        setHasNextPage(true);
      } else {
        setHasNextPage(false);
      }
    } catch (error) {
      console.error(error);
    }
  }

  // UseEffects
  useEffect(() => {
    getCoachTags();
  }, []);

  useEffect(() => {
    getCoaches();
  }, [searchParams]);

  return (
    <>
      {!Boolean(
        searchParams.getAll("tags").length || searchParams.get("q"),
      ) && (
        <section
          className="-mt-16 mb-12 bg-cover"
          style={{
            background: `linear-gradient(357deg, rgba(236, 240, 255, 0.25) -20.82%, #F8F8F8 97.75%), url(${heroBg}), lightgray 50% / cover no-repeat`,
          }}
        >
          <div className="container flex max-w-screen-xl flex-col gap-8 pb-12 pt-36">
            <h1 className="text-3xl font-bold sm:text-3xl md:text-4xl lg:text-5xl">
              Welcome to Zoee!
            </h1>
            <p className="text-xl sm:text-2xl md:w-2/3 md:text-3xl">
              One platform - many voices! From Executive Coaching to Spiritual Coaching and everything in between, Zoee is on a mission to shape a world where every life is profoundly impacted! 
            </p>

            <div className="-mt-6 flex flex-col gap-4 sm:-mt-10">
              <div className="ml-auto flex gap-4">
                <Button
                  onClick={scrollPrev}
                  size="icon"
                  className="rounded-full"
                  variant="ghost"
                  aria-label="Scroll To Previous"
                >
                  <SvgChevronLeft className="h-6 w-6" />
                </Button>
                <Button
                  onClick={scrollNext}
                  size="icon"
                  className="rounded-full"
                  variant="ghost"
                  aria-label="Scroll To Next"
                >
                  <SvgChevronRight className="h-6 w-6" />
                </Button>
              </div>

              <div className="overflow-hidden" ref={emblaRef}>
                <div className="-mr-4 flex">
                  {tags.map((tag) => (
                    <CategorySlide {...tag} key={tag.id} />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      <section className="container my-12 grid grid-cols-1 gap-8 sm:grid-cols-2  md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
        <div className="col-span-full lg:hidden">
          <SearchMarketplace />
        </div>
        {coaches.length ? (
          <>
            {coaches.map((coach) => (
              <MarketplaceProfileCard
                {...coach}
                key={coach.coach_public_profile_id}
              />
            ))}
            {/*
              <div className="col-span-full flex items-center justify-between">
                <Button
                  variant="secondary"
                  onClick={prevPage}
                  disabled={parseInt(searchParams.get("page") || "1") <= 1}
                >
                  <SvgChevronLeft /> Previous
                </Button>
                <Button
                  variant="secondary"
                  onClick={nextPage}
                  disabled={!hasNextPage}
                >
                  Next <SvgChevronRight />
                </Button>
              </div>
              */}
          </>
        ) : (
          <NoCoachesFound />
        )}
      </section>

      <Footer />
    </>
  );
}

function NoCoachesFound() {
  return (
    <div className="col-span-full my-12 flex flex-col items-center gap-2 text-center">
      <h1 className="text-2xl font-bold">No Coaches Found</h1>
      <p className="text-muted-foreground">Try adjusting your search</p>
    </div>
  );
}

function CategorySlide({ id, name, image }: CoachTagWithImage) {
  return (
    <Link
      to={`/find-a-coach?tags=${id}`}
      className="group relative mr-4 block aspect-[7/6] shrink-0 grow-0 basis-[calc(50%-16px)] overflow-hidden rounded-xl sm:basis-[calc(33.33%-16px)] md:basis-[calc(25%-16px)] lg:basis-[calc(20%-16px)] xl:basis-[calc(16.66%-16px)]"
    >
      <img
        src={image}
        alt={name}
        className="absolute inset-0 h-auto w-full transition-all group-hover:scale-110"
      />
      <div className="absolute inset-0 bg-gradient-to-b from-black/50 via-transparent to-transparent"></div>
      <span className="sm:text-md absolute inset-x-3 inset-y-4 font-bold text-white sm:inset-x-5 sm:inset-y-4 md:text-lg">
        {name}
      </span>
    </Link>
  );
}
