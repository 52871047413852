import React, {
  ChangeEvent,
  FC,
  FormEvent,
  useContext,
  useEffect,
  useState,
} from "react";
import SecurePassword from "../../../../components/authentication/SecurePassword";
import ForgotPasswordModal from "../../../../components/account-settings/ForgotPasswordModal";
import { UserInfo } from "../../../../api/app.service";
import { UserCtx } from "../../../../context/userContext";
import { CommonFunctionCtx } from "../../../../context/commonFunctionContext";
import useLoading from "../../../../hooks/useLoading";
import { SvgEdit, SvgLock, SvgWarning } from "../../../../components/icons";
import { Button } from "../../../../components/ui/button";
import PasswordRequirements from "../../../../components/account-settings/PasswordRequirements";

const LoginSecurityPage: FC = () => {
  const { renderError, renderSuccess } =
    useContext(CommonFunctionCtx);
  const { startLoading, stopLoading } = useLoading();
  const { user } = useContext(UserCtx);
  const [formErrors, setFormErrors] = useState<any>({});
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const [editPassword, setEditPassword] = useState<boolean>(false);
  const [deactivateStarted, setDeactivateStarted] = useState<boolean>(false);
  const [deactivateConfirmEmail, setDeactivateConfirmEmail] =
    useState<string>("");
  const [currentPassword, setCurrentPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [validPasswordChars, setValidPasswordChars] = useState<boolean>(false);
  const [confirmNewPassword, setConfirmNewPassword] = useState<string>("");
  const [showForgotPasswordModal, setShowForgotPasswordModal] =
    useState<boolean>(false);
  const [showPassReq, setShowPassReq] = useState(false);
  const [validEntree, setValidEntree] = useState("");

  const handleEditPassword = () => {
    setEditPassword(true);
    const password: any = document.getElementById("curr-password");
    setTimeout(function () {
      password.focus();
    }, 20);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCurrentPassword(e.target.value);
  };

  const handleChangeDeactivate = (e: ChangeEvent<HTMLInputElement>) => {
    setDeactivateConfirmEmail(e.target.value);
  };

  const validateCharacters = (target: any) => {
    var pswd = target.value;
    var pattern = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]/;
    setValidEntree(pswd);
    if (pswd.match(pattern)) {
      setValidPasswordChars(true);
    } else {
      setValidPasswordChars(false);
    }
  };

  const handleChangeNew = (e: ChangeEvent<HTMLInputElement>) => {
    setNewPassword(e.target.value);
    validateCharacters(e.target);
  };

  const handleChangeConfirmNew = (e: ChangeEvent<HTMLInputElement>) => {
    setConfirmNewPassword(e.target.value);
  };

  const closeEditForm = () => {
    setEditPassword(false);
    setIsSubmit(false);
    setFormErrors({});
    resetFields();
  };

  const handleDeactivateClick = () => {
    const mailto = "mailto:support@zoee.com?subject=Deactivate My Zoee Account";
    if (deactivateStarted) {
      const email = user?.email || "";
      if (email === deactivateConfirmEmail) {
        window.location.href = mailto;
      } else {
        renderError(
          "Email must match your account email to start deactivation."
        );
      }
    } else {
      setDeactivateStarted(true);
    }
  };

  const validateForm = () => {
    const errors: any = {};
    !currentPassword &&
      (errors.currentPassword = "Correct current password is required");
    (!newPassword || !validPasswordChars) &&
      (errors.newPassword = "Valid password is required");
    (!confirmNewPassword || confirmNewPassword !== newPassword) &&
      (errors.confirmPassword =
        "Confirm password must be the same as new password");
    return errors;
  };

  const resetFields = () => {
    setCurrentPassword("");
    setNewPassword("");
    setConfirmNewPassword("");
  };

  const changePassword = () => {
    startLoading();
    setIsSubmit(false);
    UserInfo.changePassword({
      access_token: user?.access_token || "",
      previous_password: currentPassword,
      proposed_password: newPassword,
    })
      .then((data) => {
        if (data.message === "complete") {
          // show success message
          renderSuccess("Your password is updated!");
          // reset fields
          resetFields();
        }
      })
      .catch((ex) => {
        console.log(ex);
        let error = "Changing user password failed.";
        if (ex.response.data.error_detail.includes("LimitExceededException")) {
          error =
            "You've tried too many times. Come back later to change your password";
        }
        renderError(error);
        resetFields();
      })
      .finally(() => {
        stopLoading();
      });
    setEditPassword(false);
  };

  useEffect(() => {
    if (isSubmit && Object.keys(formErrors).length === 0) {
      changePassword();
    }
  }, [formErrors]);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setFormErrors(validateForm());
    setIsSubmit(true);
  };

  return (
    <>
      {showForgotPasswordModal && (
        <ForgotPasswordModal
          setShowForgotPasswordModal={setShowForgotPasswordModal}
        />
      )}
      <div className="md:px-auto mx-auto flex flex-col px-[32px] md:w-[744px]">
        <div className="main-shadow mb-[32px] flex flex-col rounded-[16px] p-[24px] md:w-[744px] md:p-[48px]">
          <h4 className="mb-[16px] text-[22px] font-bold text-black md:mb-[24px] md:text-[28px] md:font-semibold">
            Change Password
          </h4>
          <form onSubmit={handleSubmit}>
            <div
              className={`mb-[16px] flex flex-col gap-[16px] md:flex-row md:gap-[14px] ${
                editPassword && "md:gap-[10px]"
              }`}
            >
              <div className="flex items-center">
                <div className="flex w-full flex-col">
                  <div
                    className={`default-input ${
                      editPassword
                        ? "w-full md:w-[648px]"
                        : "w-full md:w-[592px]"
                    }`}
                  >
                    <input
                      type="password"
                      placeholder="Current Password"
                      value={currentPassword}
                      onChange={handleChange}
                      name="password"
                      id="curr-password"
                      autoComplete="off"
                      disabled={!editPassword}
                    />
                    <div className="overlay">Current Password</div>
                  </div>
                  {formErrors.currentPassword && (
                    <div className="field-error">
                      <SvgWarning />
                      {formErrors.currentPassword}
                    </div>
                  )}
                </div>
                <SvgEdit
                  className={`ml-[24px] cursor-pointer ${
                    editPassword && "hidden"
                  } `}
                  onClick={handleEditPassword}
                />
              </div>
            </div>
            {editPassword && (
              <>
                <Button
                  variant="link"
                  className="mb-[16px]"
                  onClick={() => setShowForgotPasswordModal(true)}
                >
                  <SvgLock />
                  Forgot Your Password?
                </Button>
                <div
                  className={`mb-[16px] flex flex-col gap-[16px] md:flex-row md:gap-[14px] ${
                    editPassword && "md:gap-[10px]"
                  }`}
                >
                  <div className="flex items-center">
                    <div className="flex w-full flex-col">
                      {showPassReq && (
                        <PasswordRequirements
                          validEntree={validEntree}
                          bottomMargin="6"
                        />
                      )}
                      <div className={`default-input w-full md:w-[648px]`}>
                        <input
                          type="password"
                          value={newPassword}
                          placeholder="New Password"
                          onChange={handleChangeNew}
                          name="password"
                          id="new-password"
                          autoComplete="off"
                          disabled={!editPassword}
                          onFocus={() => setShowPassReq(true)}
                          onBlur={() => setShowPassReq(false)}
                        />
                        <div className="overlay">New Password</div>
                      </div>
                      {formErrors.newPassword && (
                        <div className="field-error">
                          <SvgWarning />
                          {formErrors.newPassword}
                        </div>
                      )}
                    </div>
                    <SvgEdit
                      className={`ml-[24px] cursor-pointer md:hidden ${
                        editPassword && "hidden"
                      } `}
                      onClick={handleEditPassword}
                    />
                  </div>
                </div>
                <SecurePassword password={newPassword} />
                <div
                  className={`mb-[16px] flex flex-col gap-[16px] md:flex-row md:gap-[14px] ${
                    editPassword && "md:gap-[10px]"
                  }`}
                >
                  <div className="flex items-center">
                    <div className="flex w-full flex-col">
                      <div className={`default-input w-full md:w-[648px]`}>
                        <input
                          type="password"
                          placeholder="Confirm Password"
                          value={confirmNewPassword}
                          onChange={handleChangeConfirmNew}
                          name="password"
                          id="conf-password"
                          autoComplete="off"
                          disabled={!editPassword}
                        />
                        <div className="overlay">Confirm Password</div>
                      </div>
                      {formErrors.confirmPassword && (
                        <div className="field-error">
                          <SvgWarning />
                          {formErrors.confirmPassword}
                        </div>
                      )}
                    </div>
                    <SvgEdit
                      className={`ml-[24px] cursor-pointer md:hidden ${
                        editPassword && "hidden"
                      } `}
                      onClick={handleEditPassword}
                    />
                  </div>
                </div>
                <div className="flex w-full items-center justify-between md:w-[648px]">
                  <Button
                    type="button"
                    variant="secondary"
                    onClick={closeEditForm}
                  >
                    Cancel
                  </Button>
                  <Button type="submit">Update Password</Button>
                </div>
              </>
            )}
          </form>
        </div>
        <div
          className={`main-shadow flex flex-col justify-between rounded-[16px] p-[24px] md:w-[744px] md:p-[48px] ${
            deactivateStarted ? "md:flex-col" : "md:flex-row md:items-center"
          }`}
        >
          <div className="flex flex-col gap-[8px] md:gap-[12px]">
            <h4 className="text-[22px] font-bold text-black md:text-[28px] md:font-semibold">
              Account
            </h4>
            <p className="md:text-mainBlack text-graySlate text-base md:w-[200px] md:text-[16px]">
              Deactivate your account
            </p>
          </div>
          {deactivateStarted && (
            <div
              className={`default-input mt-[24px] w-full md:mb-[24px] md:w-[648px]`}
            >
              <input
                type="text"
                placeholder="Enter your email"
                onChange={handleChangeDeactivate}
                name="deactivate-email"
                id="deactivate-email"
                autoComplete="off"
              />
              <div className="overlay">Email</div>
            </div>
          )}
          <div className="mt-[24px] flex w-full items-center justify-end gap-[10px] md:m-0">
            <Button
              variant="destructive"
              className={deactivateStarted ? "w-auto" : "w-full md:w-auto"}
              onClick={handleDeactivateClick}
            >
              {deactivateStarted ? "Confirm Deactivate" : "Deactivate"}
            </Button>
            {deactivateStarted && (
              <Button
                variant="secondary"
                onClick={() => setDeactivateStarted(false)}
              >
                Cancel
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginSecurityPage;
