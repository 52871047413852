import React from "react";
import ContactCard from "./ContactCard";
import marketplaceSoon from "../../assets/images/marketplace-coming-soon.webp";
import ContactMobileListItem from "./ContactMobileListItem";
import { UserCtx } from "../../context/userContext";
import { Button } from "../ui/button";
import { SvgComingSoon, SvgUserPlus, SvgUsers } from "../icons/";

interface Props {
  setInvites?: any;
  setShowConfirmInviteModal?: any;
  getContacts?: any;
  contacts: any;
  searchValue: string;
  setSelectedContact: any;
  setShowContactProfile: any;
  type: string;
  setShowInviteModal: any;
  setContactRequestFull: any;
  acceptContactRequest: any;
  denyContactRequest: any;
  revokeContactRequest: any;
  resendContactRequest: any;
  showContactsMobileListView: boolean;
}

const ContactsList: React.FC<Props> = ({
  setInvites,
  setShowConfirmInviteModal,
  getContacts,
  contacts,
  searchValue,
  setSelectedContact,
  denyContactRequest,
  resendContactRequest,
  setShowContactProfile,
  type,
  setShowInviteModal,
  setContactRequestFull,
  acceptContactRequest,
  revokeContactRequest,
  showContactsMobileListView,
}) => {
  const [foundContacts, setFoundContacts] = React.useState<any>(null);
  const [showInviteButton, setShowInviteButton] = React.useState(false);
  const { user } = React.useContext(UserCtx);

  const updateFoundContacts = () => {
    const keyword = searchValue;
    if (keyword !== "") {
      const results = contacts.filter((contact: any) => {
        const name = contact.contact_name;
        return name.toLowerCase().includes(keyword.toLowerCase());
      });
      setFoundContacts(results);
    } else {
      setFoundContacts(contacts);
    }
  };

  const getNoContactString = () => {
    if (type === "all") {
      return `Looks like you don't have any contacts yet! Let's get started by inviting your ${
        user.activeProfile === "coach" ? "clients" : "coaches"
      } to Zoee.`;
    } else if (type === "invited") {
      return "Looks like you don't have any sent invitations at the moment.";
    } else if (type === "leads") {
      return "Looks like you don't have any Zoee leads yet.";
    } else if (type === "prospect") {
      return "Looks like you don't have any prospective clients yet";
    } else if (type === "connected") {
      return "Looks like you don't have any connected contacts yet.";
    } else if (type === "requested") {
      return "Looks like you don't have any pending contact requests at the moment.";
    }
  };

  React.useEffect(() => {
    if (!foundContacts) {
      setFoundContacts(contacts);
    }
    updateFoundContacts();
  }, [searchValue, contacts]);

  const shouldApplyMargin = (index: number, totalCards: number) => {
    const screenWidth = window.innerWidth;
    let columns;

    if (screenWidth < 640) {
      columns = 2;
    } else if (screenWidth < 768) {
      columns = 3;
    } else {
      columns = 4;
    }
    const lastRowStartIndex = Math.floor((totalCards - 1) / columns) * columns;
    return screenWidth < 768 && index >= lastRowStartIndex;
  };

  React.useEffect(() => {
    setShowInviteButton(window.innerWidth <= 768);
  }, []);

  return (
    <>
      {/* MOBILE */}
      <div className="md:hidden">
        <>
          {showContactsMobileListView ? (
            // Mobile list view
            <div className="flex flex-col w-full px-[20px]">
              {foundContacts?.map((contact: any, index: number) => (
                <ContactMobileListItem
                  key={contact.profile_id || `cont${index}`}
                  contact={contact}
                  setContactRequestFull={setContactRequestFull}
                  type={type}
                  setShowInviteModal={setShowInviteModal}
                  showInviteButton={showInviteButton && index === 0}
                />
              ))}
            </div>
          ) : (
            // Mobile grid view
            <div className="px-[8px] w-full grid grid-cols-2 justify-items-center sm:flex mx-auto items-center sm:flex-row sm:flex-wrap justify-center gap-[8px]">
              {foundContacts?.map((contact: any, index: number) => (
                <ContactCard
                  setInvites={setInvites}
                  setShowConfirmInviteModal={setShowConfirmInviteModal}
                  getContacts={getContacts}
                  key={contact.profile_id}
                  setSelectedContact={setSelectedContact}
                  setShowContactProfile={setShowContactProfile}
                  contact={contact}
                  setContactRequestFull={setContactRequestFull}
                  acceptContactRequest={acceptContactRequest}
                  denyContactRequest={denyContactRequest}
                  revokeContactRequest={revokeContactRequest}
                  resendContactRequest={resendContactRequest}
                  shouldApplyMargin={shouldApplyMargin(
                    index,
                    foundContacts.length
                  )}
                />
              ))}
            </div>
          )}

          {contacts?.length === 0 && (
            <>
              {type !== "lead" ? (
                <div className="flex flex-col gap-[24px] items-center mx-auto mt-[100px]">
                  <SvgUsers />
                  <h5 className="font-bold text-[16px] text-center max-w-[300px]">
                    {`${getNoContactString()}`}
                  </h5>
                </div>
              ) : (
                <div className="flex items-center flex-col rounded-[16px] main-shadow p-[24px] pb-[32px] mx-auto">
                  <img
                    src={marketplaceSoon}
                    alt="marketplace coming soon"
                    className="w-full mb-[32px]"
                  />
                  <SvgComingSoon className="mb-[32px]" />
                  <h5 className="font-bold text-[22px] mb-[8px]">
                    Zoee Marketplace
                  </h5>
                  <p className="text-graySlate font-bold text-base">
                    Coming Soon!
                  </p>
                </div>
              )}
            </>
          )}
        </>
      </div>

      {/* DESKTOP */}
      <div className="hidden w-full md:flex mx-auto items-center md:flex-row flex-wrap gap-[16px] justify-center md:justify-start">
        {foundContacts?.map((contact: any, index: number) => (
          <div
            className="gap-[8px]"
            key={contact.profile_id}
          >
            <ContactCard
              setInvites={setInvites}
              setShowConfirmInviteModal={setShowConfirmInviteModal}
              getContacts={getContacts}
              setSelectedContact={setSelectedContact}
              setShowContactProfile={setShowContactProfile}
              contact={contact}
              setContactRequestFull={setContactRequestFull}
              acceptContactRequest={acceptContactRequest}
              denyContactRequest={denyContactRequest}
              revokeContactRequest={revokeContactRequest}
              resendContactRequest={resendContactRequest}
              shouldApplyMargin={shouldApplyMargin(index, foundContacts.length)}
            />
          </div>
        ))}
        {contacts?.length === 0 && (
          <>
            {type !== "lead" ? (
              <div className="flex flex-col gap-[24px] items-center mx-auto mt-[100px]">
                <SvgUsers />
                <h5 className="font-bold text-[18px] w-[355px] text-center">
                  {`${getNoContactString()}`}
                </h5>
              </div>
            ) : (
              <div
                className="w-[600px] h-[434px] flex items-center flex-col rounded-[16px] main-shadow p-[20px] mx-auto"
              >
                <img
                  src={marketplaceSoon}
                  alt="marketplace coming soon"
                  className="w-full mb-[66px]"
                />
                <SvgComingSoon className="mb-[32px]" />
                <h5 className="font-bold text-[28px] mb-[8px]">
                  Zoee Marketplace
                </h5>
                <p className="text-graySlate font-bold text-[16px]">
                  Coming Soon!
                </p>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default ContactsList;
