import React from "react";
import { useNavigate } from "react-router-dom";
import { CoachPrivateProfileContext } from "../../../pages/manage-business/profile/context/coachPrivateProfileContext";
import { getTextColor } from "../../../utils/getTextColor";
import { SvgConsultation } from "../../icons/";

export default function CreateConsultationBanner() {
    const navigate = useNavigate()
    const { coachPublicProfileData } = React.useContext(CoachPrivateProfileContext);

    const goToCreateConsultation = () => {
        navigate(`/coach/services`, {
            state: { serviceType: "consultation" }
        });
    }

    return (
        <div 
            className="w-full px-[48px] py-[32px] rounded-[10px]
            border border-1 border-gray flex flex-col lg:flex-row items-center justify-between"
        >
            <div className="flex flex-col items-start gap-2">
                <div className="flex">
                    <span> 
                        <SvgConsultation />
                    </span>
                    <h5 className="font-bold text-[18px] ml-2">
                        You haven't created a consultation service
                    </h5>
                </div>
                <div className="flex flex-col ">
                    <p className="text-base ml-8">
                        We recommend setting up a consultation service so new clients can set up an initial session with you
                    </p>
                </div>
            </div>
            {/* TODO: USE NAVIGATE BELOW TO SHOW AVAILABILITY TAB WHEN LANDING ON /COACH/SCHEDULING */}
            <button
                onClick={goToCreateConsultation}
                className={`h-[50px] px-[16px] py-[6px] rounded-md w-full lg:w-auto mt-[20px] md:m-0 shadow-outline hover:opacity-90`}
                style={{backgroundColor: `${coachPublicProfileData?.profile_information?.profileColor}`,
                        color: `${getTextColor(coachPublicProfileData?.profile_information?.profileColor)}`
            }}
            >
                Create Consultation
            </button>
        </div>
    )
}
