import * as React from 'react';
import {loadStripe} from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';
// Make sure to call `loadStripe` outside of a component's render to avoid
// recreating the `Stripe` object on every render.

const user_environment = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || ""

interface Props {
    clientSecret: any
    connectedAccountID: any
    handleComplete: any
}

const SessionCheckout: React.FC<Props> = ({clientSecret, connectedAccountID, handleComplete}) => {
  const options = {clientSecret};
  const stripePromise = loadStripe( user_environment, {
    stripeAccount: connectedAccountID,
  });

  return (
    <EmbeddedCheckoutProvider
      stripe={stripePromise}
      options={{...options,
        onComplete: handleComplete}}
    >
      <EmbeddedCheckout />
    </EmbeddedCheckoutProvider>
  )
}

export default SessionCheckout;