import moment from "moment"
import * as React from "react"
import {
  NotificationRequests,
  ReferralRequests,
  UserAccounts,
  VerifyEmail,
} from "../../../api/app.service"
import { User } from "../../../api/auth.service"
import { CommonFunctionCtx } from "../../../context/commonFunctionContext"
import { generatePassword } from "../../../utils/generatePassword"
import { isLocalStorageAvailable } from "../../../utils/isLocalStorageAvailable"
import { OnSuccessfulAuthCallback } from "../context"
import { useLocation } from "react-router-dom"

type View = "account-type" | "email" | "password" | "name" | "phone"
export type AccountType = "member" | "coach" | null

export interface RegisterFormContext {
  accountType: AccountType
  setAccountType: (accountType: AccountType) => void

  email: string
  setEmail: (email: string) => void

  password: string
  setPassword: (password: string) => void

  firstName: string
  setFirstName: (name: string) => void

  lastName: string
  setLastName: (name: string) => void

  phone: string | null
  setPhone: (phone: string) => void

  view: View
  setView: (view: View) => void

  createAccount: (organization?: string) => Promise<void>
}

export const RegisterFormContext = React.createContext<RegisterFormContext>(
  {} as RegisterFormContext
)

export const RegisterFormProvider: React.FC<
  React.PropsWithChildren<{
    defaultEmail: string
    defaultAccountType: AccountType
    defaultPhone: string
    defaultFirstName: string
    defaultLastName: string
    defaultPassword: string
    onSuccess: OnSuccessfulAuthCallback
  }>
> = ({
  children,
  defaultEmail,
  defaultAccountType,
  onSuccess,
  defaultPhone,
  defaultFirstName,
  defaultLastName,
  defaultPassword,
}) => {
  const [accountType, setAccountType] = React.useState<AccountType>(
    defaultAccountType || null
  )
  const [email, setEmail] = React.useState<string>(defaultEmail || "")
  const [firstName, setFirstName] = React.useState<string>(
    defaultFirstName || ""
  )
  const [lastName, setLastName] = React.useState<string>(defaultLastName || "")
  const [password, setPassword] = React.useState<string>(defaultPassword || "")
  const [phone, setPhone] = React.useState<string | null>(defaultPhone || null)
  const [view, setView] = React.useState<View>(
    defaultAccountType ? "email" : "account-type"
  )
  const location = useLocation()
  const { renderError, getUser } = React.useContext(CommonFunctionCtx)

  const createAccount = async (organization?: string) => {
    try {

      const referralCode = localStorage.getItem("referrerPromoCode")

      const newUser = await User.createUser({
        email: email.toLowerCase(),
        password: password ? password : generatePassword(),
        first_name: firstName,
        last_name: lastName,
        timezone: { value: moment.tz.guess(true) },
        user_type: accountType || "member",
        phone_number: phone,
        // TODO look into this value, I don't believe it's being used on backend. If so remove from createUser type
        organization: organization || null,
        connection_id: "",
        referral_code: "",
        email_newsletter: false,
        terms_and_conditions: true,
      })

      // newUser will have member_profile_id None if it is a coach profile. and vice versa

      if (isLocalStorageAvailable()) {
        localStorage.setItem("user", JSON.stringify(newUser))
      }

      const createStreamUserPromise = UserAccounts.createStreamUser({
        coach_profile_id: parseInt(newUser.coach_profile_id),
        member_profile_id: parseInt(newUser.member_profile_id),
        is_coach: newUser.is_coach,
        first_name: firstName || email,
        last_name: lastName || "",
      })

      const createStripeUserPromise = UserAccounts.createStripeUser({
        user_id: parseInt(newUser.user_id),
        email: email.toLowerCase(),
        phone_number: phone,
        first_name: firstName || email,
        last_name: lastName || "",
        user_environment: process.env.REACT_APP_USER_ENVIRONMENT || "",
        user_type: accountType || "member",
      })

      // as per new C2C, member_profile_id will be None. Hence only the payload will have only one value at all times.
      const createNotificationSettingsPromise =
        NotificationRequests.createNotificationSettings({
          profiles: [
            ...(newUser.coach_profile_id && newUser.coach_profile_id !== null
              ? [{ id: newUser.coach_profile_id, type: "coach" }]
              : []),
            ...(newUser.member_profile_id && newUser.member_profile_id !== null
              ? [{ id: newUser.member_profile_id, type: "member" }]
              : []),
          ],
        })
        
      const verifyEmailPromise = VerifyEmail.sendEmailCode({
        user_id: parseInt(newUser.user_id), pathname: `${location.pathname}${location.search}`
      }).then(() => {
        referralCode &&
        ReferralRequests.createReferralOnCreateAccount({
          user_id: parseInt(newUser.user_id),
          email:email.toLowerCase(),
          promo_code: referralCode,
        })
          .then((data) => {
              console.log("createReferral", data);
          })
          .catch((ex: { response: any }) => {
              console.error(ex);
          });
      })

      await Promise.all([
        createStreamUserPromise,
        createStripeUserPromise,
        createNotificationSettingsPromise,
        verifyEmailPromise,
      ])

      const userData = await getUser()
      onSuccess(userData)
    } catch (error: any) {
      renderError(error?.response?.data?.message)
    }
  }

  return (
    <RegisterFormContext.Provider
      value={{
        accountType,
        setAccountType,
        email,
        setEmail,
        password,
        setPassword,
        firstName,
        setFirstName,
        lastName,
        setLastName,
        phone,
        setPhone,
        view,
        setView,
        createAccount,
      }}
    >
      {children}
    </RegisterFormContext.Provider>
  )
}
