import React from "react"
import { useParams, useLocation } from "react-router-dom"
import { UserCtx } from "../../../context/userContext"
import { ContactRequests, MeetingRequests } from "../../../api/app.service"
import { CommonFunctionCtx } from "../../../context/commonFunctionContext"
import NotesPreviewElement from "./NotesPreviewElement"
import MeetingsPreviewElement from "./MeetingsPreviewElement"
import BillingPreviewElement from "./BillingPreviewElement"
import ServicesPreviewElement from "./ServicesPreviewElement"
import ContactPrimaryDetails from "./ContactPrimaryDetails"
import NotesPopover from "../../../components/notes/NotesPopover"
import BillingMobileView from "./BillingMobileView"
import BackToButton from "../../BackToButton"
import useLoading from "../../../hooks/useLoading"
import useScrollToTop from "../../../hooks/useScrollToTop"
import { Button } from "../../ui/button"
import MeetingPreviewDetails from "./MeetingPreviewDetails"
import moment from "moment"
import { differenceInMinutes, parseISO } from "date-fns"
import NewMeeting from "../../scheduling/new-meeting/NewMeeting"
import { SvgChat } from "../../icons"

interface Props {
  setActiveContactProfile: React.Dispatch<React.SetStateAction<any>>
}

const DynamicContactProfile: React.FC<Props> = ({
  setActiveContactProfile,
}) => {
  const { contact_id } = useParams()
  const location = useLocation()
  const { user } = React.useContext(UserCtx)
  const { renderError, renderSuccess } = React.useContext(CommonFunctionCtx)
  const { startLoading, stopLoading } = useLoading()
  const [contact, setContact] = React.useState<any>(null)
  const [callGetMemberNotes, setCallGetMemberNotes] = React.useState(false)
  const [showNotesPopover, setShowNotesPopover] = React.useState<boolean>(false)
  const [showToday, setShowToday] = React.useState<boolean>(true)
  const [showServices, setShowServices] = React.useState<boolean>(false)
  const [showBilling, setShowBilling] = React.useState<boolean>(false)
  const [showNoteModal, setShowNoteModal] = React.useState<boolean>(false)
  const [isNewNote, setIsNewNote] = React.useState<boolean>(false)
  const [openUpcomingDetails, setOpenUpcomingDetails] =
    React.useState<boolean>(false)
  const [upcomingSessionDetailViewId, setUpcomingSessionDetailViewId] =
    React.useState<number | null>(null)
  const [selectedState, setSelectedState] = React.useState<string>("upcoming")
  const [serviceState, setServiceState] = React.useState<string>("coach")
  const [meetings, setMeetings] = React.useState<any[]>([])
  const [filteredMeetings, setFilteredMeetings] = React.useState<any[]>([])
  const [showRescheduleMeeting, setShowRescheduleMeeting] =
    React.useState<boolean>(false)
  const [clickReschedule, setClickReschedule] = React.useState<boolean>(false)
  const [clickTodayReschedule, setClickTodayReschedule] =
    React.useState<boolean>(false)
  const [backToContacts, setBackToContacts] = React.useState<boolean>(false)
  const [keepBackToButton, setKeepBackToButton] = React.useState<boolean>(false)
  const [showNewMeeting, setShowNewMeeting] = React.useState<boolean>(false)
  const [newMeetingType, setNewMeetingType] = React.useState<
    "individual" | "group" | "custom"
  >("custom")
  // const [note, setNote] = React.useState<any>(null);

  interface C2CContact {
    avatar_url: string
    contact_date: Date
    contact_name: string
    contact_type: string
    email: string
    endpoint_slug: string | null
    has_avatar: boolean
    profile_connection_id: number
    profile_id: string
    profile_type: string | null
    public_profile_published: boolean
    status: string
    user_color: string
    user_id: string
    original_contact: any
  }

  const filterMeetingsForProfile = () => {
    if (meetings.length > 0) {
      const now = moment(Date.now())
      let filteredMeetings: any[] = []
      meetings.forEach((meeting: any) => {
        const participants_emails = meeting.contacts.map(
          (participant: any) => participant.email
        )
        if (meeting.is_managed) {
          if (selectedState === "upcoming") {
            if (
              participants_emails.includes(contact.email) &&
              meeting.startTime > now
            ) {
              filteredMeetings.push(meeting)
            }
          } else if (selectedState === "past") {
            if (
              participants_emails.includes(contact.email) &&
              meeting.startTime < now
            ) {
              filteredMeetings.push(meeting)
            }
          }
        }
      })

      if (selectedState === "upcoming") {
        filteredMeetings.sort((a: any, b: any) => {
          return moment.utc(a.startTime).diff(moment.utc(b.startTime))
        })
      } else {
        filteredMeetings.sort((a: any, b: any) => {
          return moment.utc(b.startTime).diff(moment.utc(a.startTime))
        })
      }

      setFilteredMeetings(filteredMeetings)
    }
  }

  const contactObj = (): C2CContact => {
    let contact_c2c: C2CContact = {
      avatar_url: user.avatar_url,
      contact_date: contact.contact_date,
      contact_name: user.firstName,
      contact_type: contact.contact_type,
      email: user.email,
      endpoint_slug: user?.coachProfile.endpoint_slug,
      has_avatar: user.hasAvatar,
      profile_connection_id: contact.profile_connection_id,
      profile_id: user.activeProfileId,
      profile_type: user.activeProfile,
      public_profile_published: user.isSubscribed,
      status: contact.status,
      user_color: user.userColor,
      user_id: user.user_id,
      original_contact: contact,
    }
    return contact_c2c
  }

  const getMeetings = () => {
    if (user) {
      MeetingRequests.getMeetings({
        user_id: parseInt(user.user_id),
        profile_id: user.activeProfileId,
        from_date: moment(Date.now()).subtract(30, "days").format("yyyy-MM-DD"),
        to_date: moment(Date.now()).add(201, "days").format("yyyy-MM-DD"),
      })
        .then((data) => {
          const meetings: any[] = []
          data.meetings.forEach((m) => {
            // parse UTC string from db to a JS Date object with time adjusted for user timezone
            const parsedStartTime = moment
              .utc(m.start_time)
              .tz(user.timezone.value)
              .toDate()
            const parsedEndTime = moment
              .utc(m.end_time)
              .tz(user.timezone.value)
              .toDate()

            const obj: any = {
              id: m.meeting_id,
              meetingType: m.meeting_type,
              inProgress: m.in_progress,
              contacts: m.participants,
              meetingName: m.name,
              status: m.status,
              medium: m.medium,
              date: parseISO(m.start_time),
              startTime: parsedStartTime,
              endTime: parsedEndTime,
              duration: differenceInMinutes(
                parseISO(m.end_time),
                parseISO(m.start_time)
              ),
              // coachUserId = m.coach_user_id
              // TODO need to know what external calendar it is coming from (e.g. google, office365) in order to use different views
              // or decide to only have one color for all non_managed calendars
              // 9/23/2022: For 2.0 launch, will just use the is_managed property to distinguish between Zoee-managed and others. Return to this property in future versions.
              calendar: `${m.is_managed ? "zoee" : "google"}`,
              timezone: user.timezone,
              // TODO backend for service not ready yet
              service: {},
              // TODO backend for notifications not ready yet
              notifications: [],
              belongs_to_profile: m.belongs_to_profile,
              is_managed: m.is_managed,
            }
            meetings.push(obj)
          })
          setMeetings(meetings)
        })
        .catch((ex) => {
          console.log(ex)
          renderError(ex.response.data)
        })
    }
  }

  const getContact = (profileId: string) => {
    if (user?.coachProfile || user?.memberProfile) {
      startLoading()
      ContactRequests.getContactList({
        profile_id: user.activeProfileId,
        profile_type: user.activeProfile,
      })
        .then((data) => {
          const foundContact = data.contact_list.find((c: any) => {
            return c.profile_id === parseInt(profileId)
          })

          // TODO double check that we want user to only be able to see contact profile of connected contacts
          if (foundContact.contact_type === "connected") {
            // TODO can probably consolidate these two state variables
            setContact(foundContact)
            setActiveContactProfile(foundContact)
          }
          stopLoading()
        })
        .catch((ex) => {
          console.log(ex)
          renderError(ex.response.data.message)
          stopLoading()
        })
    }
  }

  const handleStartMessage = () => {
    startLoading()
    window.location.href = `/${user.activeProfile}/inbox?chat=${contact.profile_id}`
  }

  let state: any = null
  if (location && location.state) {
    state = location.state
  }
  React.useEffect(() => {
    if (state?.status === "success") {
      renderSuccess("Subscription purchased!")
    }
    if (state?.contact) {
      setContact(state.contact)
    } else {
      if (contact_id) {
        getContact(contact_id)
      }
    }
  }, [state])

  React.useEffect(() => {
    filterMeetingsForProfile()
  }, [meetings, selectedState])

  React.useEffect(() => {
    //Disables background scroll when note modal is open
    if (showNoteModal) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "auto"
    }
    return () => {
      document.body.style.overflow = "auto"
    }
  }, [showNoteModal])

  const meeting = meetings?.find((s) => s.id === upcomingSessionDetailViewId)

  useScrollToTop()

  if (showBilling) {
    return (
      <BillingMobileView setShowBilling={setShowBilling} contact={contact} />
    )
  } else {
    return (
      <>
        {showRescheduleMeeting ? (
          <NewMeeting
            newMeetingType="custom"
            setShowNewMeeting={setShowNewMeeting}
            setActiveSessionDetailViewId={setUpcomingSessionDetailViewId}
            existingMeetingData={meeting}
            initialStep={"availability"}
            isEditing={true}
            setNewMeetingType={setNewMeetingType}
            showRescheduleMeeting={showRescheduleMeeting}
            setShowRescheduleMeeting={setShowRescheduleMeeting}
            clickReschedule={clickReschedule}
            setClickReschedule={setClickReschedule}
            clickTodayReschedule={clickTodayReschedule}
            backToContacts={backToContacts}
            contact={contact}
            keepBackToButton={keepBackToButton}
          />
        ) : (
          <>
            {openUpcomingDetails ? (
              <MeetingPreviewDetails
                meeting={meetings?.find(
                  (s) => s.id === upcomingSessionDetailViewId
                )}
                setOpenUpcomingDetails={setOpenUpcomingDetails}
                setUpcomingSessionDetailViewId={setUpcomingSessionDetailViewId}
                getMeetings={getMeetings}
                setBackToContacts={setBackToContacts}
                backToContacts={backToContacts}
                contact={contact}
                keepBackToButton={keepBackToButton}
              />
            ) : (
              <div className="px-[20px] md:px-0 md:w-[1080px] md:max-w-[90%] mx-auto flex flex-col pb-24">
                {contact ? (
                  <>
                    <div className="flex items-center justify-between">
                      <Button
                        className="flex gap-[10px] h-[40px] md:hidden text-md"
                        onClick={handleStartMessage}
                      >
                        Message
                        <SvgChat fill="white" />
                      </Button>
                    </div>
                    <div className="mb-[24px]">
                      <ContactPrimaryDetails
                        contact={contact}
                        setShowBilling={setShowBilling}
                      />
                    </div>

                    {/* MOBILE LAYOUT */}
                    {/* <div className="md:hidden flex items-center justify-center gap-[14px] mb-[16px] text-base font-bold">
                      <button
                        onClick={() => {
                          setShowToday(true)
                          setShowServices(false)
                        }}
                        className={
                          showToday
                            ? "py-[8px] px-[16px] rounded-[10px] btn-primary btn-secondary-nav h-[40px]"
                            : "py-[8px] px-[16px] rounded-[10px] text-graySlate h-[40px]"
                        }
                      >
                        Today
                      </button>
                      <button
                        onClick={() => {
                          setShowToday(false)
                          setShowServices(true)
                        }}
                        className={
                          showServices
                            ? "py-[8px] px-[16px] rounded-[10px] btn-primary btn-secondary-nav h-[40px]"
                            : "py-[8px] px-[16px] rounded-[10px] text-graySlate h-[40px]"
                        }
                      >
                        Services
                      </button>
                    </div> */}

                    <div className="md:hidden">
                      {showToday && (
                        <>
                          <div>
                            <NotesPreviewElement
                              showNoteModal={showNoteModal}
                              contact={contact}
                              setShowNotesPopover={setShowNotesPopover}
                              callGetMemberNotes={callGetMemberNotes}
                              setCallGetMemberNotes={setCallGetMemberNotes}
                              setShowNoteModal={setShowNoteModal}
                              isNewNote={isNewNote}
                              setIsNewNote={setIsNewNote}
                            />
                          </div>
                          <div className="mb-[16px]">
                            <MeetingsPreviewElement
                              contact={contact}
                              setOpenUpcomingDetails={setOpenUpcomingDetails}
                              setUpcomingSessionDetailViewId={
                                setUpcomingSessionDetailViewId
                              }
                              filteredMeetings={filteredMeetings}
                              getMeetings={getMeetings}
                              selectedState={selectedState}
                              setMeetings={setMeetings}
                              setSelectedState={setSelectedState}
                              setShowRescheduleMeeting={
                                setShowRescheduleMeeting
                              }
                              setBackToContacts={setBackToContacts}
                            />
                          </div>
                        </>
                      )}

                      {/* {showServices && (
                        <>
                          <ServicesPreviewElement
                            contact={contact}
                            serviceState={""}
                          />
                          {user.activeProfile === "coach" &&
                            contact.profile_type === "coach" && (
                              <ServicesPreviewElement
                                contact={contact}
                                serviceState={""}
                              />
                            )}
                        </>
                      )} */}
                    </div>

                    {/* DESKTOP LAYOUT */}
                    <div className="">
                      {/* <div className="w-full mb-[32px]">
										<NotesPreviewElement
											contact={contact}
											setShowNotesPopover={setShowNotesPopover}
											callGetMemberNotes={callGetMemberNotes}
											setCallGetMemberNotes={setCallGetMemberNotes}
											showNoteModal={showNoteModal}
											setShowNoteModal={setShowNoteModal}
											isNewNote={isNewNote}
											setIsNewNote={setIsNewNote}
										/>
								</div> */}
                      <div className="hidden md:block mb-[32px]">
                        <MeetingsPreviewElement
                          contact={contact}
                          setOpenUpcomingDetails={setOpenUpcomingDetails}
                          setUpcomingSessionDetailViewId={
                            setUpcomingSessionDetailViewId
                          }
                          filteredMeetings={filteredMeetings}
                          getMeetings={getMeetings}
                          selectedState={selectedState}
                          setMeetings={setMeetings}
                          setSelectedState={setSelectedState}
                          setShowRescheduleMeeting={setShowRescheduleMeeting}
                          setBackToContacts={setBackToContacts}
                        />
                      </div>

                      {user.activeProfile === "coach" &&
                      contact.profile_type === "coach" ? (
                        <>
                          <div className="w-full main-shadow rounded-[16px] px-[16px] py-[24px] md:p-[40px] relative">
                            <h2 className="hidden md:block font-semibold text-[28px]">
                              {contact.profile_type === "coach" &&
                                user.activeProfile === "coach" &&
                                "Services"}
                            </h2>
                            <div className=" items-center gap-[10px] mb-[32px]">
                              <div className="relative flex justify-center items-center w-full rounded-[20px] h-[50px] p-1">
                                <button
                                  className={`w-[50%] h-full rounded-[18px] font-bold
																${
                                  serviceState === "coach"
                                    ? "bg-blurple text-[white]"
                                    : "bg-grayFlash text-[black]"
                                } `}
                                  onClick={() => setServiceState("coach")}
                                >
                                  {contact.contact_name}
                                </button>
                                <button
                                  className={`w-[50%] h-full rounded-[18px] font-bold
																${
                                  serviceState === "client"
                                    ? "bg-blurple text-[white]"
                                    : "bg-grayFlash text-[black]"
                                }`}
                                  onClick={() => setServiceState("client")}
                                >
                                  {user.firstName}
                                </button>
                              </div>
                            </div>

                            {serviceState === "coach" && (
                              <>
                                <ServicesPreviewElement
                                  contact={contact}
                                  serviceState={serviceState}
                                />

                                <div className="main-shadow">
                                  <BillingPreviewElement
                                    contact={contact}
                                    serviceState={serviceState}
                                  />
                                </div>
                              </>
                            )}
                            {serviceState === "client" && (
                              <>
                                <ServicesPreviewElement
                                  contact={contactObj()}
                                  serviceState={serviceState}
                                />

                                <div className="main-shadow">
                                  <BillingPreviewElement
                                    contact={contact}
                                    serviceState={serviceState}
                                  />
                                </div>
                              </>
                            )}
                          </div>
                        </>
                      ) : (
                        <div className="mb-[32px]">
                          <>
                            <ServicesPreviewElement
                              contact={contact}
                              serviceState={""}
                            />
                            <div className="main-shadow">
                              <BillingPreviewElement
                                contact={contact}
                                serviceState={""}
                              />
                            </div>
                          </>
                        </div>
                      )}

                      {showNotesPopover && (
                        <NotesPopover
                          setShowNotesPopover={setShowNotesPopover}
                          setCallGetMemberNotes={setCallGetMemberNotes}
                          contact={contact}
                          showNoteModal={showNoteModal}
                          setShowNoteModal={setShowNoteModal}
                          isNewNote={isNewNote}
                          setIsNewNote={setIsNewNote}
                        />
                      )}
                      <div className="w-full mb-[32px]">
                        <NotesPreviewElement
                          contact={contact}
                          setShowNotesPopover={setShowNotesPopover}
                          callGetMemberNotes={callGetMemberNotes}
                          setCallGetMemberNotes={setCallGetMemberNotes}
                          showNoteModal={showNoteModal}
                          setShowNoteModal={setShowNoteModal}
                          isNewNote={isNewNote}
                          setIsNewNote={setIsNewNote}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <BackToButton
                      to="/coach/contacts"
                      prev="Contact Not Found"
                      current="Contacts"
                    />
                    {/* TODO improve uiux / messaging */}
                    <h3 className="mt-[24px] font-bold">No contact found</h3>
                  </>
                )}
              </div>
            )}
          </>
        )}
      </>
    )
  }
}

export default DynamicContactProfile
