import {
  FC,
  useState,
  useCallback,
  useMemo,
  useEffect,
  useContext,
} from "react"
import moment from "moment"
import { Button } from "../ui/button"
import { SvgChevronDown } from "../icons/"
import { UserCtx } from "../../context/userContext"

type Props = {
  setActiveDate: (date: Date) => void
  meetingDates?: moment.Moment[]
  disablePastDates?: boolean
  changeActiveDateOnMonthChange?: boolean
  dayColor: string
  pickerOpen?: boolean
  setPickerOpen?: any
  activeDate?: Date
}

const dayOfWeekStrings = ["S", "M", "T", "W", "T", "F", "S"]

const CustomDayPicker: FC<Props> = ({
  activeDate,
  setActiveDate,
  meetingDates,
  disablePastDates,
  changeActiveDateOnMonthChange,
  dayColor,
  pickerOpen,
  setPickerOpen,
}) => {
  const [selectedMonth, setSelectedMonth] = useState<moment.Moment>(moment())
  const [selectedDate, setSelectedDate] = useState<moment.Moment>(moment())

  const { user } = useContext(UserCtx)

  const goForwardOneMonth = useCallback(() => {
    const nextMonth = selectedMonth.clone().add(1, "month")
    if (changeActiveDateOnMonthChange) {
      setActiveDate(nextMonth.toDate())
    }
    setSelectedMonth(nextMonth)
  }, [selectedMonth, changeActiveDateOnMonthChange, setActiveDate])

  const goBackOneMonth = useCallback(() => {
    const previousMonth = selectedMonth.clone().subtract(1, "month")
    if (changeActiveDateOnMonthChange) {
      setActiveDate(previousMonth.toDate())
    }
    setSelectedMonth(previousMonth)
  }, [selectedMonth, changeActiveDateOnMonthChange, setActiveDate])

  const handleSelectDate = useCallback(
    (date: moment.Moment) => {
      if (disablePastDates && date.isBefore(moment(), "days")) {
        return
      }
      if (date.isAfter(moment().add(199, "days"))) {
        return
      }
      setSelectedDate(date)
      setActiveDate(date.toDate())
    },
    [setActiveDate, setSelectedDate, disablePastDates]
  )

  const handleSetToday = () => {
    const today = moment()
    setSelectedMonth(today)
    setSelectedDate(today)
    setActiveDate(today.toDate())
    if (pickerOpen) {
      setPickerOpen(false)
    }
  }

  const generateDaysInMonth = useCallback((month: moment.Moment) => {
    const currentMonthDates = Array.from(
      { length: month.daysInMonth() },
      (x, i) => moment(month).startOf("month").add(i, "days")
    )
    return currentMonthDates
  }, [])

  const generateActiveDates = useCallback(
    (currentMonthDates: moment.Moment[]) => {
      const numExtraDaysToGenerateAtBeginningOfMonth =
        currentMonthDates[0].day()
      const numExtraDaysToGenerateAtEndOfMonth =
        6 - currentMonthDates[currentMonthDates.length - 1].day()
      const daysInPreviousMonth = currentMonthDates[0]
        .subtract(1, "month")
        .daysInMonth()
      currentMonthDates[0].add(1, "month")

      const dayButtons = []
      for (let i = numExtraDaysToGenerateAtBeginningOfMonth; i > 0; i--) {
        dayButtons.push(
          <button
            onClick={goBackOneMonth}
            key={`prev${i}`}
            className="text-gray py-[6px]"
          >
            {daysInPreviousMonth - i + 1}
          </button>
        )
      }

      currentMonthDates.forEach((day, idx) => {
        const isToday = day.isSame(new Date(), "day")

        let hasMeetingOnDate = false
        if (meetingDates) {
          const match = meetingDates.find((meetingDate) => {
            return day.isSame(meetingDate, "day")
          })
          if (match) {
            hasMeetingOnDate = true
          }
        }
        const isSelectable = !(
          (disablePastDates && day.isBefore(moment(), "days")) ||
          day.isAfter(moment().add(199, "days"))
        )

        dayButtons.push(
          <button
            onClick={() => handleSelectDate(day)}
            disabled={disablePastDates && day.isBefore(moment(), "days")}
            key={`curr${idx}`}
            className={`py-[6px] relative
                        ${
                          day.isSame(selectedDate, "day")
                            ? "text-white rounded-full"
                            : "hover:outline hover:outline-1 hover:outline-offset-[-1px] hover:rounded-full"
                        } 
                        ${
                          disablePastDates && day.isBefore(moment(), "days")
                            ? "text-gray rounded-full hover:outline-0"
                            : ""
                        } 
                        ${!isSelectable ? "text-gray cursor-not-allowed" : ""}
                        `}
            style={{
              background: day.isSame(selectedDate, "day") ? dayColor : "",
              outline: dayColor,
            }}
          >
            {day.date()}
            {isToday ? (
              <div
                className={`absolute mx-auto left-0 right-0 bottom-[3px] h-[3px] w-[16px] rounded-full
                                `}
                style={{
                  background: day.isSame(selectedDate, "day")
                    ? "white"
                    : dayColor,
                }}
              ></div>
            ) : (
              <></>
            )}

            {hasMeetingOnDate && !isToday ? (
              <div
                className={`absolute mx-auto left-0 right-0 bottom-[3px] h-[4px] w-[4px] rounded-full
                                `}
                style={{
                  background: day.isSame(selectedDate, "day")
                    ? "white"
                    : dayColor,
                }}
              ></div>
            ) : (
              <></>
            )}
          </button>
        )
      })

      for (let j = 0; j < numExtraDaysToGenerateAtEndOfMonth; j++) {
        dayButtons.push(
          <button
            onClick={goForwardOneMonth}
            key={`next${j}`}
            className="text-gray py-[6px]"
          >
            {j + 1}
          </button>
        )
      }

      return dayButtons
    },
    [
      goForwardOneMonth,
      goBackOneMonth,
      handleSelectDate,
      selectedDate,
      meetingDates,
      disablePastDates,
    ]
  )

  const selectedMonthFormatted = useMemo(
    () => selectedMonth.format("MMMM yyyy"),
    [selectedMonth]
  )

  const activeMonthJSX = useMemo(
    () => generateActiveDates(generateDaysInMonth(selectedMonth)),
    [selectedMonth, generateActiveDates, generateDaysInMonth]
  )

  useEffect(() => {
    if (activeDate) {
      setSelectedDate(moment(activeDate))
      setSelectedMonth(moment(activeDate))
    }
  }, [activeDate])

  useEffect(() => {
    const savedDate = localStorage.getItem("selectedDate")
    if (savedDate) {
      const parsedDate = moment(savedDate)
      setSelectedDate(parsedDate)
      setSelectedMonth(parsedDate)
      setActiveDate(parsedDate.toDate())
    }
  }, [setActiveDate])

  useEffect(() => {
    if (activeDate) {
      localStorage.setItem("selectedDate", activeDate.toISOString())
    }
  }, [activeDate])

  return (
    <div
      className={`${
        pickerOpen
          ? "w-[400px] fixed top-8 right-1 bg-white z-50 rounded-[20px] shadow-outline px-4 py-8"
          : "w-full"
      }`}
    >
      <h4 className="flex justify-between font-semibold text-lg -mt-4 mb-4 px-4">
        {selectedMonthFormatted}

        <Button
          onClick={() => handleSetToday()}
          variant={"outline"}
          className="h-[30px] w-[75px] border border-gray text-black"
        >
          Today
        </Button>
      </h4>

      <div className="flex items-center justify-between mb-[16px] px-4 text-gray text-sm">
        <button className="flex items-center" onClick={goBackOneMonth}>
          <span className="rotate-90 text-sm">
            <SvgChevronDown />
          </span>
          {selectedMonth.clone().subtract(1, "month").format("MMM")}
        </button>
        <button className="flex items-center" onClick={goForwardOneMonth}>
          {selectedMonth.clone().add(1, "month").format("MMM")}
          <span className="-rotate-90 text-sm">
            <SvgChevronDown />
          </span>
        </button>
      </div>

      <div className="grid grid-cols-7 text-center mb-[10px]">
        {dayOfWeekStrings.map((dayString, idx) => {
          return (
            <h6 key={idx} className="text-graySlate text-base">
              {dayString}
            </h6>
          )
        })}
      </div>
      {pickerOpen ? (
        <div
          onClick={() => setPickerOpen(!pickerOpen)}
          className="grid grid-cols-7 gap-y-[2px] text-center text-base font-semibold"
        >
          {activeMonthJSX.map((date) => date)}
        </div>
      ) : (
        <div className="grid grid-cols-7 gap-y-[2px] text-center text-base font-semibold">
          {activeMonthJSX.map((date) => date)}
        </div>
      )}
    </div>
  )
}

export default CustomDayPicker
