import { useContext } from "react";
import { UserCtx } from "../context/userContext";

function useName(customUser: any = null) {
  const { user } = useContext(UserCtx);
  const currentUser = customUser || user; // Use customUser if provided, otherwise use the context user

  let initial = "?";
  let username = "";

  if (currentUser?.displayName) {
    initial = currentUser.displayName.charAt(0).toUpperCase();
    username = currentUser.displayName;
  } else if (currentUser?.firstName) {
    initial = currentUser.firstName.charAt(0).toUpperCase();
    username = `${currentUser.firstName} ${currentUser.lastName || ""}`;
  } else if (currentUser?.email) {
    initial = currentUser.email.charAt(0).toUpperCase();
    username = currentUser.email;
  }

  return { initial, username };
}

export default useName;
