import React, { useContext, useState, useEffect } from "react";
import { CoachPrivateProfileContext } from "../../../pages/manage-business/profile/context/coachPrivateProfileContext";
import { Link, useNavigate } from "react-router-dom";

// MAIN PAGE COMPONENTS
import ProfileBannerImage from "./ProfileBannerImage";
import ProfileInformation from "./ProfileInformation";
import TilesCarousel from "./TilesCarousel";

// EDIT COMPONENTS
import EditBannerImage from "./edit-forms/EditBannerImage";
import EditProfileInformation from "./edit-forms/EditProfileInformation";
import EditProfileAvatar from "./edit-forms/EditProfileAvatar";
import EditPortraitVideo from "./edit-forms/EditPortraitVideo";
import EditCredentials from "./edit-forms/EditCredentials";
import CreateConsultationBanner from "./CreateConsultationBanner";
import CreateBlogPost from "./edit-forms/CreateBlogPost";
import EditBlogPost from "./edit-forms/EditBlogPost";

import { UserInfo } from "../../../api/app.service";
import { CommonFunctionCtx } from "../../../context/commonFunctionContext";
import { UserCtx } from "../../../context/userContext";
import ProfileSyncPrompt from "./ProfileSyncPrompt";
import Loader from "../../ui/loader";
import useLoading from "../../../hooks/useLoading";
import PrivateProfileScheduler from "../../coach-public-profile/PrivateProfileScheduler";
import { getLightTextColor } from "../../../utils/getLightTextColor";
import { Button } from "../../ui/button";
import { ShareModal } from "./ShareModal";
import { ProfileVisibilityModal } from "./ProfileVisibilityModal";
import { SvgEye, SvgMarketplace, SvgNewWindow, SvgShare } from "../../icons/";

export default function ManageProfile() {
  const {
    editProfileInformation,
    editBannerImage,
    selectedTile,
    editprimaryProfileVideo,
    editCredentials,
    editBlogPost,
    createBlogPost,
    services,
    coachPublicProfileData,
    editPortraitVideo,
    setNextStepsData,
    syncPrompt,
  } = useContext(CoachPrivateProfileContext);
  const { user } = useContext(UserCtx);
  const { renderError } = useContext(CommonFunctionCtx);
  const [consultationModalOpen, setConsultationModalOpen] =
    React.useState<boolean>(false);
  const [shareModalOpen, setShareModalOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const { stopLoading } = useLoading();
  const [loadingCanPublish, setLoadingCanPublish] = useState<boolean>(false);
  const [visibilityModalOpen, setVisibilityModalOpen] =
    useState<boolean>(false);
  const textColor = getLightTextColor(
    coachPublicProfileData?.profile_information?.profileColor,
  );
  const endPoint = coachPublicProfileData?.endpoint_slug;
  const published = coachPublicProfileData?.published;
  const marketplaceVis = coachPublicProfileData?.marketplace_visibility;

  const getConsultation = () => {
    const consultations = services?.filter((service: any) => {
      return service?.service_details?.type === "consultation";
    });
    return consultations;
  };

  const getNextStepsValues = () => {
    if (user) {
      setLoadingCanPublish(true);
      UserInfo.getNextStepsValues({
        user_id: parseInt(user.user_id),
      })
        .then((data: any) => {
          checkProfileAccessStatus(data?.next_steps_data);
          setNextStepsData(data?.next_steps_data);
          setLoadingCanPublish(false);
        })
        .catch((ex: any) => {
          console.log(ex);
          renderError(ex.response.data.message);
          stopLoading();
        });
    }
  };

  const checkProfileAccessStatus = (data: {
    profile_access_status: string;
  }) => {
    if (["blocked", "pending"].includes(data.profile_access_status)) {
      navigate("/coach", { state: { violated_profile_access: true } });
    }
  };

  const profileVisibility = () => {
    if (!published && !marketplaceVis) {
      return <p className="flex text-xs">Draft Mode</p>;
    } else if (published && !marketplaceVis) {
      return <p className="text-blurple flex text-xs">Publish Mode</p>;
    } else if (published && marketplaceVis) {
      return <p className="text-grassGreen flex text-xs">Public Mode</p>;
    }
  };

  const getIcon = () => {
    if (!published && !marketplaceVis) {
      return (
        <span className="text-gray">
          <SvgEye />
        </span>
      );
    } else if (published && !marketplaceVis) {
      return (
        <span>
          <SvgEye />
        </span>
      );
    } else if (published && marketplaceVis) {
      return (
        <span>
          <SvgMarketplace />
        </span>
      );
    }
  };

  useEffect(() => {
    getNextStepsValues();
  }, []);

  return (
    <>
      <div className="-mt-0 w-full md:-mt-[65px] lg:flex lg:flex-col lg:items-center">
        <div className="container flex flex-col gap-10 px-4">
          {/* MAIN */}
          <div
            className={`xl:hidden ${
              consultationModalOpen ? "left-0" : "-left-[300px]"
            } hover:shadow-custom fixed top-[50px] z-[1001] flex h-[550px] w-[300px] cursor-pointer items-center justify-center rounded-xl transition-all duration-500 ease-in-out`}
            style={{ backgroundColor: textColor }}
          >
            <PrivateProfileScheduler />
            <div
              onClick={() => setConsultationModalOpen(!consultationModalOpen)}
              className={`shadow-outline } fixed top-[450px] z-[1000] -mr-[349px] flex h-[50px] w-[155px] -rotate-90 transform cursor-pointer items-center justify-center rounded-bl-lg rounded-br-lg
          xl:hidden`}
              style={{ backgroundColor: textColor }}
            >
              {consultationModalOpen ? (
                <h1 className="text-sm font-bold">
                  <span>Close</span>
                </h1>
              ) : (
                <h1 className="text-sm font-bold">
                  <span>Book</span>
                  <span className="ml-2">a</span>
                  <span className="ml-2">Consult</span>
                </h1>
              )}
            </div>
          </div>
          <div className="lg:pt-8">
            {loadingCanPublish ? (
              <Loader />
            ) : (
              <div className="mx-2 flex flex-col items-center justify-center gap-2 sm:flex-row md:h-16 lg:justify-between lg:gap-0">
                <div></div>
                <div className="ml-0 flex items-center justify-center gap-2 md:ml-[80px]">
                  <Button
                    onClick={() => setVisibilityModalOpen(true)}
                    variant="utility"
                    className="flex h-14 flex-col"
                  >
                    <span className="flex items-center gap-2">
                      {getIcon()}
                      Profile Visibility
                    </span>
                    <span className="-mt-2">{profileVisibility()}</span>
                  </Button>
                  {published && (
                    <Button
                      onClick={() => setShareModalOpen(true)}
                      variant="utility"
                      className="ml-4 hidden h-[50px] lg:flex"
                    >
                      <SvgShare />
                      Share
                    </Button>
                  )}
                </div>
                {shareModalOpen && (
                  <ShareModal
                    showWidget
                    endpoint_slug={endPoint}
                    shareModalOpen={shareModalOpen}
                    setShareModalOpen={setShareModalOpen}
                  />
                )}
                <Link
                  to={`/find-a-coach/${coachPublicProfileData?.endpoint_slug}`}
                  target="_blank"
                >
                  <Button variant="utility" className="h-[50px]">
                    <SvgNewWindow />
                    Preview my profile
                  </Button>
                </Link>
              </div>
            )}
          </div>
          <div className="block">
            <ProfileBannerImage />
          </div>
          <ProfileInformation />

          {getConsultation().length === 0 && (
            // <SetAvailabilityBanner />
            <CreateConsultationBanner />
          )}
          <TilesCarousel />

          {/* POPOVERS */}
          {syncPrompt && <ProfileSyncPrompt />}
          {editBannerImage && <EditBannerImage />}
          {editProfileInformation && <EditProfileInformation />}
          {editprimaryProfileVideo && <EditProfileAvatar />}
          {editCredentials && <EditCredentials />}
          {createBlogPost && <CreateBlogPost />}
          {editBlogPost?.show && <EditBlogPost />}
          {editPortraitVideo && <EditPortraitVideo />}
        </div>
        <div
          className={`mx-auto mt-[40px] max-w-[90%] ${
            selectedTile ? "min-h-[300px]" : ""
          }`}
        ></div>
        {visibilityModalOpen && (
          <ProfileVisibilityModal isOpen={setVisibilityModalOpen} />
        )}
        {/* <CreateTestimonial /> */}
      </div>
    </>
  );
};
