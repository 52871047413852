import React from "react";
import { UserCtx } from "../../context/userContext";
import { CommonFunctionCtx } from "../../context/commonFunctionContext";
import { ContactRequests } from "../../api/app.service";
import { Button } from "../ui/button";
import useLoading from "../../hooks/useLoading";
import Loader from "../ui/loader";
import { SvgClose } from "../icons";
import ToClientImage from "../../assets/images/marketplace/MailToClient.png";
import ToCoachImage from "../../assets/images/marketplace/MailToCoach.png";

interface Props {
  setShowConfirmInviteModal: any;
  invites: any;
  setInvites: any;
  getContacts: any;
  contacts: any;
}

const ConfirmContactInvite: React.FC<Props> = ({
  setShowConfirmInviteModal,
  invites,
  setInvites,
  getContacts,
  contacts,
}) => {
  const [validInvites, setValidInvites] = React.useState<any>([]);
  const [isSubmit, setIsSubmit] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const { user } = React.useContext(UserCtx);
  const { renderError, renderSuccess } =
    React.useContext(CommonFunctionCtx);
  const { startLoading, stopLoading } = useLoading();
  const [submitObject, setSubmitObject] = React.useState<any>({
    invites: [],
    subject: "Connect with me on Zoee!",
    description: `Please log in to the Zoee coaching portal. It serves as a hub and central location for the coaching work we will do together.`,
  });

  const getMemberName = () => {
    if (user.firstName && user.lastName) {
      return `${user.firstName} ${user.lastName}`;
    } else {
      return user?.email;
    }
  };

  const [customMessage, setCustomMessage] = React.useState<string>("");
  const generalMessage =
    user?.activeProfile === "coach"
      ? `Hi, I'm a Coach ${user?.firstName} ${user?.lastName}. Let's connect on Zoee Client Portal`
      : `Hi, my name is ${getMemberName()}. Let's connect on Zoee Client Portal`;

  const submitInvitesForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmit(true);
    let emailArray: any[] = [];
    validInvites.forEach((invite: any) => emailArray.push(invite.email));
    setSubmitObject({ ...submitObject, invites: emailArray });
  };

  const closeAndReset = () => {
    setValidInvites([]);
    setInvites([{ name: "", email: "" }]);
    setShowConfirmInviteModal(false);
    getContacts();
  };

  const removeInvite = (invite: any) => {
    const inviteCount = validInvites.length;
    if (inviteCount <= 1) {
      closeAndReset();
    } else {
      const filtered = validInvites.filter((item: any) => {
        return invite.email !== item.email;
      });
      setValidInvites(filtered);
      setInvites(filtered);
    }
  };

  // Send invite function
  const sendInvites = () => {
    if (user?.coachProfile || user?.memberProfile) {
      setLoading(true);
      startLoading();

      const existingContacts = contacts
        .filter((contact: any) => contact.contact_type !== "prospect")
        .map((contact: any) => contact.email?.toLocaleLowerCase());

      const inviteEmails = validInvites.map((invite: any) =>
        invite.email?.toLocaleLowerCase()
      );

      if (
        inviteEmails.some((email: string) => existingContacts.includes(email))
      ) {
        renderError("The email you entered is already in your contacts.");
        setLoading(false);
        stopLoading();
        setIsSubmit(false);
      } else {
        ContactRequests.createContactRequest({
          profile_id: user.activeProfileId,
          contact_requests: inviteEmails,
          description: submitObject.description,
          subject: submitObject.subject,
          custom_message: customMessage || generalMessage,
          avatar_url: user?.avatar_url,
          sender_email: user?.email,
          has_avatar: user?.hasAvatar,
        })
          .then(() => {
            setLoading(false);
            closeAndReset();
            renderSuccess("Invites sent successfully!");
          })
          .catch((ex) => {
            console.log(ex);
            if (ex.response.data.error_detail) {
              renderError(ex.response.data.error_detail);
            } else {
              renderError(ex.response.data.message);
            }
            stopLoading();
          });
      }
    }
    setIsSubmit(false);
  };

  React.useEffect(() => {
    if (isSubmit) {
      sendInvites();
    }
  }, [submitObject]);

  React.useEffect(() => {
    const filledInvites = invites.filter((invite: { email: string }) => {
      const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/;
      return invite.email.match(mailformat);
    });
    setValidInvites(filledInvites);
  }, []);

  return (
    <div className="fixed top-0 left-0 w-screen h-screen pb-[120px] zoee-backdrop-filter z-[801] flex items-center justify-center">
      <form
        action="#"
        onSubmit={submitInvitesForm}
      >
        <div
          className="py-[16px] sm:py-[32px] px-[24px] sm:px-[48px] w-[351px] sm:w-auto bg-white 
                    main-shadow rounded-[16px] flex flex-col items-center relative mt-[50px] md:mt-[100px]"
        >
          <SvgClose
            className="absolute top-[24px] right-[24px] cursor-pointer"
            onClick={closeAndReset}
          />
          <h2 className="text-[18px] md:text-[22px] font-bold mb-[24px]">
            Send Invite
          </h2>
          <div className="w-[298px] md:w-[604px] max-h-[450px] overflow-y-auto pb-[16px]">
            <div className="w-full bg-[#f8f8f8] p-[16px] rounded-[14px] flex flex-col sm:flex-row mb-[24px]">
              <h5 className="font-bold text-base mb-[8px] sm:mr-[10px]">To:</h5>
              <div className="flex flex-wrap w-full gap-[4px]">
                {validInvites.map((invite: any, index: any) => (
                  <div
                    className="py-[4px] px-[8px] bg-[#e8e7e4] flex items-center
                                        text-grayCharcoal text-base font-bold gap-[12px] rounded-[8px]"
                    key={index}
                  >
                    <span>{invite.email}</span>
                    <SvgClose
                      className="cursor-pointer"
                      onClick={() => removeInvite(invite)}
                    />
                  </div>
                ))}
              </div>
            </div>

            {/* Original static message for coach contact invite */}

            <p className="mb-[24px] font-bold">
              The provided email addresses will receive this contact request:
            </p>

            <div className="flex items-start gap-[16px] mb-[16px]">
              <div className="font-bold text-graySlate min-w-[80px] text-left">
                Subject:
              </div>
              {user?.activeProfile === "coach" ? (
                <p>{`Coach ${user?.firstName} ${user?.lastName} has invited you to connect on Zoee Client Portal!`}</p>
              ) : (
                <p>{`${getMemberName()} wants to connect with you on Zoee Client Portal!`}</p>
              )}
            </div>

            <div className="flex items-start gap-[16px] mb-[16px]">
              <div className="font-bold text-graySlate min-w-[80px] text-left">
                Message:
              </div>
              <div>
                <img
                  src={
                    user?.activeProfile === "coach"
                      ? ToClientImage
                      : ToCoachImage
                  }
                  alt="client invite"
                  className="mb-12 md:mb-0"
                />
                <div className="md:p-12 pb-12">
                  <div className="w-fit flex items-center gap-6 flex-col md:flex-row">
                    {user?.hasAvatar ? (
                      <img
                        src={user?.avatar_url}
                        alt="user avatar"
                        className="h-12 w-12 rounded-full flex-shrink-0"
                      />
                    ) : (
                      <div className="flex justify-center items-center rounded-full flex-shrink-0 h-12 w-12 border border-solid border-gray-500 bg-gradient-to-t from-[#BACDFF] to-background"><p className="font-bold text-white">ZOEE</p></div>
                    )}
                    {user?.activeProfile === "coach" ? (
                      <p className="text-base">
                        Coach <b>{`${user?.firstName} ${user?.lastName}`}</b>{" "}
                        has invited you to connect on{" "}
                        <b>Zoee, world-class coaching platform.</b>
                      </p>
                    ) : (
                      <p>
                        <b>{`${getMemberName()}`}</b> has requested to connect
                        with you on <b>Zoee Client Portal.</b>
                      </p>
                    )}
                  </div>
                </div>
                <div className="w-full flex items-center justify-center">
                  <Button disabled>Connect</Button>
                </div>
                <div style={{ marginBottom: "1em" }}></div>
              </div>
            </div>
            {/* Input field dynamic coach contact message // TODO: to be not available on basic plan */}
            {/* <p className="ml-auto mt-[16px] text-gray text-base">
              300 Characters
            </p>
            <div className="relative w-full h-[180px] md:h-[160px]">
              <textarea
                className="w-full h-full border
                                border-grayCloud rounded-[10px] focus:outline-none focus:border-royalBlue 
                                py-[32px] px-[20px] resize-none mt-[8px] text-graySlate focus:text-black"
                onChange={(e) => setCustomMessage(e.target.value)}
                name="description"
                value={customMessage}
                placeholder={`Create a custom message for your ${
                  user.activeProfile === "coach" ? "client" : "coach"
                }`}
              ></textarea>
            </div> */}
          </div>
          <div className="w-full flex items-center justify-center mt-[16px]">
            {loading ? (
              <Loader />
            ) : (
              <Button type="submit">
                {`Send Invite${validInvites.length > 1 ? "s" : ""}`}
              </Button>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default ConfirmContactInvite;
