import React from "react";
import { UserCtx } from "../../../context/userContext";
import { CoachPrivateProfileContext } from "../../../pages/manage-business/profile/context/coachPrivateProfileContext";
import { getTextColor } from "../../../utils/getTextColor";
import { Button } from "../../ui/button";
import { SvgEdit } from "../../icons/";

export default function AvatarImage() {
  const { user } = React.useContext(UserCtx);
  const { setEditPrimaryProfileVideo, coachPublicProfileData } = React
    .useContext(CoachPrivateProfileContext);

  return (
    <div className="w-full h-full rounded-[20px] group relative shadow-md">
      {user.hasAvatar
        ? (
          <img
            src={user.avatar_url}
            alt="avatar"
            className="w-full h-full rounded-[20px] object-cover"
          />
        )
        : (
          <div
            className={`flex items-center justify-center rounded-[20px] w-full h-full text-[32px] md:text-[40px] font-bold`}
            style={{
              backgroundColor:
                `${coachPublicProfileData?.profile_information?.profileColor}`,
              color: `${
                getTextColor(
                  coachPublicProfileData?.profile_information?.profileColor,
                )
              }`,
            }}
          >
            {coachPublicProfileData?.first_name?.charAt(0)}
          </div>
        )}
      <div className="hidden group-hover:flex w-full h-full z-2 bg-white bg-opacity-[0.8] rounded-[20px] absolute top-0 left-0 items-center justify-center">
        <Button
          variant={"utility"}
          onClick={() => setEditPrimaryProfileVideo(true)}
          className="bg-white border font-bold z-3 text-base flex items-center justify-center"
        >
          <SvgEdit />
          Edit
        </Button>
      </div>
    </div>
  );
}
