import * as React from "react";
import { Line } from "react-chartjs-2";
import type { Chart, ChartData } from "chart.js";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
} from "chart.js";
import Loader from "../ui/loader";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
);

type Props = {
    options: any;
    chartData: ChartData<"line">;
};

export default function LineChart({ chartData, options }: Props) {
    const chartRef = React.useRef<Chart<"line">>(null);

    const zoeeDefaultOptions = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
        },
        scales: {
            x: {
                position: "top",
                grid: {
                    display: false,
                    drawBorder: false,
                },
            },
            y: {
                grid: {
                    display: false,
                    drawBorder: false,
                },
            },
        },
        fill: false,
        borderColor: "#5DC388",
        tension: 0.3,
        spanGaps: true,
    };
    
    // TODO: The default options and additional options prop combine successfully, but it is not reflected in the rendered Chart
    const combinedOptions = { ...zoeeDefaultOptions, ...options };

    return (
        <div className="w-full h-full relative">
            <Line ref={chartRef} options={combinedOptions} data={chartData} />
            {!chartData && (
                <div className="absolute left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2">
                    <Loader />
                </div>
            )}
        </div>
    );
};
