import React, { useEffect } from "react";
import { SvgCheck } from "../../components/icons";

const IcfPasswordReqList = [
  "8 characters minimum",
  "At least 1 uppercase character",
  "At least 1 lowercase character",
  "At least 1 number",
  "At least 1 special character",
  "Password must match",
];

interface Props {
  validEntree: string;
  confirmPassword: string;
  bottomMargin?: string;
  setIsEntryValid?: (statement: boolean) => void;
}

const IcfPasswordRequirements: React.FC<Props> = ({
  validEntree,
  confirmPassword,
  bottomMargin,
  setIsEntryValid,
}) => {
  const validateEntree = (entree: string) => {
    const containsNumber = /\d/.test(entree);
    const containsSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(entree);
    const containsUppercase = /[A-Z]/.test(entree);
    const containsLowercase = /[a-z]/.test(entree);
    const entreeLength = entree.length >= 8;
    const passwordsMatch = entree === confirmPassword;

    return [
      entreeLength,
      containsUppercase,
      containsLowercase,
      containsNumber,
      containsSpecialChar,
      passwordsMatch,
    ];
  };

  useEffect(() => {
    if (setIsEntryValid) {
      setIsEntryValid(validateEntree(validEntree).every((el) => el === true));
    }
  }, [validEntree, confirmPassword]);

  const validationResults = validateEntree(validEntree);

  return (
    <div className={`p-[16px] mb-${bottomMargin} md:w-full`}>
      <p className="text-sm md:text-[18px] font-bold text-[#7f8184] mb-4">
        Password requirements:
      </p>
      <ul className="text-xs md:text-sm grid grid-cols-1 md:grid-cols-2 gap-x-4">
        {IcfPasswordReqList.map((item, idx) => (
          <li key={idx} className="flex items-center mb-2">
            <SvgCheck
              className={`mr-2 h-3 w-3 ${
                validationResults[idx] ? "text-[green]" : "text-[#7f8184]"
              }`}
            />
            <span
              className={`${
                validationResults[idx] ? "text-[green]" : "text-[#7f8184]"
              }`}
            >
              {item}
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default IcfPasswordRequirements;
