import React, { useState } from "react"
import Bernadette from "./Bernadette.jpg"
import BestValueBadge from "../../assets/images/capterra-badges/ca-value-2023.png"
import CustomerSupportBadge from "../../assets/images/capterra-badges/sa-customer_support-2023.png"
import IcfLogo from "../../assets/images/landing/icf-logo.png"
import MostRecommendedBadge from "../../assets/images/capterra-badges/sa-most_recommended-2023.png"
import { SvgZoeeIcon } from "../../components/icons"
import { Dialog, DialogContent, DialogFooter } from "../../components/ui/dialog"
import IcfRegistrationForm from "./IcfRegistrationForm"
import { RegisterFormProvider } from "../../components/auth/register-form/context"
import { Button } from "../../components/ui/button"
import { useNavigate } from "react-router-dom"

const badges: { src: string; alt: string }[] = [
  { src: MostRecommendedBadge, alt: "Badge: Most Recommended 2023" },
  { src: BestValueBadge, alt: "Badge: Best Value 2023" },
  { src: CustomerSupportBadge, alt: "Badge: Best Customer Support 2023" },
]

const IcfRegistrationPage = () => {

  const [ confModal, setConfModal ] = useState(false)
  const navigate = useNavigate()

  const onModalClose = () => {
    setConfModal(false)
    navigate('/coach')
  }

  return (
    <div className="min-h-screen flex flex-col md:flex-row items-center justify-center bg-gradient-to-t from-[#BACDFF] to-[#FFFFFF] p-4 overflow-x-hidden">
      <div className="w-full md:w-[50%] md:ml-10 sm:p-12">
        <div className="mb-6">
          <div className="h-12 w-12 md:h-16 md:w-16 mb-6 flex items-center justify-center">
            <SvgZoeeIcon className="h-full w-full" />
          </div>
          <p className="text-3xl md:text-5xl font-bold">
            The All-in-One Solution for ICF Coaches:{" "}
            <span className="text-blurple">Less Hassle, More Impact</span>
          </p>
          <p className="text-[24px] text-muted-foreground mt-2">
            Join hundreds of coaches & consultants who trust Zoee to run their
            business.
          </p>
        </div>
        <div className="xsm:block flex items-stretch mt-8 relative">
          <div className="bg-blurple w-1 mr-4 absolute top-0 bottom-0 my-auto h-[85%]"></div>
          <p className="text-base ml-6">
            “Zoee is an amazing platform for coaches to spend more time doing
            what they LOVE - coach their clients and less time on the calendar
            worrying about invoicing and scheduling overlaps.”
          </p>
        </div>
        <div className="mt-2 flex">
          <div className="hidden xsm:block h-12 w-12 md:h-[60px] md:w-[60px] overflow-hidden">
            <img
              className="object-cover object-top h-full w-full rounded-full"
              src={Bernadette}
              alt="Professional Coach Testimony"
            />
          </div>
          <div className="hidden xsm:block ml-4">
            <p className="text-lg md:text-xl font-bold">Bernadette R.</p>
            <p>Professional Coach</p>
          </div>
        </div>
        <div className="-ml-2 mt-16 flex items-center h-12 md:h-16 gap-2 ">
          {badges.map(({ src, alt }) => (
            <img key={src} src={src} alt={alt} className="h-full" />
          ))}
        </div>
        <div className="-ml-2 mt-4 flex items-center h-12 md:h-16">
          <img className="h-full" src={IcfLogo} alt="icflogo" />
        </div>
      </div>
      <div className="w-full p-4 mt-8 md:mt-0 md:w-[50%] flex justify-center md:justify-start">
        <RegisterFormProvider
          defaultEmail=""
          defaultAccountType={"coach"}
          defaultPhone=""
          defaultFirstName=""
          defaultLastName=""
          defaultPassword=""
          onSuccess={() => true}
        >
          <IcfRegistrationForm setConfModal={setConfModal}/>
        </RegisterFormProvider>
        <Dialog open={confModal} onOpenChange={onModalClose}>
          <DialogContent>
            <h4 className="text-center text-xl font-bold">Congratulations!</h4>
            <p className="text-center">You unlocked your 50% OFF ICF Discount with Zoee!</p>
            <p className="text-center">CODE: <b>ICFMEMBER2024</b></p>
            <DialogFooter className="md:justify-center gap-y-4 mt-4">
              <Button
                onClick={() => navigate('/coach')}
                variant="secondary"
                className="md:w-[155px]">OK</Button>
              <Button
                onClick={() => navigate('/icf/registration')}
                autoFocus
                variant="default"
                className="md:w-[155px]">Use Discount Now
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  )
}

export default IcfRegistrationPage
