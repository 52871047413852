import React from "react"
import InfoModal from "../InfoModal"
import useName from "../../hooks/useName"
import { Avatar, AvatarFallback, AvatarImage } from "../ui/avatar"
import { Button } from "../ui/button"
import { CommonFunctionCtx } from "../../context/commonFunctionContext"
import { getUserColor } from "../../utils/getUserColor"
import { getTextColor } from "../../utils/getTextColor"
import { Link } from "react-router-dom"
import { selectPeers, useHMSStore } from "@100mslive/react-sdk"
import {
  SvgClose,
  SvgHelp,
  SvgInvite,
  SvgLogout,
  SvgSettings,
  SvgStar,
  SvgUser,
} from "../icons/"
import { useAuthModal } from "../auth/use-auth-modal"
import { UserCtx } from "../../context/userContext"
import { UserInfo } from "../../api/app.service"

interface Props {
  setShowUserButton: any
  showUserButton: boolean
}

const UserButtonMobile: React.FC<Props> = ({
  setShowUserButton,
  showUserButton,
}) => {
  const { user } = React.useContext(UserCtx)
  const { renderError, previewImgUrl } = React.useContext(CommonFunctionCtx)

  // Hooks
  const { initial, username } = useName()
  const { logout } = useAuthModal()
  const peers = useHMSStore(selectPeers)

  // State
  const [nextStepsData, setNextStepsData] = React.useState<any>({})
  const [, setDisabledSwitchAccount] = React.useState<boolean>(false)
  const [showReferCoachComingSoon, setShowReferCoachComingSoon] =
    React.useState<boolean>(false)

  const isFoundingCoach = localStorage.getItem("isFoundingCoach") === "true"

  // Functions
  const userInActiveSession =
    peers.filter((peer) => {
      const id = user.activeProfileId
      return peer.customerUserId === id.toString()
    }).length > 0

  const getNextStepsValues = async () => {
    await UserInfo.getNextStepsValues({
      user_id: parseInt(user.user_id),
    })
      .then((data) => {
        // GET RID OF `OR` condition on master push
        setNextStepsData(data.next_steps_data)
      })
      .catch((ex) => {
        console.log(ex)
        renderError(ex.response.data.message)
      })
  }

  // useEffects
  React.useEffect(() => {
    if (userInActiveSession) {
      setDisabledSwitchAccount(true)
    }
  }, [peers])

  React.useEffect(() => {
    getNextStepsValues()
  }, [])

  return (
    <>
      {showReferCoachComingSoon && (
        <InfoModal
          setShowModal={setShowReferCoachComingSoon}
          header={`Exciting News! 🎉`}
          helpText={`This feature is currently in the works and will be coming soon.`}
          confirmButtonText="OK"
          isWarningVariant={false}
        />
      )}
      {showUserButton && (
        <div className="fixed top-0 left-0 w-screen h-screen zoee-backdrop-filter z-[601]"></div>
      )}
      <div
        className={`flex flex-col bg-white min-h-screen rounded-lg fixed top-0 right-0 bottom-0 bg-white p-8 overflow-y-auto overflow-x-hidden transition-all duration-500 ease-in-out ${
          showUserButton ? "w-[279px] right-0" : "w-0 right-[-279px]"
        } z-[1000]`}
        style={{
          boxShadow:
            "-5px 0 15px -2px rgba(111, 89, 246, 0.15), -5px 0 15px -2px rgba(111, 89, 246, 0.15), -5px 0 15px -2px rgba(111, 89, 246, 0.1)",
        }}
        onClick={() => setShowUserButton(!showUserButton)}
      >
        <Button
          className="absolute right-[20px] top-[25px] rounded-full bg-grayFlash"
          size="icon"
          variant="ghost"
          onClick={() => setShowUserButton(() => !showUserButton)}
        >
          <SvgClose />
        </Button>

        <div className="w-[238px] py-4 rounded-lg pr-5">
          <div className="flex items-center gap-4 px-3.5 py-2">
            <div className="flex-shrink-0">
              <Avatar size="sm">
                {Boolean(user.hasAvatar) && (
                  <AvatarImage
                    alt="avatar"
                    src={previewImgUrl || user.avatar_url}
                  />
                )}
                <AvatarFallback className={getUserColor(user.userColor)}>
                  <div
                    style={{
                      color: getTextColor(user.userColor),
                    }}
                  >
                    {initial}
                  </div>
                </AvatarFallback>
              </Avatar>
            </div>
            <div className="flex flex-col">
              <span className="capitalize text-muted-foreground font-[600]">
                {user.activeProfile}
              </span>
              <span className="text-lg text-ellipsis font-bold overflow-hidden whitespace-nowrap w-36">
                {username}
              </span>
            </div>
          </div>

          {user.isCoach === true && (
            <div className="flex flex-col gap-[5px]">
              <hr className="my-2" />
              {nextStepsData?.profile_access_status === "approved" && (
                <Link
                  to="/coach/profile"
                  className="cursor-pointer text-blurple flex items-center rounded-md hover:bg-hover hover:text-black px-3.5 py-2"
                >
                  <SvgUser className="mr-4 h-6 w-6" />
                  <span className="font-[600]">View my Profile</span>
                </Link>
              )}
              <Link
                to="/coach/subscription"
                className="cursor-pointer flex items-center rounded-md hover:bg-hover hover:text-black px-3.5 py-2"
              >
                <SvgStar className="mr-4 w-6 h-6" />
                <span className="font-[600]">My Subscription</span>
              </Link>
              <Link
                to="/affiliate"
                className="cursor-pointer flex items-center rounded-md hover:bg-hover hover:text-black px-3.5 py-2"
              >
                <SvgInvite className="mr-4 h-6 w-6" />
                <span className="font-[600]">Become an Affiliate</span>
              </Link>
            </div>
          )}

          <hr className="my-2" />
          <div className="flex flex-col gap-[5px]">
            <Link
              to="/account-settings"
              className="cursor-pointer flex items-center rounded-md hover:bg-hover hover:text-black px-3.5 py-2"
            >
              <SvgSettings fill="black" className="mr-4 w-6 h-6" />
              <span className="font-[600]">Account settings</span>
            </Link>

            <a
              href="https://intercom.help/zoee/en"
              target="_blank"
              rel="noreferrer"
              className="cursor-pointer flex items-center rounded-md hover:bg-hover hover:text-black px-3.5 py-2"
            >
              <SvgHelp className="mr-4 h-6 w-6" />
              <span className="font-[600]">Help</span>
            </a>
          </div>
          {user.isCoach && (
            <>
              <hr className="my-2" />
              <div className="flex flex-col gap-[5x] px-3.5"></div>
            </>
          )}

          <hr className="my-2" />

          <div
            className="cursor-pointer text-vividRed flex items-center rounded-md hover:bg-hover hover:text-black px-3.5 py-2"
            onClick={logout}
          >
            <SvgLogout className="mr-4 h-6 w-6" />
            <span className="font-[600]">Log out</span>
          </div>
        </div>
      </div>
    </>
  )
}

export default UserButtonMobile
