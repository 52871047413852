import React from "react";
import { SvgCheckCircle } from "../../icons";

interface Props {
  service: any;
  serviceUsagePeriods: any;
}

const ServiceUsageSummary: React.FC<Props> = ({
  service,
  serviceUsagePeriods,
}) => {
  const getPlusMinusBackground = (variance: number) => {
    if (variance < 0) {
      return "bg-vividOrange";
    } else if (variance > 0) {
      return "bg-vividCyanGreen";
    } else {
      return "";
    }
  };

  const getPeriodPlusMinus = (period: any) => {
    const completedPlusScheduled =
      parseInt(period?.completed_count) + parseInt(period?.scheduled_count);
    const variance =
      parseInt(period?.target_session_count) - completedPlusScheduled;
    return (
      <div
        className={`${variance === 0 ? "text-black" : "text-white"} font-bold 
                w-[24px] h-[24px] flex mx-auto items-center justify-center 
                rounded-[8px] ${getPlusMinusBackground(variance)}`}
      >
        {variance === 0 ? "--" : variance}
      </div>
    );
  };

  const getTotalPlusMinus = () => {
    const completedPlusScheduled =
      parseInt(service?.redeemed_session_count) +
      parseInt(service?.scheduled_session_count);
    const variance =
      parseInt(service?.total_session_count) - completedPlusScheduled;
    return (
      <div
        className={`${variance === 0 ? "text-black" : "text-white"} font-bold 
                w-[24px] h-[24px] flex mx-auto items-center justify-center 
                rounded-[8px] ${getPlusMinusBackground(variance)}`}
      >
        {variance === 0 ? "--" : variance}
      </div>
    );
  };

  return (
    <>
      {/* STAT SUMMARY */}
      <div className="w-full flex items-start justify-center gap-[10px] md:gap-[18px] mb-[24px] md:mb-[36px]">
        {/* Sessions completed */}
        <div
          className="flex w-[72px] md:w-[144px] py-[4px] md:border
                    md:border-grayCloud justify-center items-center"
        >
          <div className="md:w-[100px] pt-[4px] text-center md:text-left flex flex-col gap-[4px]">
            <span className="font-bold text-[24px] md:text-[40px] leading-none">
              {service?.redeemed_session_count}
            </span>
            <span className="font-semibold text-base md:text-base text-grayCharcoal">
              Completed
            </span>
          </div>
        </div>
        {/* Sessions scheduled */}
        <div
          className="flex w-[72px] md:w-[144px] py-[4px] md:border
                    md:border-grayCloud justify-center items-center"
        >
          <div className="md:w-[100px] pt-[4px] text-center md:text-left flex flex-col gap-[4px]">
            <span className="font-bold text-[24px] md:text-[40px] leading-none">
              {service?.scheduled_session_count}
            </span>
            <span className="font-semibold text-base md:text-base text-grayCharcoal">
              Scheduled
            </span>
          </div>
        </div>
        {/* Sessions unused */}
        <div
          className="flex w-[72px] md:w-[144px] py-[4px] md:border
                    md:border-grayCloud justify-center items-center"
        >
          <div className="md:w-[100px] pt-[4px] text-center md:text-left flex flex-col gap-[4px]">
            <span className="font-bold text-[24px] md:text-[40px] leading-none">
              {service?.available_session_count}
            </span>
            <span className="font-semibold text-base md:text-base text-grayCharcoal">
              Unused
            </span>
          </div>
        </div>
        {/* Sessions total */}
        <div
          className="flex w-[72px] md:w-[144px] py-[4px] md:border
                    md:border-grayCloud justify-center items-center"
        >
          <div className="md:w-[100px] pt-[4px] text-center md:text-left flex flex-col gap-[4px]">
            <span className="font-bold text-[24px] md:text-[40px] leading-none">
              {service?.total_session_count}
            </span>
            <span className="font-semibold text-base md:text-base text-grayCharcoal">
              Total Sessions
            </span>
          </div>
        </div>
      </div>
      {/* SUMMARY TABLE */}
      <table className="w-full">
        <thead>
          {/* HEADER ROW */}
          <tr className="text-graySlate text-base">
            <th className="text-left pb-[8px]">PERIOD</th>
            <th className="pb-[8px]">COMPLETED</th>
            <th className="pb-[8px] border-r-[1px] border-graySlate">
              SCHEDULED
            </th>
            <th className="pb-[8px]">TARGET</th>
            <th className="pb-[8px]">+ / -</th>
          </tr>
        </thead>

        <tbody>
          {service?.service_details?.type === "recurring" && (
            <>
              {serviceUsagePeriods.map((period: any) => (
                // DATA ROWS FOR RECURRING PERIODS
                <tr className="text-base border-b-[1px] border-graySlate">
                  <td className="text-left pb-[8px]">
                    <span
                      className={`flex items-center gap-[8px] 
                                            ${
                                              period.current_period
                                                ? "text-primaryBlue"
                                                : ""
                                            }`}
                    >
                      {period?.period_name}
                      {period.current_period && (
                        <SvgCheckCircle
                          stroke="blue"
                          className="w-[16px] h-[16px]"
                        />
                      )}
                      {period.past_period && (
                        <SvgCheckCircle className="w-[16px] h-[16px]" />
                      )}
                    </span>
                  </td>
                  <td className="text-center pb-[8px]">
                    {period?.completed_count}
                  </td>
                  <td className="text-center pb-[8px] border-r-[1px] border-graySlate">
                    {period?.scheduled_count}
                  </td>
                  <td className="text-center pb-[8px]">
                    {period?.target_session_count}
                  </td>
                  <td className="text-center pb-[8px]">
                    {getPeriodPlusMinus(period)}
                  </td>
                </tr>
              ))}
            </>
          )}
          {/* SUMMARY ROW FOR ALL TYPES */}
          <tr className="text-black text-base font-bold">
            <td className="pt-[6px]">Total Sessions</td>
            <td className="text-center pt-[6px]">
              {service?.redeemed_session_count}
            </td>
            <td className="text-center pt-[6px] border-r-[1px] border-graySlate">
              {service?.scheduled_session_count}
            </td>
            <td className="text-center pt-[6px]">
              {service?.total_session_count}
            </td>
            <td className="pt-[6px]">{getTotalPlusMinus()}</td>
          </tr>
        </tbody>
      </table>
      {service?.service_details?.type === "recurring" && (
        <div className="ml-auto flex items-center gap-[12px] mt-[30px]">
          <div className="text-base flex items-center gap-[4px] mx-auto text-primaryBlue">
            <SvgCheckCircle
              stroke="blue"
              className="w-[16px] h-[16px]"
            />
            Current period
          </div>
          <div className="text-base flex items-center gap-[4px] mx-auto">
            <SvgCheckCircle className="w-[16px] h-[16px]" />
            Completed period
          </div>
        </div>
      )}
    </>
  );
};

export default ServiceUsageSummary;
