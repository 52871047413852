import React, { useEffect, useState } from "react";
import { ProfileRequests } from "../../../api/app.service";
import { AllTestimonials } from "../../../models/app.interface";
import { CommonFunctionCtx } from "../../../context/commonFunctionContext";
import Loader from "../../ui/loader";
import { Switch } from "../../ui/switch";
import { Label } from "@radix-ui/react-label";

interface Props {
  isEditable?: boolean;
  coachPublicProfileData: any;
}

const ProfileTestimonialTab: React.FC<Props> = ({
  isEditable = true,
  coachPublicProfileData,
}) => {
  const { renderError, renderSuccess } = React.useContext(CommonFunctionCtx);
  const [testimonials, setTestimonials] = useState<AllTestimonials[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [hoveredIndex, setHoveredIndex] = React.useState<any>(null);

  const getCoachTestimonials = async () => {
    setIsLoading(true);
    const data = await ProfileRequests.getTestimonials({
      coach_public_profile_id: coachPublicProfileData?.coach_public_profile_id,
    })
      .then((data: any) => {
        setTestimonials(data.testimonials);
        setIsLoading(false);
      })
      .catch((ex: any) => {
        console.log(ex);
        renderError(ex.response.data.message);
      });
  };

  const saveTestimonialVisibility = (testimonial: any, index: number) => {
    const updatedTestimonial = [...testimonials];
    updatedTestimonial[index].is_shown = !testimonial.is_shown;

    ProfileRequests.editTestimonials({
      id: testimonial.id,
      is_shown: updatedTestimonial[index].is_shown,
      is_bento_choice: false,
    })
      .then((data: any) => {
        setIsLoading(false);
        setTestimonials(updatedTestimonial);
      })
      .catch((ex) => {
        console.log(ex);
        renderError(ex.response?.data?.message || "Error updating testimonial");
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getCoachTestimonials();
  }, [coachPublicProfileData]);

  return (
    <>
      {isLoading ? (
        <div className="flex h-96 w-full items-start justify-center">
          <Loader />
        </div>
      ) : isEditable ? (
          <div className="flex w-full flex-wrap items-center justify-start gap-4">
            {testimonials.length === 0 ? (
          <div className="flex flex-col items-center gap-4">
            <div className="text-graySlate">No testimonials</div>
          </div>
        ) : (
          testimonials.map((testimonial, index) => (
            <div
              key={index}
              onMouseEnter={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(null)}
              className={`shadow-custom hover:scale-102 h-[300px] w-[387px] transform rounded-[16px] transition-transform ${
                !testimonial.is_shown ? "text-gray" : ""
              }`}
            >
              <div
                className={`flex h-full w-full flex-col justify-start p-6 py-8 ${
                  hoveredIndex === index ? "opacity-75" : ""
                }`}
              >
                <p className="text-md italic">"{testimonial?.testimonial}"</p>
                <p className="ml-2 mt-2 font-bold">
                  -{" "}
                  {testimonial?.client_name
                    ? testimonial?.client_name
                    : "Zoee member"}
                </p>
              </div>
              {index === hoveredIndex ? (
                <div
                  className="absolute right-[10px] top-[260px] text-black"
                  onClick={() => saveTestimonialVisibility(testimonial, index)}
                >
                  <Label
                    className="mr-2"
                    htmlFor={`testimonial-visible-${index}`}
                  >
                    Visible (public)
                  </Label>
                  <Switch
                    id={`testimonial-visible-${index}`}
                    checked={testimonial.is_shown}
                  />
                </div>
              ) : (
                <></>
              )}
            </div>
          )))}
        </div>
      ) : (
        <div className="flex w-full flex-wrap items-start justify-start gap-4">
          {testimonials
            ?.filter((testimonial) => testimonial.is_shown)
            .map((testimonial, index) => (
              <div
                key={index}
                className="shadow-custom hover:scale-102 h-[300px] w-[387px] transform rounded-[16px] transition-transform"
              >
                <div className="flex h-full w-full flex-col justify-start p-6 py-8">
                  <p className="text-md flex-1 italic">
                    "{testimonial?.testimonial}"
                  </p>
                  <p className="ml-2 mt-2 text-xs font-medium">
                    {testimonial?.client_name
                      ? testimonial?.client_name
                      : "Zoee member"}
                  </p>
                </div>
              </div>
            ))}
        </div>
      )}
    </>
  );
};

export default ProfileTestimonialTab;
