import React, { useState } from "react"
import PricingPlanCard from "../../pages/(marketing)/pricing/PricingPlanCard"
import { SubscriptionContext } from "../../pages/subscription/context/subscriptionContext"
import { SubscriptionRequests } from "../../api/app.service"
import { UserCtx } from "../../context/userContext"
import useLoading from "../../hooks/useLoading"
import { CommonFunctionCtx } from "../../context/commonFunctionContext"

const MarketingPlans: React.FC = () => {
  const [selectedPlan, setSelectedPlan] = useState<"monthly" | "annual">(
    "annual"
  )
  const { renderError } =
  React.useContext(CommonFunctionCtx)


  const [hideSecondaryNav, setHideSecondaryNav] = useState(false)
  const [showSubscription, setShowSubscription] = useState(true)
  const [showReceipts, setShowReceipts] = useState(false)
  const [trialActive, setTrialActive] = useState(false)
  const [paymentMethods, setPaymentMethods] = useState([])
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null)
  const [paymentMethodModal, setPaymentMethodModal] = useState(null)
  const [subscription, setSubscription] = useState(null)
  const [showSubscriptionPlans, setShowSubscriptionPlans] = useState(false)
  const [showSubscriptionCheckout, setShowSubscriptionCheckout] =
    useState(false)
  const [createSetupIntent, setCreateSetupIntent] = useState(null)
  const [options, setOptions] = useState(null)
  const [selectedSubscription, setSelectedSubscription] = useState(null)
  const [plans, setPlans] = useState([])
  const [isSubscriptionUpdate, setIsSubscriptionUpdate] = useState(false)
  const [getSubscription, setGetSubscription] = useState(() => () => {})
  const [delayedGetPaymentMethods, setDelayedGetPaymentMethods] = useState(
    () => () => {}
  )
  const [assignSelectedPaymentMethod, setAssignSelectedPaymentMethod] =
    useState(() => () => {})
  const [stripeRedirectParams, setStripeRedirectParams] = useState(null)
  const [showSubscriptionComponents, setShowSubscriptionComponents] =
    useState<any>(null)

  const handleChoosePlanClick = (planType: string) => {
    console.log(`Selected plan: ${planType}`)
  }
  const { user } = React.useContext(UserCtx)
  const { startLoading, stopLoading } = useLoading()


  const SubscriptionContextValues = {
    hideSecondaryNav,
    setHideSecondaryNav,
    showSubscription,
    setShowSubscription,
    showReceipts,
    setShowReceipts,
    trialActive,
    paymentMethods,
    setPaymentMethods,
    selectedPaymentMethod,
    setSelectedPaymentMethod,
    paymentMethodModal,
    setPaymentMethodModal,
    subscription,
    setSubscription,
    showSubscriptionPlans,
    setShowSubscriptionPlans,
    showSubscriptionCheckout,
    setShowSubscriptionCheckout,
    showSubscriptionComponents,
    setShowSubscriptionComponents,
    createSetupIntent,
    options,
    setOptions,
    selectedSubscription,
    setSelectedSubscription,
    plans,
    setPlans,
    isSubscriptionUpdate,
    setIsSubscriptionUpdate,
    getSubscription,
    delayedGetPaymentMethods,
    assignSelectedPaymentMethod,
    stripeRedirectParams,
    setStripeRedirectParams,
  }

  const grabSubscription = async () => {
    startLoading()
    await SubscriptionRequests.getActiveSubscription({
      user_id: parseInt(user.user_id),
    })
      .then((data: any) => {
        if (Object.keys(data.active_subscription).length !== 0) {
          setSubscription(data.active_subscription)
        } else {
          setSubscription(null)
        }
      })
      .catch((ex) => {
        console.log(ex)
        renderError(ex.response.data.message)
      })
      .finally(() => {
        stopLoading()
      })
  }
  
  React.useEffect(() => {
    if (user?.user_id) {
      grabSubscription()
      // console.log("sub dataaa: ", subscription)
    }
  }, [user])

  return (
    <SubscriptionContext.Provider value={SubscriptionContextValues}>
      <div className="h-full flex flex-col items-center">
        <div className="relative flex justify-center items-center w-full max-w-xs rounded-[20px] border-2 border-black h-[50px] p-1">
          <button
            onClick={() => setSelectedPlan("monthly")}
            className={`w-[40%] h-full rounded-[18px] ${
              selectedPlan === "monthly"
                ? "bg-[black] text-[white]"
                : "bg-white text-blue-500"
            }`}
          >
            Monthly
          </button>
          <button
            onClick={() => setSelectedPlan("annual")}
            className={`w-[60%] h-full rounded-[18px] ${
              selectedPlan === "annual"
                ? "bg-[black] text-[white]"
                : "bg-white text-blue-500"
            }`}
          >
            Annual (Save 16%)
          </button>
        </div>

        <div className="mt-8 flex flex-col sm:flex-row w-full justify-center items-center xsm:gap-8 sm:px-6 ">
          <PricingPlanCard
            planType="freeTrial"
            handleChoosePlanClick={handleChoosePlanClick}
          />
          <PricingPlanCard
            planType={selectedPlan}
            handleChoosePlanClick={handleChoosePlanClick}
          />
        </div>
      </div>
    </SubscriptionContext.Provider>
  )
}

export default MarketingPlans
