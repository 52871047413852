import React from "react";
import { motion } from "framer-motion";
import CloseModal from "../CloseModal";
import { UserExists } from "../../api/auth.service";
import { CommonFunctionCtx } from "../../context/commonFunctionContext";
import useLoading from "../../hooks/useLoading";
import { SvgWarning } from "../icons";

type Props = {
    setUserEmailCheck: any;
    goToEnroll: any;
};

const UserEmailCheckModal: React.FC<Props> = ({
    setUserEmailCheck,
    goToEnroll
}) => {
    const { renderError } = React.useContext(CommonFunctionCtx);
    const { startLoading, stopLoading } = useLoading();
    const [values, setValues] = React.useState<any>({
        email: "",
    });
    const [formErrors, setFormErrors] = React.useState<any>({});
    const [isSubmit, setIsSubmit] = React.useState<boolean>(false);
    const inputReference = React.useRef<any>(null);

    const handleChange = (e: React.FormEvent<EventTarget>) => {
        e.preventDefault();
        let target = e.target as HTMLInputElement;
        setValues({ ...values, [target.name]: target.value });
    };

    const validateForm = (values: any) => {
        const errors: any = {};
        var mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/;
        const match = values.email.match(mailformat);
        (!values.email || !match) && (errors.email = "Valid email is required");
        return errors;
    };

    const handleSubmit = (e: React.FormEvent<EventTarget>) => {
        e.preventDefault();
        setFormErrors(validateForm(values));
        setIsSubmit(true);
    };

    React.useEffect(() => {
        if (isSubmit && Object.keys(formErrors).length === 0) {
            startLoading();
            UserExists.verifyUserExists({
                attributes_to_verify: {
                    email: values.email.toLowerCase(),
                },
            })
                .then((data:any) => {
                    stopLoading()
                    if (data.user_found) {
                        const emailObject = {
                            email: values.email,
                            first_name: data.first_name,
                        };
                        goToEnroll("login", emailObject);
                    } else {
                        const emailObject = {
                            email: values.email
                        };
                        goToEnroll("signup", emailObject);
                    }
                })
                .catch((ex) => {
                    renderError(ex.response.data);
                });
        } else {
            setIsSubmit(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formErrors]);

    React.useEffect(() => {
        inputReference?.current?.focus();
    }, []);

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
            className="fixed w-screen h-screen top-0 left-0 zoee-backdrop-filter z-[1001] flex items-center justify-center pb-[72px] md:pb-[40px]"
        >
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5 }}
                className={`relative overflow-hidden flex flex-col items-center bg-white rounded-[16px] h-auto py-[80px] px-[40px]`}
            >
                <CloseModal callback={setUserEmailCheck} styling="absolute top-[24px] right-[24px]" />
                <form action="#" onSubmit={handleSubmit}>
                    <h5 className="font-bold text-center mb-[24px] text-[20px]">Enter Email</h5>
                    <div className="mb-[24px]">
                        <div className={`default-input ${formErrors?.email && "input-error"}`}>
                            <input
                                type="text"
                                placeholder="Enter your email to get started"
                                onChange={handleChange}
                                name="email"
                                autoComplete="off"
                                ref={inputReference}
                            />
                            <div className="overlay">Email</div>
                        </div>
                        {formErrors.email && (
                            <div className="field-error">
                                <SvgWarning />
                                {formErrors.email}
                            </div>
                        )}
                    </div>
                    <div className="flex justify-center">
                        <input
                            type="submit"
                            className="btn-primary"
                            value="Continue"
                        />
                    </div>
                </form>
            </motion.div>
        </motion.div>
    );
};

export default UserEmailCheckModal;
