import React, { useState } from "react";
import { CoachPrivateProfileContext } from "../../../pages/manage-business/profile/context/coachPrivateProfileContext";
import { Button } from "../../ui/button";
import Loader from "../../ui/loader";
import { SvgEdit } from "../../icons";
import BentoSelectedContent from "./BentoSelectedContent";

interface Props {
  endpointSlug: any;
  setEditBento?: any;
  coachData: any;
  coachColor: any;
  setBentoBoxSelection?: any;
  editBento?: boolean;
  setActiveTab?: any;
}
type BoxType = "quote" | "video" | "blog" | "testimonials" | "image";

export default function BentoBoxThree({ setEditBento, coachData, coachColor, setBentoBoxSelection, endpointSlug, setActiveTab }: Props) {
  const [boxType, setBoxType] = useState<BoxType>();
  const { loadingBento } = React.useContext(CoachPrivateProfileContext);
  const pathname = window.location.pathname;
  let isPublicProfileView = false;
  if (pathname === `/find-a-coach/${endpointSlug}`) {
    isPublicProfileView = true;
  }

  return (
    <>
      {loadingBento ? (
        <>
          <Loader />
        </>
      ) : (
        <div className="relative h-[460px] w-[300px] group">
          {<BentoSelectedContent
            coachData={coachData}
            endpointSlug={endpointSlug}
            coachColor={coachColor}
            bentoBoxNumber={"three"}
            setBoxType={setBoxType}
            isPublicProfileView={isPublicProfileView}
            boxType={boxType}
            setActiveTab={setActiveTab}
          />}

          {!isPublicProfileView && (
            <Button
              className="absolute right-2 top-2 z-[20] invisible group-hover:visible"
              variant={"utility"}
              size={"sm"}
              onClick={() => {
                setEditBento(true);
                setBentoBoxSelection(3);
              }}
            >
              <SvgEdit />
              Edit
            </Button>
          )}
        </div>
      )}
    </>
  );
};
