import React, { useEffect, useState } from "react"
import AttendeesView from "./AttendeesView"
import ContactAvatar from "../../cards/ContactAvatar"
import moment from "moment"
import NewMeeting from "../new-meeting/NewMeeting"
import ServiceCardPreviewWide from "../../services/ServiceCardPreviewWide"
import StatusIcon from "../MeetingStatusIcon"
import useLoading from "../../../hooks/useLoading"
import useScrollToTop from "../../../hooks/useScrollToTop"
import { Button } from "../../ui/button"
import { CommonFunctionCtx } from "../../../context/commonFunctionContext"
import { Meeting } from "../../../types/meetingTypes"
import { MeetingRequests } from "../../../api/app.service"
import { SvgNotifications, SvgVideoSolid, SvgZoeeIcon } from "../../icons"
import { useLocation, useNavigate } from "react-router-dom"
import { UserCtx } from "../../../context/userContext"

type Props = {
  meeting: Meeting | undefined
  setHideBackToLink: any
  setActiveSessionDetailViewId: any
  getMeetings: any
  setNewMeetingType: any
  showRescheduleMeeting: boolean
  setShowRescheduleMeeting: any
  clickReschedule: boolean
  setClickReschedule: any
  clickTodayReschedule: boolean
  backToContacts: boolean
  contact: any
  keepBackToButton: boolean
}

const ZoeeSessionDetailView: React.FC<Props> = ({
  meeting,
  setHideBackToLink,
  setActiveSessionDetailViewId,
  getMeetings,
  setNewMeetingType,
  showRescheduleMeeting,
  setShowRescheduleMeeting,
  clickReschedule,
  setClickReschedule,
  clickTodayReschedule,
  backToContacts,
  contact,
  keepBackToButton,
}) => {
  const { user } = React.useContext(UserCtx)
  const { renderError, renderSuccess } = React.useContext(CommonFunctionCtx)
  const { startLoading, stopLoading } = useLoading()
  const [showAttendeesView, setShowAttendeesView] =
    React.useState<boolean>(false)
  const [initialStep, setInitialStep] = React.useState<string>("")
  const [showNewMeeting, setShowNewMeeting] = React.useState<boolean>(false)
  const [isHovered, setIsHovered] = useState(false)

  const navigate = useNavigate()

  const handleMouseEnter = () => {
    setIsHovered(true)
  }
  const handleMouseLeave = () => {
    setIsHovered(false)
  }

  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const rescheduleParam = queryParams.get("reschedule")
  const meetingIdParam = queryParams.get("meetingId")

  useEffect(() => {
    if (rescheduleParam === "true" && meeting?.id === meetingIdParam) {
      setClickReschedule(true)
      setShowNewMeeting(true)
    }
  }, [
    rescheduleParam,
    meetingIdParam,
    meeting?.id,
    setClickReschedule,
    setShowNewMeeting,
  ])

  const cancelMeeting = () => {
    const coach = meeting?.coach_user_id ?  meeting?.coach_user_id : ""
    startLoading()
    MeetingRequests.cancelMeeting({
      coach_user_id: parseInt(coach),
      profile_id: user.activeProfileId,
      first_name: user.firstName,
      last_name: user.lastName,
      meeting_id: meeting?.id,
    })
      .then(() => {
        setTimeout(() => {
          setActiveSessionDetailViewId(null)
          setShowNewMeeting(false)
          renderSuccess("Your meeting was successfully canceled.")
          getMeetings()
          stopLoading()
          if (user.activeProfile === "member") {
            navigate(`/${user.activeProfile}`)
          } else {
            navigate(`/${user.activeProfile}/dashboard`)
          }
        }, 2000)
      })
      .catch((ex) => {
        console.log(ex)
        renderError(ex.response.data.message)
        stopLoading()
      })
  }

  const getGuestName = (guest: any) => {
    if (guest?.first_name) {
      return `${guest.first_name} ${guest.last_name}`
    } else {
      return guest.email
    }
  }

  const isPastMeeting = () => {
    return meeting?.endTime && meeting?.endTime < new Date()
  }

  const formatMeetingType = () => {
    if (meeting?.meetingType === "individual") {
      return "INDIVIDUAL - BILLABLE"
    } else if (meeting?.meetingType === "group") {
      return "GROUP - BILLABLE"
    } else if (meeting?.meetingType === "custom") {
      return "NON-BILLABLE"
    }
  }

  useScrollToTop()

  useEffect(() => {
    if (showAttendeesView || showNewMeeting) {
      setHideBackToLink(true)
    } else {
      setHideBackToLink(false)
    }
  }, [showAttendeesView, showNewMeeting, setHideBackToLink])

  if (!meeting) {
    return <p>Meeting not found</p>
  }

  if (showAttendeesView) {
    return (
      <AttendeesView
        sessionName={meeting.meetingName || "Meeting View"}
        contacts={meeting.contacts}
        setShowAttendeesView={setShowAttendeesView}
      />
    )
  } else if (showNewMeeting) {
    return (
      <NewMeeting
        newMeetingType={meeting.meetingType}
        setShowNewMeeting={setShowNewMeeting}
        setActiveSessionDetailViewId={setActiveSessionDetailViewId}
        existingMeetingData={meeting}
        initialStep={initialStep}
        isEditing={true}
        setNewMeetingType={setNewMeetingType}
        showRescheduleMeeting={showRescheduleMeeting}
        setShowRescheduleMeeting={setShowRescheduleMeeting}
        clickReschedule={clickReschedule}
        setClickReschedule={setClickReschedule}
        clickTodayReschedule={clickTodayReschedule}
        backToContacts={backToContacts}
        contact={contact}
        keepBackToButton={keepBackToButton}
      />
    )
  } else {
    return (
      <div className="pb-[100px] md:pb-0">
        <div className="mx-auto md:w-[744px] mb-[16px] px-[18px] md:px-0 w-full flex flex-col">
          <div className="mb-[12px] bg-white promotion-shadow rounded-[16px]">
            <div className="h-[100px] px-[24px] md:px-[32px] zoee-session-detail-gradient flex items-center justify-between rounded-t-[16px]">
              <div className="flex items-center mr-[8px]">
                <SvgZoeeIcon className="w-[38px] h-[38px] mr-[12px] md:mr-[22px]" />
                <h3 className="text-[16px] font-bold text-white">
                  Zoee Managed Calendar
                </h3>
              </div>
              {isPastMeeting() ? (
                <>
                  <div className="hidden md:block text-base font-bold text-white bg-black bg-opacity-[.32] zoee-backdrop-filter-[24px] rounded-[100px] px-[12px] py-[4px]">
                    This meeting occured in the past
                  </div>
                  <div className="min-w-fit md:hidden text-base font-bold text-white bg-black bg-opacity-[.32] zoee-backdrop-filter-[24px] rounded-[100px] px-[12px] py-[4px]">
                    Past meeting
                  </div>
                </>
              ) : (
                <div className="flex items-center gap-[8px] mt-[16px]">
                  {meeting.inProgress ? (
                    <button
                      className="btn-primary btn-blue min-w-fit md:h-[48px] h-[40px]"
                      onClick={(e: any) => {
                        startLoading()
                        window.location.href = `/${user.activeProfile}/sessions`
                      }}
                    >
                      Join Session
                    </button>
                  ) : (
                    <></>
                  )}
                </div>
              )}
            </div>
            <div className="relative flex flex-col px-[24px] pb-[24px] pt-[28px] md:px-[32px] md:pt-[40px] md:pb-[32px]">
              <h4 className="hidden md:block text-base font-bold text-graySlate mb-[24px]">
                EVENT DETAILS ({formatMeetingType()})
              </h4>
              <h4 className="md:hidden text-base font-bold text-graySlate mb-[24px]">
                EVENT DETAILS
              </h4>
              <h2 className="text-[18px] md:text-[32px] font-bold mb-[16px] w-full break-words">
                {meeting.meetingName}
              </h2>
              <div className="flex items-center mb-[32px]">
                <div className="pr-[16px] border-r border-grayMist">
                  {meeting.status && <StatusIcon status={meeting.status} />}
                </div>
                <button className="ml-[16px] flex items-center justify-center py-[4px] px-[8px] rounded-[8px] text-primaryBlue font-bold text-base bg-paleCyanBlue bg-opacity-[.24]">
                  <SvgVideoSolid className="mr-[12px]" />
                  Video
                </button>
              </div>
              <div className="flex flex-col">
                <h4 className="text-base font-bold text-graySlate mb-[8px]">
                  Notifications
                </h4>
                <div className="flex gap-[8px]">
                  <SvgNotifications className="w-[24px] h-[24px]" />
                  {meeting.notifications.length > 0 ? (
                    // TODO Make dynamic
                    <p className="text-base font-bold">Email, 30 minutes</p>
                  ) : (
                    <p className="text-base font-bold">None</p>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="flex md:justify-between gap-4 flex-col md:flex-row mb-[12px] relative promotion-shadow bg-white px-[24px] pb-[24px] pt-[28px] md:px-[32px] md:pt-[40px] md:pb-[32px] rounded-[16px]">
            <div className="w-full">
              <h5 className="mb-[24px] text-base font-bold text-graySlate">
                WHEN
              </h5>
              <div>
                {meeting.date && meeting.startTime && meeting.endTime && (
                  <>
                    <p className="font-bold text-[16px] mb-[8px]">
                      {moment(meeting.startTime).format("MMMM DD, yyyy")}
                    </p>
                    <div className="flex items-center text-graySlate text-base">
                      <p>Starts at</p>
                      <p className="text-black font-bold">
                        &nbsp;
                        {moment(meeting.startTime).format("h:mm A")}
                      </p>
                      <p className="mx-[12px]">-</p>
                      <p>Ends at</p>
                      <p className="text-black font-bold">
                        &nbsp;
                        {moment(meeting.endTime).format("h:mm A")}
                      </p>
                    </div>
                  </>
                )}
              </div>
            </div>

            <div
              className="flex gap-2 flex-col md:justify-between"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <Button
                variant="outline"
                onClick={() => {
                  setInitialStep("availability")
                  setShowNewMeeting(true)
                  setClickReschedule(true)
                }}
                className=" h-[32px] md:px-8 md:py-4"
                disabled={
                  meeting?.meetingType.toLowerCase() === "group" ? true : false
                }
              >
                Reschedule
              </Button>
              <Button
                className=" h-[35px] md:px-8 md:py-4"
                variant="destructive"
                onClick={cancelMeeting}
                disabled={
                  meeting?.meetingType.toLowerCase() === "group" ? true : false
                }
              >
                Cancel Meeting
              </Button>

              {isHovered &&
                user.activeProfile === "coach" &&
                meeting?.meetingType.toLowerCase() === "group" && (
                  <a
                    href="mailto:support@zoee.com"
                    className="absolute right-0 mt-1 p-2 bg-lightPurple text-gray-800 text-sm rounded-md shadow-md cursor-pointer opacity-90 w-[250px] h-[100px]"
                    // onClick={handleContactSupport}
                  >
                    <span className="ml-0">Under Construction</span>
                    <span
                      role="img"
                      aria-label="Under Construction"
                      className="text-xl"
                    >
                      {" "}
                      {String.fromCodePoint(0x1f6a7)}
                    </span>{" "}
                    <br />
                    To cancel or reschedule this meeting, please contact{" "}
                    <b>support@zoee.com</b>
                  </a>
                )}

              {isHovered &&
                user.activeProfile === "member" &&
                meeting?.meetingType.toLowerCase() === "group" && (
                  <a
                    href="mailto:support@zoee.com"
                    className="absolute right-0 mt-1 p-2 bg-lightPurple text-gray-800 text-sm rounded-md shadow-md cursor-pointer opacity-90 w-[250px] h-[70px]"
                  >
                    Please contact your coach to cancel or reschedule this
                    meeting.
                  </a>
                )}
            </div>
          </div>

          <div className="relative mb-[12px] bg-white promotion-shadow rounded-[16px] px-[24px] pb-[24px] pt-[28px] md:px-[32px] md:pt-[40px] md:pb-[32px]">
            {meeting.meetingType === "custom" && (
              <button
                onClick={() => {
                  setInitialStep("attendees")
                  setShowNewMeeting(true)
                }}
                className="absolute right-[24px] md:top-[32px] md:right-[40px] btn-primary btn-secondary h-[32px]"
              >
                Edit Attendees
              </button>
            )}
            <div className="flex flex-col">
              <div className="flex items-center gap-[12px] mb-[24px]">
                <h4 className="text-base font-bold text-graySlate">
                  ATTENDEES
                </h4>
                {/* Hidden for 2.0 launch. */}
                {/* <p className="text-base text-graySlate"> 
                                    <span className="font-bold text-mainBlack">
                                        {meeting.contacts.length - 1}
                                    </span>
                                    &nbsp;of {meeting.contacts.length} responded
                                </p> */}
              </div>
              <div className="flex flex-col gap-[12px]">
                {meeting.contacts.length < 10 ? (
                  meeting.contacts.map((guest, idx) => {
                    return (
                      <div key={idx} className="flex items-center">
                        <ContactAvatar
                          contact={guest}
                          width={32}
                          height={32}
                          bold={false}
                          fontSize={"text-base"}
                        />
                        <p className="ml-[16px] mr-[12px] text-base font-bold">
                          {getGuestName(guest)}
                        </p>
                        <div className="text-base font-bold">
                          {/* TODO Hidden for 2.0 launch. Add back in later. */}
                          {/* <AttendeeStatusTag status="Accepted" /> */}
                        </div>
                      </div>
                    )
                  })
                ) : (
                  <>
                    {meeting.contacts.slice(0, 9).map((guest, idx) => {
                      return (
                        <div key={idx} className="flex items-center">
                          {/* TODO Replace with ContactAvatar.tsx component */}
                          <ContactAvatar
                            contact={guest}
                            width={32}
                            height={32}
                            bold={false}
                            fontSize={"text-base"}
                          />
                          <p className="mr-[12px] text-base font-bold">
                            {getGuestName(guest)}
                          </p>
                          {/* TODO Hidden for 2.0 launch. Add back in later. */}
                          {/* <AttendeeStatusTag status="Accepted" /> */}
                        </div>
                      )
                    })}
                    <button
                      onClick={() => setShowAttendeesView(true)}
                      className="mt-[4px] btn-primary btn-blue w-[162px] h-[32px]"
                    >
                      See All Attendees
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
          {!(meeting.meetingType === "custom") && (
            <div className="relative mx-auto md:w-[744px] px-[24px] pb-[24px] pt-[28px] md:px-[32px] md:pt-[40px] md:pb-[32px] w-full flex flex-col rounded-[16px] bg-white promotion-shadow">
              <h4 className="text-base font-bold text-graySlate mb-[24px]">
                SERVICES
              </h4>
              {meeting?.service_details ? (
                <ServiceCardPreviewWide service={meeting} />
              ) : (
                <>No service attached</>
              )}
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default ZoeeSessionDetailView
