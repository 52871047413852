import React from "react";
import { CoachPublicProfileContext } from "../../pages/manage-business/profile/context/coachPublicProfileContext";
import ServiceCardBannerOverlay from "../services/ServiceCardBannerOverlay";
import { useParams, Link } from "react-router-dom";
import { Button } from "../ui/button";
import { getLightTextColor } from "../../utils/getLightTextColor";
import { getTextColor } from "../../utils/getTextColor";
import { SvgCheckCircle } from "../icons/";
import { Badge } from "../ui/badge";
import moment from "moment";
import { pluralize } from "../../utils/pluralize";
import { getUnits } from "../../utils/services/get-units/get-units";
import { getPrice } from "../../utils/services/get-price";

interface Props {
  service: any;
  getServices: any;
}

const PublicProfileServiceCard: React.FC<Props> = ({ service }) => {
  const serviceDetails = service?.service_details;
  const { is_group_service } = service;
  const { endpoint_slug } = useParams();
  const { coachPublicProfileData } = React.useContext(
    CoachPublicProfileContext
  );
  const coachColor = coachPublicProfileData?.profile_information?.profileColor;
  const textColor = getLightTextColor(coachColor);

  const getSessionCount = () => {
    if (["consultation", "individual"].includes(serviceDetails?.type)) {
      return `${serviceDetails?.session.count} session`;
    } else if (["bundle"].includes(serviceDetails?.type)) {
      return pluralize(serviceDetails?.session.count, "session");
    } else if (["recurring"].includes(serviceDetails?.type)) {
      return (
        <div>
          {pluralize(serviceDetails?.session?.count, "session")}
          <span> / {`${serviceDetails?.billing?.unit}`}</span>
        </div>
      );
    } else {
      return "Not Found";
    }
  };

  const getStartDate = (): {
    startMessage: string;
  } => {
    const meetingDates = serviceDetails?.group?.meetingDates || [];
    const upcomingDates = meetingDates
      .map((date: string) => new Date(date))
      .filter((date: Date) => date >= new Date())
      .sort((a: Date, b: Date) => a.getTime() - b.getTime());
  
    if (upcomingDates.length > 0) {
      const startDate = upcomingDates[0];
      const startTime = moment(startDate).format("h:mm A");
      return {
        startMessage: `Next Session at ${moment(startDate).format(
          "MMM DD"
        )} at ${startTime}`,
      };
    } else {
      return { startMessage: "" };
    }
  };

  const serviceCardDetailsLink = `/find-a-coach/${endpoint_slug}/services/${service.service_id}`;

  return (
    <div
      className="w-full md:w-[385px] bg-white relative rounded-[16px] main-shadow min-h-[410px] hover:transform hover:scale-105 transition-transform duration-300 shadow-custom last:mb-[30px] md:last:mb-0"
    >
      <Link to={serviceCardDetailsLink}>
        <div
          className={`w-full h-[110px] border-white border-[12px] rounded-[20px] relative ${serviceDetails?.image?.color}`}
        >
          <ServiceCardBannerOverlay service={service} />
          {serviceDetails.image.exists && (
            <img
              src={`https://clientservice-images-${process.env.REACT_APP_USER_ENVIRONMENT}.s3.amazonaws.com/${service.stripe_product_id}?t=${new Date().getTime()}`}
              alt="service"
              className="w-full h-full object-cover rounded-[8px]"
            />
          )}
        </div>
        <div className="w-full flex flex-col px-[16px] py-[12px]">
          <div className="w-full flex flex-col items-left justify-between">
            <h4 className="font-bold text-[18px] max-w-[260px]">
              {serviceDetails?.title}
            </h4>
            <h3 className="font-bold text-[16px] mt-2">
              {getPrice(parseInt(serviceDetails?.billing?.amount), serviceDetails?.symbol)}
              <span className="text-graySlate text-[12px] text-base font-normal">
                {getUnits(serviceDetails.billing.unit, parseInt(serviceDetails?.billing?.amount), serviceDetails?.type)}
              </span>
              {is_group_service && getStartDate().startMessage && (
                <>
                  &nbsp; &nbsp;
                  <Badge variant="success">
                    {getStartDate().startMessage}
                  </Badge>
                </>
              )}
            </h3>
          </div>

          <div className="w-full flex items-center justify-between mt-2">
            <div className="flex gap-[16px] items-center">
              <div
                className="py-[4px] px-[8px] rounded-[8px] flex items-center justify-center font-bold text-base text-black"
                style={{ background: textColor }}
              >
                {getSessionCount()}
              </div>
              <div
                className="py-[4px] px-[8px] rounded-[8px] flex items-center justify-center font-bold text-base text-black"
                style={{ background: textColor }}
              >
                <div>{parseInt(serviceDetails?.session?.duration) / 60} minutes</div>
              </div>
            </div>
          </div>
          <div className="text-sm mt-4">
            <div className="flex items-center">
              {serviceDetails?.details?.features?.feature1 ? (
                <>
                  <SvgCheckCircle
                    fill={`${coachPublicProfileData?.profile_information?.profileColor}`}
                  />
                  <p className="ml-3 line-clamp-1 mt-1">
                    {serviceDetails.details.features.feature1}
                  </p>
                </>
              ) : null}
            </div>
            <div className="flex items-center">
              {serviceDetails?.details?.features?.feature2 ? (
                <>
                  <SvgCheckCircle
                    fill={`${coachPublicProfileData?.profile_information?.profileColor}`}
                  />
                  <p className="ml-3 line-clamp-1 mt-1">
                    {serviceDetails.details.features.feature2}
                  </p>
                </>
              ) : null}
            </div>
            <div className="flex items-center">
              {serviceDetails?.details?.features?.feature3 ? (
                <>
                  <SvgCheckCircle
                    fill={`${coachPublicProfileData?.profile_information?.profileColor}`}
                  />
                  <p className="ml-3 line-clamp-1 mt-1">
                    {serviceDetails.details.features.feature3}
                  </p>
                </>
              ) : null}
            </div>
          </div>

          <Link
            to={serviceCardDetailsLink}
            className="gap-[8px] absolute bottom-[16px] w-[90%] left-[50%] -translate-x-[50%]"
          >
            <Button
              className={`bg-${coachColor} text-${getTextColor(
                coachColor
              )} h-[50px] w-full rounded-md shadow-md flex items-center justify-center p-4 font-bold text-md`}
              style={{
                backgroundColor: coachColor,
                color: getTextColor(coachColor),
              }}
            >
              See Details
            </Button>
          </Link>
        </div>
      </Link>
    </div>
  );
};

export default PublicProfileServiceCard;
