import React from "react"
import { SvgCheckCircle } from "../icons"
import { Button } from "../ui/button"

type Props = {
  plan: any
  title: string
  handleChoosePlanClick: (plan: any) => void
}

const SubscriptionPlanCard: React.FC<Props> = ({
  plan,
  title,
  handleChoosePlanClick,
}) => {
  const [cardDetailsColor, setCardDetailsColor] = React.useState<string>("")
  const [cardColor, setCardColor] = React.useState<string>("")
  const [paymentPeriod, setPaymentPeriod] = React.useState<string>("")

  const assignCardColor = () => {
    if (plan?.recurring_interval === "annual" && !plan?.is_promotional) {
      setCardDetailsColor("darkPurple")
    }
    if (plan?.recurring_interval === "month" && !plan?.is_promotional) {
      setCardDetailsColor("blurple")
    }
    if (plan?.amount === 500 && plan?.is_promotional) {
      setCardDetailsColor("mainBlack")
      setCardColor("subscription-cta-gradient")
    }
  }

  const setPeriod = () => {
    if (plan?.recurring_interval === "annual" && !plan?.is_promotional) {
      setPaymentPeriod("/annual")
    }
    if (plan?.recurring_interval === "month" && !plan?.is_promotional) {
      setPaymentPeriod("/month")
    }
    if (plan?.amount === 500 && plan?.recurring_interval_count === 2) {
      setPaymentPeriod("one-time cost")
    }
  }

  const getStrikethroughPrice = () => {
    if (plan?.recurring_interval === "annual" && !plan?.is_promotional)
      return "$55.00/mo"
    if (plan?.recurring_interval === "month" && !plan?.is_promotional)
      return "$69.00/mo"
  }

  const getBillingCycleText = () => {
    if (plan?.recurring_interval === "annual" && !plan?.is_promotional)
      return "Billed annually"
    if (plan?.recurring_interval === "month" && !plan?.is_promotional)
      return <span style={{ visibility: "hidden" }}>Monthly</span>
  }

  React.useEffect(() => {
    assignCardColor()
    setPeriod()
  }, [plan])

  return (
    <div
      className={`${cardColor} w-[406px] h-full flex flex-col rounded-[32px] main-shadow bg-white relative`}
    >
      <div
        className={`absolute top-0 left-0 w-full h-[30px] bg-black rounded-tl-[32px] rounded-tr-[32px] ${
          plan?.recurring_interval === "annual"
            ? "bg-blurple"
            : plan?.recurring_interval === "month"
            ? "bg-black"
            : "bg-gray-400"
        } flex items-center justify-center`}
        style={{ borderTopLeftRadius: "32px", borderTopRightRadius: "32px" }}
      >
        {plan?.recurring_interval !== "freeTrial" && (
          <p className="text-white text-center">Limited Time Offer</p>
        )}
      </div>

      <div className="md:hidden"></div>
      <div className="h-[736px] px-8 py-10 bg-white rounded-[32px] flex-col justify-start items-start gap-8 inline-flex">
        <div className=" self-stretch h-[28px] flex-col justify-start items-start gap-1 flex">
          <div className="text-black text-2xl font-semibold font-['Beausite Classic']">
            {title}
          </div>
          <div className="self-stretch text-[#7d878f] text-base font-normal font-['Beausite Classic'] leading-snug">
            {plan?.stripe_product_description}
          </div>
        </div>
        <div className="self-stretch h-[78px] flex-col justify-start items-start flex -mt-1.5">
          <div className="self-stretch h-[76px] flex-col justify-start items-start gap-0.5 flex">
              <div className="text-center text-black text-[42px] font-semibold font-['Beausite Classic']">
                ${plan?.amount}
                {paymentPeriod}
              </div>
              <div className="self-stretch text-[#8e8e93] text-xl font-normal font-['Beausite Classic']">
                <p className="text-[18px] text-graySlate line-through">
                  {getStrikethroughPrice()}
                </p>
                <p className="text-[18px] text-graySlate">
                  {getBillingCycleText()}
                </p>
              </div>
          </div>
        </div>

        <div className="flex flex-col gap-[12px] flex justify-center w-full">
          <div className="flex flex-col gap-[12px]">
            <Button
              variant="default"
              size={"lg"}
              className="mt-[4px] md:mt-[20px] mx-[8px] md:mx-0 text-md"
              onClick={() => handleChoosePlanClick(plan)}
            >
              Choose Plan
            </Button>
          </div>

          <div className="flex flex-col gap-[16px]">
            {[
              plan?.stripe_product_metadata?.feature1,
              plan?.stripe_product_metadata?.feature2,
              plan?.stripe_product_metadata?.feature3,
              plan?.stripe_product_metadata?.feature4,
            ].map((feature, index) => (
              <div className="flex items-center gap-[16px]" key={index}>
                <SvgCheckCircle stroke="#4750f5" />
                <p>{feature}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default SubscriptionPlanCard
