import React from "react"
import BillingPreviewItem from "./BillingPreviewItem"
import { ClientPaymentRequests } from "../../../api/app.service"
import { ProfileClientPaymentsItem } from "../../../types/clientPaymentTypes"
import { UserCtx } from "../../../context/userContext"
import { CommonFunctionCtx } from "../../../context/commonFunctionContext"
import { Button } from "../../ui/button"

interface Props {
  contact: any;
  serviceState: string
}

const BillingPreviewElement: React.FC<Props> = (props: any) => {
  const { user } = React.useContext(UserCtx)
  const { renderError } = React.useContext(CommonFunctionCtx)
  const [selectedState, setSelectedState] = React.useState<string>("past")
  const [showCount, setShowCount] = React.useState<number>(3)
  const [invoices, setInvoices] = React.useState<
    Array<ProfileClientPaymentsItem>
  >([])
  interface GroupedInvoices {
    [currency: string]: Array<ProfileClientPaymentsItem>
  }
  const [groupedInvoices, setGroupedInvoices] = React.useState<GroupedInvoices>(
    {}
  )
  const sortPayments = (payments: any) => {
    const sorted = payments.sort((a: any, b: any) => {
      const bDate: any = new Date(b.transaction_date)
      const aDate: any = new Date(a.transaction_date)
      return bDate - aDate
    })
    return sorted
  }

  const getProfileClientPayments = () => {
    if (user) {
      if (user.activeProfile === "member" ) {
        ClientPaymentRequests.getProfileClientPayments({
          member_profile_id: user.activeProfileId,
          coach_profile_id: props.contact.profile_id,
        })
          .then((data) => {
            setInvoices(sortPayments(data.client_payments_list))
          })
          .catch((ex) => {
            console.log(ex);
            renderError(ex.response.data.message);
          });
      } else if (user.activeProfile === "coach" && props.contact.profile_type  === 'member') {
        ClientPaymentRequests.getProfileClientPayments({
          member_profile_id: props.contact.profile_id,
          coach_profile_id: user.activeProfileId,
        })
          .then((data) => {
            setInvoices(sortPayments(data.client_payments_list));
          })
          .catch((ex) => {
            console.log(ex);
            renderError(ex.response.data.message);
          });
      } else {
      ClientPaymentRequests.getProfileClientPayments({
           member_profile_id: props.serviceState === 'coach' ?  user.activeProfileId :  props.contact.profile_id,
          coach_profile_id: props.serviceState === 'coach' ?   props.contact.profile_id : props.contact.profile_id
      })
        .then((data) => {
          setInvoices(sortPayments(data.client_payments_list));
        })
        .catch((ex) => {
          console.log(ex);
          renderError(ex.response.data.message);
        });
    }
    }
  }

  React.useEffect(() => {
    const groupedInvoices: GroupedInvoices = invoices.reduce((acc, invoice) => {
      // Convert currency to lowercase for consistency
      const currency = invoice.currency?.toLowerCase()

      // Initialize the array for this currency if it doesn't exist
      if (!acc[currency]) {
        acc[currency] = []
      }

      // Push the current invoice into the correct currency array
      acc[currency].push(invoice)

      return acc
    }, {} as GroupedInvoices)
    setGroupedInvoices(groupedInvoices)
  }, [invoices])

  React.useEffect(() => {
    getProfileClientPayments()
  }, [])

  const calculateTotal = (invoices: any[]): string => {
    const allInvoices = Object.values(invoices).flat()
    const invoiceAmountTotals = allInvoices?.map((e: any) => e.stripe_amount)
    const invoiceAmountTotalsSum = invoiceAmountTotals
      ? invoiceAmountTotals.reduce(
          (previous: any, current: any) => previous + current,
          0
        ) / 100
      : 0
    const formattedInvoiceAmountTotals = invoiceAmountTotalsSum.toLocaleString(
      "en-US",
      {
        minimumFractionDigits: 2,
      }
    )
    return formattedInvoiceAmountTotals
  }

  const loadMore = () => {
    setShowCount(showCount + 2)
  }

  const loadLess = () => {
    setShowCount(3)
  }

  return (
    <div className="w-full md:main-shadow rounded-[16px] md:p-[40px] relative">
      <div className="flex items-center justify-between mb-[20px]">
        <div className="hidden md:flex items-center gap-[16px]">
          <h2 className="font-semibold text-[28px]">Billing</h2>
          {/* <p className="text-gray text-base">Client has credit card on file</p> */}
        </div>
        {/* HIDE TOGGLE FOR 2.0 LAUNCH */}
        {/* <div className="flex items-center p-[4px] bg-hover rounded-[12px]">
                    <button 
                        className={`flex items-center justify-center font-bold text-base rounded-[8px]
                        ${selectedState === "upcoming" ? "text-mainBlack bg-white attendee-toggle-shadow"
                        : "text-gray"} py-[4px] px-[24px]`}
                        onClick={() => setSelectedState("upcoming")}
                    >
                        Upcoming Payments
                    </button>
                    <button 
                        className={`flex items-center justify-center font-bold text-base rounded-[8px]
                        ${selectedState === "past" ? "text-mainBlack bg-white attendee-toggle-shadow"
                        : "text-gray"} py-[4px] px-[24px]`}
                        onClick={() => setSelectedState("past")}
                    >
                        Past Payments
                    </button>
                </div> */}
      </div>

      {Object.keys(groupedInvoices).map((currency) => (
        <>
          {groupedInvoices[currency].length > 0 && (
            <div className="mb-[24px]">
              <h5 className="text-base font-bold text-graySlate mb-[8px]">
                Total
              </h5>
              <p className="text-[32px] font-bold">
                {String.fromCharCode(
                  parseInt(groupedInvoices[currency][0]?.symbol?.slice(2), 16)
                )}
                {calculateTotal(groupedInvoices[currency])}
              </p>
            </div>
          )}

          <div className="flex flex-col gap-[8px]">
            {invoices.length === 0 ? (
              <p className="px-[24px] pt-[12px] md:pt-0 pb-[20px] text-center font-bold">
                You don't have any past payments with this contact.
              </p>
            ) : (
              <>
                {groupedInvoices[currency]
                  .slice(0, showCount)
                  .map((invoice, index) => (
                    <div key={index}>
                      <BillingPreviewItem invoice={invoice} />
                    </div>
                  ))}
              </>
            )}
          </div>
        </>
      ))}

      {invoices.length > 3 && (
        <div className="flex items-center justify-center gap-[12px] mx-auto mt-[24px]">
          {showCount > 2 && (
            <Button onClick={loadLess} variant="secondary" size="sm">
              Show Less
            </Button>
          )}
          <Button
            onClick={loadMore}
            disabled={showCount >= invoices.length}
            size="sm"
          >
            Load More
          </Button>
        </div>
      )}
    </div>
  )
}

export default BillingPreviewElement
