import React from 'react';
import { SvgDollar } from '../icons';

interface Props {
    item: any;
}

const ActivityItem: React.FC<Props> = (props: any) => {
    return(
        <div className="flex flex-col">
            <div className="w-full flex gap-[16px] mb-[24px]">
                <div className="relative w-[48px] h-[48px]">
                    <img src={props.item.image} alt="avatar" className='min-w-[48px] min-h-[48px]'/>
                    <div 
                        className="absolute bottom-0 right-0 w-[24px] h-[24px] flex justify-center
                        items-center border border-[2px] border-white bg-[#8CD48F] rounded-full"
                    >
                        <SvgDollar />
                    </div>
                </div>
                <div className="flex flex-col w-full sm:flex-row sm:justify-between">
                    <div>
                        <h4 className='font-bold text-base mb-[4px]'>
                        {props.item.name}
                        </h4>
                        <p className="text-base text-grayCharcoal mb-[16px]">
                            {props.item.action}
                            <span className='py-[4px] px-[8px] text-cyanBlue text-base font-bold bg-paleCyanBlue/25 rounded-[8px] ml-[2px]'>
                                {`Invoice #${props.item.invoiceNumber}`}
                            </span>
                        </p>
                        <p className="text-base text-graySlate mb-[16px]">{props.item.date}</p>
                    </div>
                    {props.item.invoiceNumber && (
                        <button className="btn-primary btn-blue h-[46px]">View Invoice</button>
                    )}
                </div>
            </div>
            <div className="w-full h-[1px] bg-grayMist"></div>
        </div>
    )
}

export default ActivityItem;