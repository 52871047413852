"use client"

import * as React from "react"
import { useState, useEffect } from "react"
import { Button } from "../../../../components/ui/button"
import feature from "../../../../assets/images/feature-client-management.webp"
import { useScroll, useTransform } from "framer-motion"
import CalendlyScheduler from "../../../manage-business/CalendlyScheduler"
import { useAuthModal } from "../../../../components/auth/use-auth-modal"
import { useAutoAnimate } from "@formkit/auto-animate/react"

export function AffiliateLandingCTA() {
  const strings = ["starting", "growing", "managing", "enjoying"]

  const [index, setIndex] = React.useState(0)

  const [referrerName, setReferrerName] = useState<string>("Zoee")
  const { scrollYProgress } = useScroll()
  const { showRegister } = useAuthModal()
  const [parent] = useAutoAnimate()
  let y = useTransform(scrollYProgress, [0, 1], ["0%", "-300%"])

  const [isSchedulerOpen, setIsSchedulerOpen] = useState(false)

  const handleButtonClick = () => {
    setIsSchedulerOpen(true)
  }

  const handleCloseScheduler = () => {
    setIsSchedulerOpen(false)
  }

  React.useEffect(() => {
    const id = setInterval(() => {
      setIndex((state) => {
        if (state >= strings.length - 1) return 0
        return state + 1
      })
    }, 2500)
    return () => clearInterval(id)
  }, [])

  // Use local storage to check if the user has logged in or signed up for a 14-day trial
  const [isUserLoggedIn, setIsUserLoggedIn] = useState<boolean>(false)
  const checkUserInLocalStorage = () => {
    const user = localStorage.getItem("user")
    setIsUserLoggedIn(!!user)
  }

  useEffect(() => {
    const referrerPromoCode = localStorage.getItem("referrerPromoCode")
    if (referrerPromoCode) {
      setReferrerName(referrerPromoCode)
    }
    checkUserInLocalStorage()
  }, [isUserLoggedIn])

  return (
    <div className="w-full px-4 py-8 md:px-[120px] md:py-[72px] bg-[#fcfcfc] flex flex-col items-center gap-[30px]">
      <div className="flex flex-col md:flex-row justify-center items-center md:items-start gap-8 md:gap-16 w-full max-w-[1200px]">
        <div className="w-full md:w-1/2 flex flex-col justify-start items-center md:items-start gap-6">
          <div className="text-center md:text-left text-black text-3xl md:text-5xl font-bold font-['Beausite Classic'] leading-tight md:leading-[50px]">
            {referrerName}'s gift of 10% off your first year or month with Zoee
          </div>

          {/* buttons */}
          <div className="flex flex-col md:flex-row justify-center md:justify-start items-center gap-4 w-full">
            <Button
              className=" flex justify-center items-center px-5 py-4"
              variant="default"
              size="lg"
              onClick={() => showRegister({ accountType: "coach" })}
            >
              Claim your discount
            </Button>
          </div>
        </div>

        {/* image */}
        <div className="w-full md:w-1/2 flex justify-center md:justify-end">
          <img
            className="w-full h-auto md:w-[550px] md:h-[390px] rounded-[20px]"
            src={feature}
            alt="session notes and active client contact card"
          />
        </div>
      </div>
    </div>
  )
}
