import React from "react";
import { SvgDownload, SvgEye } from "../../icons";

interface Props {
    receipt: any;
}

const ReceiptItemDropdown: React.FC<Props> = ({ receipt }) => {
    return (
        <div className="flex flex-col bg-white px-[16px] py-[12px] rounded-[12px] card-hover-shadow">
            {receipt.stripe_receipt_url && (
                <a
                    href={receipt?.stripe_receipt_url}
                    target="_blank"
                    className="text-base w-[216px] px-[16px] py-[12px] flex items-center gap-[8px] rounded-[12px] hover:bg-hover"
                    rel="noreferrer"
                >
                    <SvgEye />
                    View Receipt
                </a>
            )}
            <a
                href={receipt?.stripe_invoice_pdf}
                target="_blank"
                className="text-base w-[216px] px-[16px] py-[12px] flex items-center gap-[8px] rounded-[12px] hover:bg-hover"
                rel="noreferrer"
            >
                <SvgDownload />
                Download Invoice PDF
            </a>
        </div>
    );
};

export default ReceiptItemDropdown;
