import React, { useEffect, useRef, useState } from "react";
import { Button } from "../ui/button";
import PublicOverviewBento from "../coach-private-profile/normal-state/PublicOverviewBento";
import PublicPortraitVideoDeck from "./PublicPortraitVideoDeck";
import PublicProfileServices from "./PublicProfileServices";
import { CoachPublicProfileContext } from "../../pages/manage-business/profile/context/coachPublicProfileContext";
import { ShareModal } from "../coach-private-profile/normal-state/ShareModal";
import ListBlogCards from "../coach-profile/ListBlogCards";
import ProfileAboutTab from "../coach-private-profile/normal-state/ProfileAboutTab";
import { useSearchParams } from "react-router-dom";
import ProfileTestimonialTab from "../coach-private-profile/normal-state/ProfileTestimonialTab";
import { AllTestimonials } from "../../models/app.interface";
import { ProfileRequests } from "../../api/app.service";
import { CommonFunctionCtx } from "../../context/commonFunctionContext";
import { SvgArticle, SvgBusiness, SvgGrid, SvgList } from "../icons/";

interface Props {
  post?: any;
  service?: string;
  services?: any[];
}

const PublicTilesCarousel: React.FC<Props> = ({ post, service, services }) => {
  const [activeTab, setActiveTab] = useState("overview");
  const [shareModalOpen, setShareModalOpen] = useState<boolean>(false);
  const { coachPublicProfileData } = React.useContext(
    CoachPublicProfileContext,
  );
  const [filledButton, setFilledButton] = useState<boolean>(false);
  const endPoint = coachPublicProfileData?.endpoint_slug;
  const [searchParams, setSearchParams] = useSearchParams();
  const topRef = useRef<HTMLDivElement>(null);
  const { renderError } = React.useContext(CommonFunctionCtx);
  const [testimonials, setTestimonials] = useState<AllTestimonials[]>([]);
  const [videos, setVideos] = React.useState<any[]>([]);
  const [posts, setPosts] = React.useState<any[]>([]);
  const [aboutSectionHasContent, setAboutSectionHasContent] =
    React.useState<boolean>(false);
  const coachColor = coachPublicProfileData?.profile_information?.profileColor;

  const tabLabels = [
    {
      name: "Overview",
      activeTab: "overview",
      icon: <SvgGrid />,
      hasContent: true,
    },
    {
      name: "About",
      activeTab: "about",
      icon: <SvgList />,
      hasContent: aboutSectionHasContent,
    },
    {
      name: "Services",
      activeTab: "services",
      icon: <SvgBusiness />,
      hasContent: Array.isArray(services) && services?.length > 0,
    },
    {
      name: "Featured Content",
      activeTab: "featured",
      icon: <SvgArticle />,
      hasContent:
        (Array.isArray(videos) && videos?.length > 0) ||
        (Array.isArray(posts) && posts?.length > 0),
    },
    {
      name: "Testimonials",
      activeTab: "testimonials",
      icon: <SvgArticle />,
      hasContent:
        Array.isArray(testimonials) &&
        testimonials?.length > 0 &&
        testimonials.some((testimonial: any) => testimonial?.is_shown === 1),
    },
  ];

  const renderServices = () => {
    setActiveTab("services");
  };

  const getCoachTestimonials = async () => {
    await ProfileRequests.getTestimonials({
      coach_public_profile_id: coachPublicProfileData?.coach_public_profile_id,
    })
      .then((data: any) => {
        setTestimonials(data.testimonials);
      })
      .catch((ex: any) => {
        console.log(ex);
        renderError(ex.response.data.message);
      });
  };

  const checkAboutForContent = () => {
    if (
      coachPublicProfileData?.profile_information?.about ||
      coachPublicProfileData?.tags?.length > 0
    ) {
      setAboutSectionHasContent(true);
    } else {
      setAboutSectionHasContent(false);
    }
  };

  const getVideos = () => {
    ProfileRequests.getCoachProfileVideos({
      coach_public_profile_id: coachPublicProfileData?.coach_public_profile_id,
    })
      .then((data) => {
        setVideos(data.videos);
      })
      .catch((ex) => {
        console.log(ex);
        renderError(ex.response.data.message);
      });
  };

  const getCoachBlogPosts = async () => {
    await ProfileRequests.getCoachBlogPosts({
      coach_profile_id: parseInt(coachPublicProfileData?.coach_profile_id),
    })
      .then((data) => {
        const approvedPosts = data?.blog_posts?.filter(
          (post: any) => post.status === "approved" && post.published,
        );
        setPosts(approvedPosts);
      })
      .catch((ex: any) => {
        console.error(ex);
        renderError(ex.response.data.message);
      });
  };

  useEffect(() => {
    getCoachTestimonials();
    getVideos();
    getCoachBlogPosts();
    checkAboutForContent();
  }, []);

  useEffect(() => {
    const currentUrl = window.location.href;
    if (currentUrl.includes("featured=true")) {
      setActiveTab("featured");
    } else if (currentUrl.includes("services=true")) {
      setActiveTab("services");
    } else {
      setActiveTab("overview");
    }

    const delayedScroll = () => {
      if (topRef.current && currentUrl.includes("featured=true")) {
        topRef.current.scrollIntoView({ behavior: "smooth" });
      }
    };
    const timeoutId = setTimeout(delayedScroll, 1000);
    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    if (activeTab === "featured") {
      setSearchParams({ featured: "true" });
      setFilledButton(true);
    } else if (activeTab === "services") {
      setSearchParams({ services: "true" });
      setFilledButton(true);
    } else {
      searchParams.delete("featured");
      searchParams.delete("services");
      setSearchParams(searchParams);
    }
  }, [activeTab, setSearchParams, searchParams]);

  return (
    <>
      <div ref={topRef} className="relative flex w-full flex-col gap-[12px]">
        <div className="flex items-stretch gap-8 overflow-x-auto">
          {/* Filters for tabs with content and display */}
          {tabLabels
            .filter((tab) => tab.hasContent)
            .map((tab, index) => (
              <Button
                className={`mb-2 ml-2 h-[50px] ${
                  (filledButton || tab.activeTab === "overview") &&
                  activeTab === tab.activeTab
                    ? "bg-white"
                    : ""
                }`}
                key={index}
                variant="utility"
                onClick={() => {
                  setActiveTab(tab.activeTab);
                  setFilledButton(true);
                }}
              >
                {tab.icon}
                <h3>{tab.name}</h3>
              </Button>
            ))}
        </div>
      </div>
      {shareModalOpen && (
        <>
          <ShareModal
            endpoint_slug={endPoint}
            shareModalOpen={shareModalOpen}
            setShareModalOpen={setShareModalOpen}
          />
        </>
      )}
      {activeTab === "overview" && (
        <PublicOverviewBento
          renderServices={renderServices}
          setActiveTab={setActiveTab}
        />
      )}
      {activeTab === "about" && (
        <ProfileAboutTab coachPublicProfileData={coachPublicProfileData} />
      )}
      {activeTab === "services" && <PublicProfileServices />}
      {activeTab === "featured" && (
        <div>
          <div className="mb-16">
            <h1 className="mb-8 text-[20px] font-bold">Videos</h1>
            <PublicPortraitVideoDeck />
          </div>
          <div className="-mt-8">
            <h1 className="mb-8 text-[20px] font-bold">Blog</h1>
            <ListBlogCards coachColor={coachColor} />
          </div>
        </div>
      )}
      {activeTab === "testimonials" && (
        <ProfileTestimonialTab
          isEditable={false}
          coachPublicProfileData={coachPublicProfileData}
        />
      )}
    </>
  );
};

export default PublicTilesCarousel;
