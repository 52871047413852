import React from "react"
import SubscriptionTag from "./SubscriptionTag"
import { SvgCheckCircle } from "../icons"
import { Button } from "../ui/button"

const TrialSubscriptionCard: React.FC = () => {
  const [cardDetailsColor, setCardDetailsColor] = React.useState<string>("")
  const [cardColor, setCardColor] = React.useState<string>("")
  const [price, setPrice] = React.useState<string>("")
  const [billingPeriod, setBillingPeriod] = React.useState<string>("")
  const [annualPeriod, setAnnualPeriod] = React.useState<string>("")
  const [marketPrice, setMarketPrice] = React.useState<string>("")

  return (
    <div
      className={`${cardColor} w-[406px] h-full flex flex-col rounded-[32px] main-shadow bg-white relative`}
    >
      <div className="absolute top-0 left-0 w-full h-[30px] bg-black rounded-tl-[32px] rounded-tr-[32px]"></div>

      <div className="md:hidden">
        <SubscriptionTag subscriptionName="trial" />
      </div>
      <div className="h-[736px] px-8 py-10 bg-white rounded-[32px] flex-col justify-start items-start gap-8 inline-flex">
        <div className=" self-stretch h-[28px] flex-col justify-start items-start gap-1 flex">
          <div className="text-black text-2xl font-semibold font-['Beausite Classic']">
            Free Trial
          </div>
          <div className="self-stretch text-[#7d878f] text-base font-normal font-['Beausite Classic'] leading-snug">
            For coaches who want to try out Zoee
          </div>
        </div>
        <div className="self-stretch h-[76px] flex-col justify-start items-start gap-1 flex">
          <div className="self-stretch h-[76px] flex-col justify-start items-start gap-0.5 flex">
            <div className="self-stretch h-[76px] flex-col justify-center items-start gap-0.5 flex">
              <div className="text-center text-black text-[42px] font-semibold font-['Beausite Classic']">
                $0/mo
              </div>
              <div className="self-stretch text-[#8e8e93] text-xl font-normal font-['Beausite Classic']">
                Free for 14 days
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-[12px] flex justify-center w-full">
          <div className="flex flex-col gap-[12px]">
            <Button
              variant="outline"
              size={"lg"}
              className="mt-[4px] md:mt-[20px] mx-[8px] md:mx-0 text-md"
            >
              Get Started
            </Button>
          </div>

          <div className="flex flex-col gap-[16px]">
            <div className="flex items-center gap-[16px]">
              <SvgCheckCircle stroke="#4750F5" />
              <p className="text-large">Integrated Scheduling and billing</p>
            </div>
            <div className="flex items-center gap-[16px]">
              <SvgCheckCircle stroke="#4750F5" />
              <p className="text-large">Custom Coaching Offers</p>
            </div>
            <div className="flex items-center gap-[16px]">
              <SvgCheckCircle stroke="#4750F5" />
              <p className="text-large">HIPPA Video conferencing</p>
            </div>
            <div className="flex items-center gap-[16px]">
              <SvgCheckCircle stroke="#4750F5" />
              <p className="text-large">Personalized landing page</p>
            </div>
            <div className="flex items-center gap-[16px]">
              <SvgCheckCircle stroke="#4750F5" />
              <p className="text-large">Secure client portal</p>
            </div>
            <div className="flex items-center gap-[16px]">
              <SvgCheckCircle stroke="#4750F5" />
              <p className="text-large">SMS messaging</p>
            </div>
            <div className="flex items-center gap-[16px]">
              <SvgCheckCircle stroke="#4750F5" />
              <p className="text-large">File sharing</p>
            </div>
            <div className="flex items-center gap-[16px]">
              <SvgCheckCircle stroke="#4750F5" />
              <p className="text-large">Client testimonials</p>
            </div>
            <div className="flex items-center gap-[16px]">
              <SvgCheckCircle stroke="#4750F5" />
              <p className="text-large">Digital notetaking system</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TrialSubscriptionCard
