import React, { FC, useContext, useEffect, useRef, useState } from "react";
import useOutsideClick from "../../hooks/useOutsideClick";
import logo from "../../assets/images/zoee-icon-chat.png";
import {
  Channel,
  useChatContext,
  useChannelActionContext,
} from "stream-chat-react";
import { ShowChatCtx } from "../../context/showChatContext";
import { UserCtx } from "../../context/userContext";
import { Link } from "react-router-dom";
import { CommonFunctionCtx } from "../../context/commonFunctionContext";
import {
  SvgChevronLeft,
  SvgChevronRight,
  SvgClose,
  SvgPlus,
  SvgSearch,
  SvgTrash,
} from "../icons";
import { Button } from "../ui/button";
import useLoading from "../../hooks/useLoading";
import { ContactRequests } from "../../api/app.service";
import ChatCheckbox from "./ChatCheckbox";
import ChannelInner from "./ChannelInner";
import { Dialog, DialogContent, DialogFooter } from "../ui/dialog";

interface Props {
  setEditChatPopover: any;
  editChatPopover: boolean;
  channelCount?: number;
  setChannelCount?: (count: number) => void;
}

const ChatWindow: FC<Props> = ({ channelCount, setChannelCount }) => {
  const { user } = useContext(UserCtx);
  const [chatLinks, setChatLinks] = useState<boolean>(false);
  const { channel: activeChannel, client } = useChatContext();
  const [membersOnline, setMembersOnline] = useState<boolean>(false);
  const [memberCount, setMemberCount] = useState<number>(0);
  const [messageCount, setMessageCount] = useState<number>(0);
  const [isChatDetailsOpen, setChatDetailsOpen] = useState<boolean>(false);
  const [isSelectModalOpen, setIsSelectModalOpen] = useState<boolean>(false);
  const { startLoading, stopLoading } = useLoading();
  const [contacts, setContacts] = useState<any>(null);
  const { renderError } = useContext(CommonFunctionCtx);
  const [inputValue, setInputValue] = useState<string>("");
  const [chatMembers, setChatMembers] = useState<any[]>([]);
  const [newChatMembers, setNewChatMembers] = useState<string[]>([]);
  const ref = useRef(null);
  const showChatContext = useContext(ShowChatCtx);
  const { setShowMobileNavBottom } = useContext(CommonFunctionCtx);
  const Id = user.activeProfileId

  useOutsideClick(ref, () => {
    setChatLinks(false);
  });

  const renderMessageImage = () => {
    if (activeChannel) {
      const notCurrentUser = Object.values(
        activeChannel?.state?.members
      ).filter((member: any) => {
        const id = user.activeProfileId
        return parseInt(member.user.id) !== id
      });

      const image = notCurrentUser[0]?.user?.image;
      const avatarDiv = (
        <span
          className={`font-bold text-xl w-10 h-10 justify-center
                      rounded-full flex items-center text-white ${
                        notCurrentUser[0]?.user?.user_color
                          ? notCurrentUser[0].user.user_color
                          : "bg-primaryBlue"
                      }`}
        >
          {notCurrentUser[0]?.user?.name?.charAt(0)}
        </span>
      );

      const avatarImg = (
        <img
          src={`${image}?{new Date().getTime()`}
          alt="avatar"
          className="w-[40px] h-[40px] rounded-full object-cover"
        />
      );

      if (image) {
        return avatarImg;
      } else {
        return avatarDiv;
      }
    } else {
      return null;
    }
  };

  const renderChannelTitle = () => {
    if (activeChannel) {
      const names = [];
      const members = Object.keys(activeChannel?.state?.members);
      for (let i = 0; i < members.length; i++) {
        const userId = Object.values(activeChannel?.state?.members)[i]?.user
          ?.id;
        if (userId !== client?.user?.id) {
          names.push(
            Object.values(activeChannel?.state?.members)[i]?.user?.name
          );
        }
      }
      const joinedNames =
        names.join(", ").length < 21
          ? names.join(", ")
          : `${names.join(", ").slice(0, 50)}...`;
      const messageTitle = activeChannel?.data?.name;
      return messageTitle || joinedNames;
    }
  };

  const getProfileLink = () => {
    if (activeChannel) {
      const members = Object.keys(activeChannel?.state?.members);
      const nonUserMembers = members.filter(
        (member) => member !== client?.user?.id
      );
      if (nonUserMembers.length === 1) {
        if (user.activeProfile === "coach") {
          return `/coach/contacts/${nonUserMembers[0]}`;
        } else {
          return `/member/contacts/${nonUserMembers[0]}`;
        }
      } else {
        return "#";
      }
    } else {
      return "#";
    }
  };

  const renderTitleLink = () => {
    const titleLink = (
      <Link
        to={getProfileLink()}
        className="flex items-center gap-[16px] w-full"
      >
        {renderMessageImage()}
        <h5 className="font-bold text-[16px] truncate">
          {renderChannelTitle()}
        </h5>
      </Link>
    );
    return titleLink;
  };

  const checkOnlineStatus = () => {
    if (activeChannel) {
      if (memberCount <= 2) {
        for (let i = 0; i < memberCount; i++) {
          const userId = Object.values(activeChannel?.state?.members)[i]?.user
            ?.id;
          if (userId !== client?.user?.id) {
            const online = Object.values(activeChannel?.state?.members)[i]?.user
              ?.online;
            if (online) {
              setMembersOnline(online);
            }
          }
        }
      }
    }
  };

  const renderSearchInput = () => {
    return (
      <div className="default-input mx-auto h-[44px] md:h-[48px] rounded-[10px] w-full my-3">
        <input
          type="text"
          className="p-0 indent-[48px] rounded-[10px]"
          placeholder="Search for people"
          value={inputValue}
          onChange={(e) => {
            setInputValue(e.target.value);
          }}
        />
        <SvgSearch className="absolute top-[50%] -translate-y-[50%] left-[12px]" />
      </div>
    );
  };

  const renderParticipants = () => {
    return chatMembers
      .filter((member: any) => member.name.includes(inputValue))
      .map((member: any) => (
        <div
          key={member.id}
          className="flex items-center gap-x-4"
        >
          {member.image ? (
            <img
              className="w-12 h-12 rounded-full object-cover"
              src={`${member.image}?t=${new Date().getTime()}`}
              alt="avatar"
            />
          ) : (
            <div
              className={`w-12 h-12 rounded-full object-cover flex justify-center items-center ${
                member.user_color ? member.user_color : "bg-lightPurple"
              }`}
            >
              <p className="text-white text-xl font-bold capitalize">
                {member.name.slice(0, 1)}
              </p>
            </div>
          )}
          <p className="font-bold text-lg truncate w-[calc(100%-104px)]">
            {member.name}
          </p>
          {chatMembers.length > 2 &&
            +member.id !== Id &&
            isOwner && (
              <Button
                className="w-[20px] h-[20px]"
                variant="ghost"
                size="icon"
                onClick={() => {
                  removeChatMember(member.id);
                }}
              >
                <SvgTrash />
              </Button>
            )}
        </div>
      ));
  };

  const renderContacts = () => {
    return contacts
      .filter((contact: any) => {
        const memberIds = chatMembers.map((member: any) => +member.id);
        if (
          memberIds.includes(contact.profile_id) ||
          contact.contact_type !== "connected"
        ) {
          return null;
        }

        return contact;
      })
      .filter((contact: any) =>
        contact.contact_name.toLowerCase().includes(inputValue.toLowerCase())
      )
      .map((contact: any) => (
        <div
          key={contact.user_id || contact.request_id}
          className="flex items-center gap-x-4 w-full"
        >
          {contact.avatar_url ? (
            <img
              className="w-12 h-12 rounded-full object-cover"
              src={contact.avatar_url}
              alt="avatar"
            />
          ) : (
            <div
              className={`w-[48px] h-12 rounded-full flex justify-center items-center bg-${
                contact.user_color ? contact.user_color : "lightPurple"
              }`}
            >
              <p className="text-white text-xl font-bold capitalize">
                {contact.contact_name.slice(0, 1)}
              </p>
            </div>
          )}
          <ChatCheckbox
            contact={contact}
            newChatMembers={newChatMembers}
            setNewChatMembers={setNewChatMembers}
          />
        </div>
      ));
  };

  const getChatMembers = () => {
    return Object.entries(
      activeChannel !== undefined ? activeChannel?.state?.members : []
    ).map((member: any) => member[1].user);
  };

  const getContacts = async () => {
    startLoading();
    const profile_id = user.isCoach ? user.coachProfile.profile_id.toString() : user.memberProfile.profile_id.toString()
    await ContactRequests.getContactList({
      profile_id: parseInt(profile_id),
      profile_type: user.isCoach ? "coach" : "member",
    })
      .then((data) => {
        setContacts(data.contact_list);
      })
      .catch((ex) => {
        console.log(ex);
        renderError(ex.response.data.message);
        stopLoading();
      })
      .finally(() => stopLoading());
  };

  const addChatMembers = async (memberList: string[]) => {
    await activeChannel
      ?.addMembers([...memberList])
      .then((data) => setChatMembers(data.members.map((member) => member.user)))
      .catch((ex) => console.log(ex));
  };

  const removeChatMember = async (memberId: string) => {
    await activeChannel
      ?.removeMembers([memberId])
      .then((data) => setChatMembers(data.members.map((member) => member.user)))
      .catch((ex) => console.log(ex));
  };

  const profileId = user.activeProfileId
  const isOwner =
    activeChannel?.state.members[profileId].role === "owner";
  

  useEffect(() => {
    checkOnlineStatus();
    getContacts();

    if (activeChannel) {
      setShowMobileNavBottom(false);
      setMemberCount(Object.keys(activeChannel?.state?.members).length);
      setMessageCount(Object.keys(activeChannel?.state?.messages).length);

      setChatMembers(getChatMembers());
    }
    if (setChannelCount) {
      setChannelCount(Object.keys(client?.activeChannels).length);
    }
  }, [activeChannel]);

  return (
    <>
      <div className="fixed w-full md:w-auto h-full md:h-[76vh] md:rounded-[16px] md:static top-0 left-0 bg-white z-[49] md:z-[0]">
        <div className="flex flex-col w-full md:w-[672px] md:main-shadow md:h-[76vh] md:rounded-[16px] relative">
          <div className="flex items-center w-full justify-between h-[64px] px-[24px] border-b-[1px] border-grayMist relative">
            <div className="flex items-center w-[55%] md:w-full">
              <SvgChevronLeft
                className={`mr-[8px] md:hidden cursor-pointer`}
                onClick={() => {
                  setShowMobileNavBottom(true);
                  showChatContext?.setShowChat(false);
                }}
              />
              {renderTitleLink()}
              {membersOnline && (
                <div className="w-[8px] h-[8px] rounded-full bg-lightCyanGreen ml-[16px]"></div>
              )}
            </div>
            {activeChannel && (
              <Button
                variant="ghost"
                size="sm"
                onClick={() => setChatDetailsOpen(true)}
              >
                Chat Details
                <SvgChevronRight />
              </Button>
            )}
            {isChatDetailsOpen && (
              <div
                className="max-w-[calc(100vw-12px)] max-h-screen w-[460px] h-fit bg-white absolute top-12 right-1.5
              md:top-[calc(100%/2)] md:left-[calc(100%/2-230px)] z-10 px-6 py-10 rounded-[16px] border border-[#ABB8C3] flex flex-col items-center"
              >
                <div className="flex justify-between items-center font-bold w-full mb-3">
                  <p className="text-base text-[#ABB8C3]">Chat Details</p>
                  <Button
                    size="icon"
                    variant="ghost"
                    onClick={() => setChatDetailsOpen(false)}
                  >
                    <SvgClose />
                  </Button>
                </div>
                <div className="py-[18px] font-bold truncate w-full">
                  {renderChannelTitle()}
                </div>
                {user.isCoach && isOwner && chatMembers.length >= 3 && (
                  <Button
                    variant="ghost"
                    size="sm"
                    className="text-[#4750F5] hover:text-[#4750F5] font-bold text-lg my-4"
                    onClick={() => setIsSelectModalOpen(true)}
                  >
                    <SvgPlus stroke="#4750F5" />
                    Add Participants
                  </Button>
                )}
                {renderSearchInput()}
                <div className="h-auto max-h-80 w-full flex flex-col gap-y-4 p-3 overflow-scroll">
                  {renderParticipants()}
                </div>
                <Dialog
                  open={isSelectModalOpen}
                  onOpenChange={setIsSelectModalOpen}
                >
                  <DialogContent>
                    <h3 className=" md:text-[22px] text-[18px] font-bold sm:text-[28px] text-center">
                      Add Participants
                    </h3>
                    {renderSearchInput()}
                    <div className="w-full h-auto max-h-80 flex flex-col gap-y-6 overflow-scroll">
                      {renderContacts()}
                    </div>
                    <DialogFooter className="md:justify-center gap-y-4 mt-4">
                      <Button
                        onClick={() => setIsSelectModalOpen(false)}
                        variant="ghost"
                        size="lg"
                        className="md:text-lg"
                      >
                        Cancel
                      </Button>
                      <Button
                        size="lg"
                        variant="default"
                        className="md:text-lg"
                        disabled={newChatMembers.length < 1}
                        onClick={() => {
                          addChatMembers([...newChatMembers]);
                          setIsSelectModalOpen(false);
                        }}
                      >
                        Add Selected
                      </Button>
                    </DialogFooter>
                  </DialogContent>
                </Dialog>
              </div>
            )}
          </div>
          {activeChannel && (
            <Channel channel={activeChannel}>
              <ChannelInner
                setMessageCount={setMessageCount}
                messageCount={messageCount}
              />
            </Channel>
          )}

          {/* TODO these have not been checked on mobile yet */}
          {messageCount === 0 &&
            typeof channelCount !== "undefined" &&
            channelCount > 0 && (
              <>
                <div className="absolute bottom-[calc(100vh/2)] md:bottom-[100px] left-1/2 -translate-x-1/2 w-full lg:bottom-[150px]">
                  <div className="flex gap-[20px] items-center justify-center w-full">
                    <div className="w-[40%] h-px bg-grayMist"></div>
                    <h5 className="font-bold text-base text-blurple">New</h5>
                    <div className="w-[40%] h-px bg-grayMist"></div>
                  </div>
                  <p className="text-graySlate text-base text-center w-full py-4 md:py-2 px-4 beginning-conversation mb-[60px] md:mb-0">
                    This is the very beginning of your conversation
                  </p>
                </div>
              </>
            )}
          {channelCount === 0 && (
            <>
              <div className="absolute bottom-[336px] left-[50%] -translate-x-[50%] w-full">
                <div className="flex gap-[20px] items-center justify-center w-full">
                  <div className="w-[30%] h-[1px] bg-grayMist"></div>
                  <h5 className="font-bold text-base text-blurple">
                    Start a new chat.
                  </h5>
                  <div className="w-[30%] h-[1px] bg-grayMist"></div>
                </div>
                <p className="text-graySlate text-base text-center">
                  What are you waiting for? Let's get the conversation going.
                </p>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ChatWindow;
