import React, { FC, useEffect, useRef, useState } from "react";
import { CoachPublicProfileContext } from "../../pages/manage-business/profile/context/coachPublicProfileContext";
import { CoachPrivateProfileContext } from "../../pages/manage-business/profile/context/coachPrivateProfileContext";
import Maximize from "../ui/Maximize";
import {
  SvgAudio,
  SvgAudioOff,
  SvgMaximize,
  SvgPause,
  SvgPlay,
  SvgVideoIcon,
} from "../icons/";

interface Props {
  video: any;
  videos?: any;
  coachPublicProfileId?: number;
}

const OverviewPortraitVideoPreview: FC<Props> = ({
  video,
  videos,
  coachPublicProfileId,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [profileVideoPlaying, setProfileVideoPlaying] =
    useState<boolean>(false);
  const [profileAudioMuted, setProfileAudioMuted] =
    useState<boolean>(false);

  const [maximize, setMaximize] = useState<boolean>(false);
  const [videoDuration, setVideoDuration] = useState<number | null>(null);

  const videoHandler = (control: any) => {
    if (control === "play") {
      videoRef?.current?.play();
      setProfileVideoPlaying(true);
    } else if (control === "pause") {
      videoRef?.current?.pause();
      setProfileVideoPlaying(false);
    }
  };

  const getVideoType = (extension: any) => {
    if (extension === "mov") {
      return "mp4";
    } else {
      return extension;
    }
  };

  useEffect(() => {
    const currentVideo = videoRef.current as HTMLVideoElement;
    if (currentVideo) {
      currentVideo.addEventListener("loadedmetadata", () => {
        setVideoDuration(currentVideo.duration);
      });
    }
  }, []);

  const formatDuration = (duration: number | null) => {
    if (duration === null) {
      return "Loading...";
    }
    const minutes = Math.floor(duration / 60);
    const seconds = Math.floor(duration % 60);
    return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  };

  return (
    <>
      <div className="bg-gray group relative flex h-full w-full items-center rounded-[16px]">
        <div
          className="absolute bottom-[8px] left-[50%] z-[20] flex hidden w-full -translate-x-[50%]
                items-center justify-center gap-[8px] group-hover:flex sm:justify-evenly sm:gap-0"
        >
          <button
            className="flex h-[32px] w-[32px] items-center justify-center rounded-[8px] bg-gray text-white"
            onClick={() => {
              if (profileVideoPlaying) {
                videoHandler("pause");
              } else {
                videoHandler("play");
              }
            }}
          >
            {profileVideoPlaying ? (
              <SvgPause className="w-[32px]" />
            ) : (
              <SvgPlay />
            )}
          </button>
          <button
            className="hidden h-[32px] w-[32px] items-center justify-center rounded-[8px] bg-gray text-white sm:flex"
            onClick={(e: any) => {
              videoHandler("pause");
              setMaximize(true);
            }}
          >
            <SvgMaximize />
          </button>
          <button
            className="flex h-[32px] w-[32px] items-center justify-center rounded-[8px] bg-gray text-white"
            onClick={() => setProfileAudioMuted(!profileAudioMuted)}
          >
            {profileAudioMuted ? <SvgAudioOff /> : <SvgAudio />}
          </button>
        </div>
        <SvgVideoIcon
          fill="white"
          className="absolute right-[8px] top-0 z-[20] w-[32px]"
        />
        <video
          ref={videoRef}
          className="z-[1] h-full w-full rounded-[16px] object-cover"
          muted={profileAudioMuted}
          onEnded={() => videoHandler("pause")}
          preload="metadata"
        >
          <source
            src={`https://public-profile-videos-${process.env.REACT_APP_USER_ENVIRONMENT}.s3.amazonaws.com/${coachPublicProfileId}_about_${video.id}.${video.video_extension}#t=0.1`}
            type={`video/${getVideoType(video.video_extension)}`}
          />
          Sorry, your browser doesn't support embedded videos.
        </video>
        <div className="z-[20] flex h-[100%] flex-col justify-between">
          <div className="absolute left-[8px] top-[8px] flex w-full items-center text-center">
            <span className="rounded-[15px] bg-white bg-opacity-80 p-1 px-3 font-bold">
              Video
            </span>
          </div>
          <div className="absolute bottom-[12px] left-[6px] flex min-w-[95%] flex-col gap-[12px] rounded-[15px] bg-white/[0.8] p-[12px] text-left group-hover:hidden">
            <h2 className="line-clamp-2 max-w-[208px] text-[16px] font-bold text-black">
              {video.title}
            </h2>
            <div className="flex items-center gap-[8px] text-base font-bold">
              <span className="text-black">
                {formatDuration(videoDuration)} min video
              </span>
            </div>
          </div>
        </div>
      </div>
      {maximize && (
        <>
          <Maximize
            videoSrc={video}
            onClose={setMaximize}
            coachPublicProfileId={coachPublicProfileId}
          />
        </>
      )}
    </>
  );
};

export default OverviewPortraitVideoPreview;
