import moment from "moment";
import React from "react";
import { Button } from "../../ui/button";
import useLoading from "../../../hooks/useLoading";
import { UserCtx } from "../../../context/userContext";
import { useNavigate } from "react-router-dom";
import ContactAvatar from "../../cards/ContactAvatar";
import { SvgChat, SvgRecord, SvgVideoSolid } from "../../icons/";

interface Props {
  meeting: any;
  title: string | null;
  is_managed: boolean | undefined;
  belongs_to_profile: boolean | undefined;
  inProgress: boolean;
  endTime: Date | null;
  participants: Array<Object>;
  selectedState: string;
  setOpenUpcomingDetails: React.Dispatch<React.SetStateAction<boolean>>;
  setUpcomingSessionDetailViewId: any;
  id: number | null;
  setShowRescheduleMeeting: any;
  setBackToContacts: any;
}

const MeetingPreviewItem: React.FC<Props> = ({
  meeting,
  title,
  is_managed,
  belongs_to_profile,
  inProgress,
  endTime,
  participants,
  selectedState,
  setOpenUpcomingDetails,
  setUpcomingSessionDetailViewId,
  id,
  setShowRescheduleMeeting,
  setBackToContacts,
}) => {
  const { startLoading } = useLoading();
  const { user } = React.useContext(UserCtx);
  const navigate = useNavigate();
  const [isHovering, setIsHovering] = React.useState<boolean>(false);
  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };
  
  const startMeetingChat = (participants: any) => {
    const participantIds = participants.map(
      (participant: any) => participant.profile_id
    );
    navigate(`/${user.activeProfile}/inbox`, {
      state: {
        participantIds: participantIds,
      },
    });
  };

  const isPastMeeting = () => {
    return endTime && endTime < new Date();
  };

  let accentColor = "";
  let reschedule;
  if (is_managed) {
    if (belongs_to_profile) {
      accentColor = "bg-blurple";
      reschedule = true;
    } else {
      accentColor = "bg-mainBlack";
      reschedule = false;
    }
  } else {
    accentColor = "bg-grayMist";
    reschedule = false;
  }

  const handleMeetingSelect = (id: any) => {
    setUpcomingSessionDetailViewId(id);
    setOpenUpcomingDetails(true);
  };

  return (
    <div className="relative w-full sm:min-h-[128px] min-h-[94px] flex items-center bg-white rounded-[16px] promotion-shadow">
      <div
        onClick={
          selectedState === "upcoming"
            ? () => handleMeetingSelect(id)
            : undefined
        }
        className={`${
          selectedState === "upcoming" ? "cursor-pointer" : ""
        } pl-0 pr-[16px] sm:px-[25px] flex w-full items-center`}
      >
        <div
          className={`${accentColor} absolute top-[16px] sm:top-[24px] left-0 w-[3px] h-[48px] sm:h-[80px]`}
        />

        {/* MOBILE */}
        <div className="sm:hidden flex items-center gap-[8px] justify-between w-full">
          <div className="sm:hidden bg-hover flex flex-col items-center justify-center sm:w-[128px] sm:h-[126px] w-[65px] h-[90px] rounded-[10px] ml-[16px] text-center">
            <span className="sm:font-semibold font-bold sm:text-[28px] text-base sm:leading-normal leading-none text-grayCharcoal mt-2">
              {moment(meeting.startTime).format("MMM D, h:mm")}
            </span>
            <span className="-mt-2">-</span>
            <span className="sm:font-semibold font-bold sm:text-[28px] text-base sm:leading-normal leading-none text-grayCharcoal -mt-2">
              {moment(meeting.endTime).format("h:mm A")}
            </span>
          </div>
          <div className="flex flex-col -mt-[20px]">
            <div className="flex items-center gap-[16px]">
              <h4 className="mb-[4px] text-base sm:text-[18px] font-bold w-[240px] sm:w-auto truncate">
                {title &&
                  (title?.length > 28 ? `${title?.slice(0, 25)}...` : title)}
              </h4>
            </div>
            <div className={`flex flex-col ${reschedule ? "-mb-[35px]" : ""}`}>
              <div className="flex items-center gap-[16px]">
                <div className="flex items-center text-base text-graySlate">
                  <span>{`${meeting.contacts.length} guests:`}</span>
                  {is_managed ? (
                    <>
                      <div className="flex items-center ml-[12px] gap-[8px] overflow-ellipsis">
                        {meeting.contacts.length < 3 ? (
                          <>
                            {meeting.contacts.map(
                              (participant: any, i: number) => {
                                return (
                                  <div
                                    key={i}
                                    className="relative"
                                  >
                                    <ContactAvatar
                                      contact={participant}
                                      width={24}
                                      height={24}
                                      bold={false}
                                      fontSize={"text-base"}
                                    />
                                    {/* TODO Add logic for whether guest is online or not */}
                                    {/* <div className="absolute w-[10px] h-[10px] z-50 top-[16px] left-[15px] rounded-full bg-green border-2 border-white" /> */}
                                  </div>
                                );
                              }
                            )}
                          </>
                        ) : (
                          <>
                            {meeting.contacts
                              .slice(0, 3)
                              .map((participant: any, i: number) => {
                                return (
                                  <div
                                    key={i}
                                    className="relative"
                                  >
                                    <ContactAvatar
                                      contact={participant}
                                      width={24}
                                      height={24}
                                      bold={false}
                                      fontSize={"text-base"}
                                    />
                                    {/* TODO Add logic for whether guest is online or not */}
                                    {/* <div className="absolute w-[10px] h-[10px] z-50 top-[16px] left-[15px] rounded-full bg-green border-2 border-white" /> */}
                                  </div>
                                );
                              })}
                            <div>...</div>
                          </>
                        )}
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              
              {reschedule && selectedState === "upcoming" && (
                <div className="-mt-[7px] -ml-[16px]">
                  <Button
                    variant={"link"}
                    className="hover:no-underline font-[300]"
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowRescheduleMeeting(true);
                      setUpcomingSessionDetailViewId(id);
                      setBackToContacts(true);
                    }}
                  >
                    Reschedule Session
                  </Button>
                </div>
              )}
            </div>
          </div>
          {/* Message and Join Session Buttons */}
          <div>
            {is_managed && belongs_to_profile ? (
              <div className="flex items-center flex-col h-full gap-[8px]">
                {!isPastMeeting() &&
                inProgress &&
                selectedState === "upcoming" ? (
                  <Button
                    className="flex items-center justify-center px-[6px] py-[4px] font-bold sm:w-[80px] -ml-[67px] sm:-ml-0 w-[50px]"
                    onClick={(e: any) => {
                      startLoading();
                      window.location.href = `/${user.activeProfile}/sessions`;
                    }}
                  >
                    Join
                  </Button>
                ) : (
                  <>
                    {!isPastMeeting() && selectedState === "upcoming" && (
                      <button
                        className="btn-primary bg-grayFlash text-gray min-w-fit h-11 relative sm:w-[80px] -ml-[67px] sm:-ml-0 w-[50px]"
                        onFocus={handleMouseOver}
                        onMouseOver={handleMouseOver}
                        onBlur={handleMouseOut}
                        onMouseOut={handleMouseOut}
                      >
                        Join
                        {isHovering && (
                          <div
                            className="absolute bottom-full left-1/2 transform -translate-x-1/2 bg-white p-4 shadow-md rounded-md text-black w-[250px] border border-black"
                            data-popover
                            id="popover-description"
                            role="tooltip"
                          >
                            <p className="font-bold">
                              The link to your session will be available shortly
                              before your session begins.
                            </p>
                            <div>
                              <p className="text-graySlate mt-4 text-sm">
                                click join to view your future sessions details.
                              </p>
                            </div>
                          </div>
                        )}
                      </button>
                    )}
                  </>
                )}
                <Button
                  className="flex items-center justify-center px-[6px] py-[4px] font-bold sm:w-[80px] -ml-[67px] sm:-ml-0 w-[50px]"
                  onClick={() => startMeetingChat(participants)}
                  variant="outline"
                >
                  <SvgChat />
                </Button>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>

        {/* DESKTOP */}
        <div className="hidden sm:block w-full">
          <div className="flex items-center w-full justify-between">
            <div>
              <div className="mb-[2px] sm:mb-[12px] flex items-center gap-[12px]">
                <h4 className="text-base sm:text-[18px] font-bold max-w-[560px] truncate">
                  {title &&
                    (title?.length > 28 ? `${title?.slice(0, 25)}...` : title)}
                </h4>
                {meeting.medium && (
                  <div className="w-fit py-[4px] px-[8px] text-primaryBlue text-base font-bold bg-paleCyanBlue/[0.24] rounded-[8px] flex items-center gap-[10px]">
                    <SvgVideoSolid fill="blue" />
                    Zoee Video
                  </div>
                )}
                {inProgress && !isPastMeeting() ? (
                  <>
                    <div className="flex items-center gap-[8px] ml-[12px]">
                      <SvgRecord />
                      Live
                    </div>
                  </>
                ) : (
                  <>
                    {isPastMeeting() && (
                      <>
                        <div className="flex items-center justify-center bg-grayMist text-grayCharcoal px-[8px] py-[4px] font-bold rounded-[8px] ml-[16px]">
                          Past
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
              <div className="flex">
                <div className="flex flex-col -mb-[58px]">
                  <div className="hidden sm:flex items-center text-base text-graySlate font-bold pr-[16px] border-r border-grayMist">
                    <span>
                      {moment(meeting.startTime).format("MMM Do, YYYY h:mm A")}
                    </span>
                    <span>-</span>
                    <span>
                      {moment(meeting.endTime).format("MMM Do, YYYY  h:mm A")}
                    </span>
                  </div>
                  {reschedule && selectedState === "upcoming" && (
                    <Button
                      variant={"link"}
                      className="-ml-[16px] -mt-[12px] hover:no-underline font-[300] hover:underline mr-auto"
                      onClick={(e) => {
                        e.stopPropagation();
                        setShowRescheduleMeeting(true);
                        setUpcomingSessionDetailViewId(id);
                        setBackToContacts(true);
                      }}
                    >
                      Reschedule Session
                    </Button>
                  )}
                </div>
                <div className="flex items-center text-base text-graySlate px-[16px]">
                  <span className="text-graySlate text-base">
                    {`${meeting?.contacts?.length} participants`}
                  </span>
                  {is_managed ? (
                    <>
                      <div className="flex items-center ml-[12px] gap-[8px] overflow-ellipsis">
                        {meeting.contacts.length < 9 ? (
                          <>
                            {meeting.contacts.map(
                              (participant: any, i: number) => {
                                return (
                                  <div
                                    key={i}
                                    className="relative"
                                  >
                                    <ContactAvatar
                                      contact={participant}
                                      width={24}
                                      height={24}
                                      bold={false}
                                      fontSize={"text-base"}
                                    />
                                    {/* TODO Add logic for whether guest is online or not */}
                                    {/* <div className="absolute w-[10px] h-[10px] z-50 top-[16px] left-[15px] rounded-full bg-green border-2 border-white" /> */}
                                  </div>
                                );
                              }
                            )}
                          </>
                        ) : (
                          <>
                            {meeting.contacts
                              .slice(0, 3)
                              .map((participant: any, i: number) => {
                                return (
                                  <div
                                    key={i}
                                    className="relative"
                                  >
                                    <ContactAvatar
                                      contact={participant}
                                      width={24}
                                      height={24}
                                      bold={false}
                                      fontSize={"text-base"}
                                    />
                                    {/* TODO Add logic for whether guest is online or not */}
                                    {/* <div className="absolute w-[10px] h-[10px] z-50 top-[16px] left-[15px] rounded-full bg-green border-2 border-white" /> */}
                                  </div>
                                );
                              })}
                            <div>...</div>
                          </>
                        )}
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  {/* HIDE STATUS AND ONLINE INDICATOR */}
                  {/* <div className="flex items-center gap-[7px] border-l border-grayMist pl-[16px]">
                                        <div className="w-[5px] h-[5px] bg-green rounded-full"></div>
                                        <div className="text-green text-base font-bold">Busy</div>
                                    </div> */}
                </div>
              </div>
            </div>
            {/* Message and Join Session Buttons */}
            <div className="flex flex-col justify-evenly items-center gap-[8px] mr-4">
              {is_managed && belongs_to_profile ? (
                <div>
                  {!isPastMeeting() &&
                  inProgress &&
                  selectedState === "upcoming" ? (
                    <Button
                      onClick={() => {
                        startLoading();
                        window.location.href = `/${user.activeProfile}/sessions`;
                      }}
                      size="lg"
                    >
                      Join
                    </Button>
                  ) : (
                    <>
                      {!isPastMeeting() && selectedState === "upcoming" && (
                        <button
                          className="h-11 rounded-md px-8 btn-primary bg-grayFlash text-gray min-w-fit relative"
                          // disabled={true}
                          onFocus={handleMouseOver}
                          onMouseOver={handleMouseOver}
                          onBlur={handleMouseOut}
                          onMouseOut={handleMouseOut}
                        >
                          Join
                          {isHovering && (
                            <div
                              className="absolute bottom-full left-1/2 transform -translate-x-1/2 bg-white p-4 shadow-md rounded-md text-black w-[250px] border border-black"
                              data-popover
                              id="popover-description"
                              role="tooltip"
                            >
                              <p className="font-bold">
                                The link to your session will be available
                                shortly before your session begins.
                              </p>
                              <div>
                                <p className="text-graySlate mt-4 text-sm">
                                  click join to view your future sessions
                                  details.
                                </p>
                              </div>
                            </div>
                          )}
                        </button>
                      )}
                    </>
                  )}
                </div>
              ) : (
                <></>
              )}
              <Button
                className="flex items-center"
                variant="outline"
                size="lg"
                onClick={() => startMeetingChat(participants)}
              >
                <SvgChat />
              </Button>
            </div>
          </div>
        </div>
        {/* </div> */}
      </div>
    </div>
  );
};

export default MeetingPreviewItem;
