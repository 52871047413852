import React from "react"
import { SvgCheckCircle } from "../icons"
import TrialSubscriptionCard from "./TrialSubscriptionCard"

type Props = {
  isNewPlan: boolean
  subscription: any
}

const SubscriptionCheckoutCard: React.FC<Props> = ({
  isNewPlan,
  subscription,
}) => {
  const [paymentPeriod, setPaymentPeriod] = React.useState<string>("")
  const [subscriptionCardTitle, setSubscriptionCardTitle] =
    React.useState<string>("")
  const [twoYearSubscription, setTwoYearSubscription] =
    React.useState<boolean>(false)
  const [cardColor, setCardColor] = React.useState<string>("")
  const [cardDetailsColor, setCardDetailsColor] = React.useState<string>("")


  const assignCardColor = () => {
    if (subscription?.recurring_interval === "annual" && !subscription?.is_promotional) {
      setCardDetailsColor("darkPurple")
    }
    if (subscription?.recurring_interval === "month" && !subscription?.is_promotional) {
      setCardDetailsColor("blurple")
    }
    if (subscription?.amount === 500 && subscription?.is_promotional) {
      setCardDetailsColor("mainBlack")
      setCardColor("subscription-cta-gradient")
    }
  }

  const setSubscriptionCardData = () => {
    if (
      subscription?.recurring_interval === "annual" &&
      !subscription?.is_promotional
    ) {
      setPaymentPeriod("/annual")
      setSubscriptionCardTitle("Annual Plan")
    }
    if (
      subscription?.recurring_interval === "month" &&
      !subscription?.is_promotional
    ) {
      setPaymentPeriod("/month")
      setSubscriptionCardTitle("Monthly Plan")
    }
    if (
      subscription?.amount === 500 &&
      subscription?.recurring_interval_count === 2
    ) {
      setPaymentPeriod("one time cost")
      setSubscriptionCardTitle("Two Year Plan")
      setTwoYearSubscription(true)
    }
  }

  const getStrikethroughPrice = () => {
    if (subscription?.recurring_interval === "annual" && !subscription?.is_promotional)
      return "$55.00/mo"
    if (subscription?.recurring_interval === "month" && !subscription?.is_promotional)
      return "$69.00/mo"
  }

  const getBillingCycleText = () => {
    if (subscription?.recurring_interval === "annual" && !subscription?.is_promotional)
      return "Billed annually"
    if (subscription?.recurring_interval === "month" && !subscription?.is_promotional)
      return <span style={{ visibility: "hidden" }}>Monthly</span>
  }

  React.useEffect(() => {
    setSubscriptionCardData()
  }, [subscription])

  return (
    <div
      className={`${cardColor} w-[406px] h-full flex flex-col rounded-[32px] main-shadow bg-white relative`}
    >
      <div
        className={`absolute top-0 left-0 w-full h-[30px] bg-black rounded-tl-[32px] rounded-tr-[32px] ${
          subscription?.recurring_interval === "annual"
            ? "bg-blurple"
            : subscription?.recurring_interval === "month"
            ? "bg-black"
            : "bg-gray-400"
        } flex items-center justify-center`}
        style={{ borderTopLeftRadius: "32px", borderTopRightRadius: "32px" }}
      >
        {subscription?.recurring_interval !== "freeTrial" && (
          <p className="text-white text-center">Limited Time Offer</p>
        )}
      </div>

      <div className="md:hidden"></div>
      <div className="h-[736px] px-8 py-10 bg-white rounded-[32px] flex-col justify-start items-start gap-8 inline-flex mb-4">
        <div className=" self-stretch h-[28px] flex-col justify-start items-start gap-1 flex">
          <div className="text-black text-2xl font-semibold font-['Beausite Classic']">
            {subscriptionCardTitle}
          </div>
          <div className="self-stretch text-[#7d878f] text-base font-normal font-['Beausite Classic'] leading-snug">
            {subscription?.stripe_product_description}
          </div>
        </div>
        <div className="self-stretch h-[78px] flex-col justify-start items-start flex -mt-1.5">
          <div className="self-stretch h-[76px] flex-col justify-start items-start gap-0.5 flex">
              <div className="text-center text-black text-[42px] font-semibold font-['Beausite Classic']">
                ${subscription?.amount}
                {paymentPeriod}
              </div>
              <div className="self-stretch text-[#8e8e93] text-xl font-normal font-['Beausite Classic']">
                <p className="text-[18px] text-graySlate line-through">
                  {getStrikethroughPrice()}
                </p>
                <p className="text-[18px] text-graySlate">
                  {getBillingCycleText()}
                </p>
              </div>
          </div>
        </div>

        <div className="flex flex-col gap-[12px] flex justify-center w-full">
          <div className="flex flex-col gap-[12px]"></div>

          <div className="flex flex-col gap-[16px]">
            {[
              subscription?.stripe_product_metadata?.feature1,
              subscription?.stripe_product_metadata?.feature2,
              subscription?.stripe_product_metadata?.feature3,
              subscription?.stripe_product_metadata?.feature4,
            ].map((feature, index) => (
              <div className="flex items-center gap-[16px]" key={index}>
                <SvgCheckCircle stroke="#4750f5" />
                <p>{feature}</p>
              </div>
            ))}
          </div>
        </div>
        <div>
          <p className="italic">* Limited Time Discount!  Sign up now to lock in our special pricing forever.</p>
        </div>
      </div>
    </div>
  )
}

export default SubscriptionCheckoutCard
