import React from "react";
import { Link } from "react-router-dom";
import { CoachPrivateProfileContext } from "../../../pages/manage-business/profile/context/coachPrivateProfileContext";
import PrivateProfileServiceCard from "./PrivateProfileServiceCard";
import Loader from "../../ui/loader";
import { Button } from "../../ui/button";
import { SvgPlus } from "../../icons/";

interface Props {}

const ProfileServices: React.FC<Props> = (props) => {
    const { services, loadingServices, getServices } = React.useContext(CoachPrivateProfileContext);

    return (
        <>
        <h1 className="font-bold text-xl">Services & Programs</h1>
        <div className="w-full md:max-w-[1200px] flex items-center flex-wrap justify-start gap-[20px]">
            {loadingServices ? (
                <Loader />
            ) : (
                <>
                    {services?.map((service:any, index:number) => (
                        <PrivateProfileServiceCard
                            key={index}
                            service={service}
                            getServices={getServices}
                        />
                    ))}
                    {services?.length === 0 && (
                        <div className="flex flex-col items-center gap-[12px]">
                            <div className="text-graySlate">No services</div>
                            <Link to="/coach/services">
                            <Button
                                className="bg-white px-[20px] hover:bg-grayFlash gap-1"   
                                variant={'utility'}
                                size={"lg"} >     
                                <SvgPlus />
                                Add
                            </Button>
                            </Link>
                        </div>
                    )}
                </>
            )}
        </div>
         </>
    )
}

export default ProfileServices;
