import React from "react";
import PrivateProfileBlogPost from "./PrivateProfileBlogPost";
import { CoachPrivateProfileContext } from "../../../pages/manage-business/profile/context/coachPrivateProfileContext";
import { ProfileRequests } from "../../../api/app.service";
import { UserCtx } from "../../../context/userContext";
import { CommonFunctionCtx } from "../../../context/commonFunctionContext";
import Loader from "../../ui/loader";
import { Button } from "../../ui/button";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { SvgDraggable, SvgPlus } from "../../icons";

export default function PrivateProfileBlog() {
  const { user } = React.useContext(UserCtx);
  const { setCreateBlogPost, coachPublicProfileData } = React.useContext(
    CoachPrivateProfileContext,
  );
  const { renderError } =
    React.useContext(CommonFunctionCtx);
  const [posts, setPosts] = React.useState<any[]>([]);
  const [loadingPosts, setLoadingPosts] = React.useState<boolean>(false);
  const [orderedPosts, setOrderedPosts] = React.useState<any[]>(posts);

  const getCoachBlogPosts = async () => {
    if (user) {
      setLoadingPosts(true);
      await ProfileRequests.getCoachBlogPosts({
        coach_profile_id: parseInt(user.coachProfile.profile_id),
      })
        .then((data) => {
          setPosts(data.blog_posts);
          setLoadingPosts(false);
        })
        .catch((ex) => {
          renderError(ex.response.data.message);
          setLoadingPosts(false);
        });
    }
  };

  React.useEffect(() => {
    getCoachBlogPosts();
  }, [coachPublicProfileData]);

  React.useEffect(() => {
    setOrderedPosts(posts);
  }, [posts]);

  const reorderBlogPosts = (ids: number[]) => {
    console.log("loading...");
    ProfileRequests.reorderBlogPosts({
      blog_post_ids: ids,
    })
      .then(() => {
        console.log("success");
      })
      .catch((ex) => {
        console.log(ex);
        renderError(ex.response.data.message);
      });
  };

  const onDragEnd = (result: any) => {
    const { destination, source } = result;
    if (!destination) {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    const items = Array.from(orderedPosts);
    const [reorderedItem] = items.splice(source.index, 1);
    items.splice(destination.index, 0, reorderedItem);

    setOrderedPosts(items);

    items.forEach((item: any, index) => {
      console.log(
        `Update order -> id: ${item.id}, order: ${index}, title: ${item.title}`,
      );
    });

    reorderBlogPosts(items.map((item: any) => item.id));
  };

  return (
    <div className="mx-auto flex w-full flex-wrap items-center justify-start gap-[16px]">
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="posts">
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className="mx-auto -mt-0 flex w-full flex-wrap items-center justify-start gap-[21px] md:-mt-[35px]"
            >
              {loadingPosts ? (
                <Loader />
              ) : (
                <>
                  <Button
                    className="hover:bg-grayFlash flex items-center justify-center gap-1 bg-white px-[20px]"
                    variant={"utility"}
                    size={"lg"}
                    onClick={() => setCreateBlogPost(true)}
                  >
                    <SvgPlus />
                    Add
                  </Button>
                  {orderedPosts?.map((post, index) => (
                    <Draggable
                      key={post.id} // Replace with your video unique key
                      draggableId={String(post.id)} // Replace with your video unique ID
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          className="mt-[35px] h-[300px] w-[300px] md:h-[350px] md:w-[350px]"
                          key={index}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={{
                            ...provided.draggableProps.style,
                            opacity: snapshot.isDragging ? 0.5 : 1,
                          }}
                        >
                          <div
                            {...provided.dragHandleProps}
                            className="inline-flex px-2 py-4 opacity-50 transition-all hover:opacity-100"
                          >
                            <SvgDraggable />
                          </div>
                          <PrivateProfileBlogPost post={post} key={post.id} />
                        </div>
                      )}
                    </Draggable>
                  ))}
                </>
              )}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};
